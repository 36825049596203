.DateRangePicker__MonthHeader {
  justify-content: center;
}

.DateRangePicker__CalendarSelection {
  background-color: #5968ea;
  border: 1px solid #5968ea;
}

.sessionTime {
  line-height: 3.5;
}