.footerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 4rem;
  background: #fff;
  justify-content: space-between;
  font-family: 'Noto Sans', sans-serif;
  // horizontal padding of 2 rem
  padding: 0 2rem;
  p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.8rem;
    color: #999999;
  }
}

.logo {
  width: 8rem;
  object-fit: contain;
}
