// @import '../../../../../../../assets/sass/_variables.scss';
@import '../../../../../assets/sass/variables';

.mimicContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #e5ecff;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 32 * $vw-const;
  margin-top: 24 * $vh-const;
}
.header {
  width: 100%;
  margin-top: 50 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25 * $vw-const;
  margin-top: 25 * $vw-const;
  padding: 0 2rem;
}

.profileContainer {
  min-width: 200 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10 * $vw-const;
  cursor: pointer;
  background-color: #e5ecff;
  border-radius: 5px;
  // margin-top: 30 * $vw-const;
  .pic {
    width: 40 * $vw-const;
    height: 40 * $vw-const;
    // background-color: gray;
    margin-right: 10px;
  }
  .name {
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    font-family: 'Poppins-SemiBold';
    color: #002c94;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
  }
  .role {
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    font-family: 'Poppins-Regular';
    color: #202428;
    letter-spacing: 0.56px;
    text-align: right;
    font-weight: 400;
  }
  .settingBtn {
    width: clamp(10px, 30 * $vw-const, 30 * $vw-const);
    position: relative;
    margin-left: 10px;
    img {
      position: absolute;
      right: 10px;
    }
  }
}

.sidebar {
  width: 20vw;
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e5ecff;
  overflow: auto;
  padding-bottom: 4rem;
  // padding-top: 10vh;
}

.date {
  padding: 0 2rem;
  margin-bottom: 1rem;
  span {
    font-family: 'Poppins-Bold';
  }
}

.topContainer {
  display: flex;
  flex-direction: row;
  // min-height: 30vh;
  padding: 0 2rem;
  margin-bottom: 1rem;
  // overflow: hidden;
}

.registrationsContainer {
  width: 80%;
  background: #fff;
  height: 100%;
  margin-right: 1rem;
  border-radius: 15px;
}

.passesIssuedContainer {
  width: 20%;
  background: #fff;
  height: 100%;
  border-radius: 15px;
}
.boxHeading {
  font-family: 'Poppins-bold';
  color: #3e3e3e;
  font-size: 1.2rem;
}
.paddingNormal {
  padding: 0.4rem 1rem;
}

.divideBottom {
  height: 4px;
  background: #f8faff;
  width: 100%;
}

.registrationsMainContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 1rem;
  height: 90%;
  padding-bottom: 2rem;
  // background-color: red;
}

.man {
  height: 13rem;
  // width: 1rem;
  // aspect-ratio: 1;
  // object-fit: contain;
}

.registrationsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // background: red;

  flex: 1;
  padding: 1rem 0.4rem;
}

.regTop {
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;
}

.subHeadingBox {
  font-family: 'Poppins-Regular';
  font-size: 0.8rem;
}
.dashedBorder {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #979797;
}
.numberBox {
  padding: 1rem;
  margin-right: 1rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(224, 229, 241, 0.5);
  flex: 1;

  p {
    opacity: 0.69;
    font-family: Poppins-Bold;
    font-size: 1.8rem;
    color: #707070;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 0.4rem;
  }
  span {
    font-family: Poppins-Regular;
    font-size: 0.8rem;
    color: #3e3e3e;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
  }
}

.passesIssuedBox {
  align-items: center;
  margin-bottom: 1rem;
}

.passesIssuedImage {
  height: 3.4rem;
  aspect-ratio: 1;
  object-fit: contain;
}

.bottomContainer {
  width: 100%;
  min-height: 30vh;

  padding: 0 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.leftBoxes {
  width: 60%;
  gap: 1rem;
}

.rightBoxes {
  width: 40%;
  gap: 1rem;
}
.barBox {
  background: #fff;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // margin-right: 1rem;
}
.smallBoxContainer {
  // padding: 1rem;

  flex: 1;
  flex-direction: column;
  height: 85%;
  background: blue;
  overflow: hidden;
}

.smallBoxContent {
  height: 50%;
  background: red;
  align-items: center;
  justify-content: space-between;

  .smallBoxContentItem {
    width: 100%;
    padding: 0rem 1rem;
    justify-content: space-between;
    align-items: center;
    img {
      height: 2rem;
      object-fit: contain;
      aspect-ratio: 1;
      margin-right: 0.4rem;
    }
  }
}

.bottomBoxImage {
  height: 1rem;
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: 0.4rem;
}
.metrics {
  padding: 1rem;
}
.metricContainer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  p {
    font-family: Poppins-Regular;
    font-size: 0.8rem;
  }

  span {
    font-family: Poppins-Bold;
    font-size: 1.2rem;
  }
}

.bar {
  height: 1rem;
  border-radius: 25px;
  width: 100%;
  background: rgba(232, 225, 153, 0.65);
}
.barContainer {
  position: relative;
  height: 1rem;
  border-radius: 25px;
  width: 100%;
  background: #f0eeee65;
  overflow: hidden;
}
