.container {
  // padding: 1vh 1vw;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.crossIcon {
  position: absolute;
  right: 10px;
  top: -2.8rem;
  width: 1rem !important;
  height: 1rem !important;
  z-index: 300;

  img {
    height: 0.6rem;
    object-fit: contain;
  }
}

.content {
  padding: 1rem 2rem;
  width: 100%;
}

.heading {
  font-size: 0.8rem;
  font-family: Poppins-Medium;
  margin-bottom: 1rem;
}

.guide {
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.iconContainer {
  padding: 1rem 1rem;
  background: #f8faff;
  border-right: 1px solid #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 7rem;
  object-fit: contain;
}

.guideContent {
  flex: 1;
  flex-direction: column;
  padding: 1rem 1rem;

  h1 {
    font-size: 0.9rem;
    font-family: Poppins-Bold;
  }

  p {
    font-family: Poppins-Light;
    color: #86909e;
    font-size: 0.7rem;
  }
}

.CTAButton {
  // width: 45%;
  font-size: 0.8rem !important;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  text-decoration: none;
  display: inline-flex;
  width: 8rem;
  margin-right: 1rem;
  background: #e6edff;
  border: none;
}
