.container {
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 2vh;
    text-align: center;
    font-size: 0.8rem;
  }
}

.image {
  width: 8vw;
  aspect-ratio: 1;
}

.submitButton {
  width: 40%;
}

.clickHere {
  font-size: 0.8rem;
  font-family: 'Poppins-Regular';
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
}
