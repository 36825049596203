@import '../../assets/sass/_variables.scss';
.container {
  width: 50%;
  position: relative;
  background-color: white;
  border-radius: 5px;
  /* border-left: solid; */
  flex-direction: column;
  /* padding: 0.89vh; */
  height: 600 * $vw-const;
  width: 600 * $vw-const;
  padding: 10 * $vw-const;
}
.bodyContainer {
  padding: 40 * $vw-const;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
.webcamContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: -12 * $vw-const;
  right: -12 * $vw-const;
  width: 25 * $vw-const;
  height: 25 * $vw-const;
  color: #000000;
  cursor: pointer;
}
.back {
  font-family: 'Poppins-Medium';
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 10 * $vw-const;
    width: 25 * $vw-const;
  }
}
.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20 * $vw-const;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading {
  font-family: 'Poppins-SemiBold';
  font-size: 18 * $vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
}
.message {
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}
.attachIcon {
  width: 15 * $vw-const;
  margin-left: 10 * $vw-const;
  position: absolute;
  right: 10 * $vw-const;
}
.captureIcon {
  width: 67 * $vw-const;
  height: 67 * $vw-const;
  cursor: pointer;
}
.tickIcon {
  width: 40 * $vw-const;
  height: 40 * $vw-const;
  cursor: pointer;
  margin-right: 50 * $vw-const;
  margin-left: 35 * $vw-const;
}
.retakeIcon {
  width: 40 * $vw-const;
  height: 40 * $vw-const;
  cursor: pointer;
}
.button {
  text-align: center;
  background: $blue-100;
  border: none;
  color: $white;
  text-align: center;
  border-radius: 5px;
  font-size: 16 * $vw-const;
  letter-spacing: 0.64px;
  font-weight: 600;
  height: 48 * $vw-const;
  margin: 10 * $vw-const;
  padding: 10 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 344 * $vw-const;
}

.uploadBoxClassNames {
  text-align: center;
  background: $blue-100;
  border: none;
  color: $white;
  text-align: center;
  border-radius: 5px;
  font-size: 16 * $vw-const;
  letter-spacing: 0.64px;
  font-weight: 600;
  height: 48 * $vw-const;
  margin: 10 * $vw-const;
  padding: 10 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  width: 344 * $vw-const;
  margin-bottom: 25 * $vw-const;
  img {
    position: absolute;
    right: 10 * $vw-const;
    bottom: 15 * $vw-const;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }
}
