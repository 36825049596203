@import '../../assets/sass/fonts.scss';
@import '../../assets/sass/_variables.scss';
.leftContainer {
  /* font-family: "Poppins-Regular"; */
  width: 55vw;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //  background-image: url('../../assets/images/login-left-bgm/image.png');
  // box-shadow: inset 0 0 0 1000px rgba(0, 8, 28, 0.9);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  .sannaLogo {
    width: 110 * $vw-const;
    margin-left: 20 * $vw-const;
    margin-top: 20 * $vw-const;
    z-index: 1;
  }
}
.carouselRoot {
  outline: none;
  position: absolute;
  width: 55vw;
  left: 0;
  box-shadow: inset 0 0 0 1000px rgba(0, 8, 28, 0.8);
  // background-color: rgba($color: #000000, $alpha: 1);
}
.image1 {
  background-image: url('../../assets/images/carousel/1.png');
  width: 55vw;
  height: 100vh;
  box-shadow: inset 0 0 0 1000px rgba(0, 8, 28, 0.8);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.image2 {
  background-image: url('../../assets/images/carousel/2.png');
  width: 55vw;
  height: 100vh;
  box-shadow: inset 0 0 0 1000px rgba(0, 8, 28, 0.8);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.image3 {
  background-image: url('../../assets/images/carousel/3.png');
  width: 55vw;
  height: 100vh;
  box-shadow: inset 0 0 0 1000px rgba(0, 8, 28, 0.8);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.eyeContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  .eyeIcon {
    width: 198 * $vw-const;
    margin-bottom: 20 * $vw-const;
  }
  div {
    margin-bottom: 5 * $vw-const;
  }
}
.emblemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 820 * $vw-const;
  .emblemIcon {
    width: 292 * $vw-const;
  }
  .logo {
    width: 140 * $vw-const;
    aspect-ratio: 1;
  }
}
.heading1 {
  font-family: 'Poppins-Bold';
  font-size: 45 * $vw-const;
  color: #ffffff;
  letter-spacing: 1.79px;
  text-align: center;
  font-weight: 700;
}
.headerWrapper {
  margin-bottom: 125 * $vw-const;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 85 * $vw-const;
  z-index: 1;
  .copyright {
    margin-top: 20 * $vw-const;
  }
}
.mobilePlayStoreIcons {
  // background: red;
  position: absolute;
  bottom: 0;
  //  width: 100%;
  height: 4vh;
  margin-bottom: 10px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  p {
    font-family: inherit;
    font-weight: 400;
    font-size: 0.8rem;
  }
  .playStoreIcon {
    height: 20 * $vw-const;
    aspect-ratio: 1;
    margin-right: 10 * $vw-const;
    margin-left: 10 * $vw-const;
  }
  .appStoreIcon {
    height: 20 * $vw-const;
    aspect-ratio: 1;
    margin-left: 10 * $vw-const;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1080px) {
  .leftContainer {
    display: none;
  }
}
