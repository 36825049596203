@import '../../../../../assets/sass/variables';

.personCardsContainer {
  background: #f8faff;
  font-family: 'Poppins-Regular';
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  padding: 1vh 2vw;
  margin-bottom: 4vh;
}

.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 1.6rem;
}
.image {
  height: 1.8vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
}

.imageContainer {
  border-radius: 5px;
  overflow: hidden;
  background: #e6edff;
  padding: 0.2vw;
  margin-right: 1vw;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
}

.cardButtonsContainer {
  margin-bottom: 4vh;
}

// Card buttons
.cardButton {
  font-family: inherit;
  font-size: 1rem;
  background: transparent;
  border: none;
  color: #000;
  padding: 1vh 1vw;
  cursor: pointer;
}

.activeButton {
  font-family: inherit;
  font-size: 1rem;
  background: #87abff;
  border: none;
  color: #fff;
  border-bottom: 2px solid #002c94;
  padding: 1vh 1vw;

  cursor: pointer;
}

// Temp styles
// @import "../../../../../assets/sass/variables";

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 90%;
}
.bodyContainer {
  flex: 1;
  justify-content: center;
}
.tableWrapper {
  font-family: 'Poppins-Regular';
  // height: 600px;
  // max-height: 60vh;
  width: 100%;
  padding: 0 10px;
  // z-index: 1;
  margin-bottom: 1vh;
}

// .defaultContainerStyle {
//   flex: 1;
//   display: inline-table;
//   // height: 47.92vh;
//
//   height: 100%;
//   padding-left: 6.302vw;
//   padding-right: 4.479vw;
// }
.rowHeader {
  font-size: 1rem;
  color: #202428;
  background-color: #e6edff;
  font-weight: bold !important;
}
.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428 !important;
  font-size: 14px;
  font-weight: 400;
}
.statusLabel {
  font-size: 14px;
  color: #202428;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.status {
  display: flex;
  height: 24px;
  width: 102px;
  justify-content: center;
  align-items: center;
  // margin-top: 6px;
  background: #efefef;
  border: 1px solid #d4d4d5;
  border-radius: 5px;
}
.bottomLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 0.8rem;
  // width: 90%;
}
.filterBarContainer {
  // width: 90%;
  // margin-top: 50px;
  padding: 0 2vw;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  // z-index: 2;
}
.dropdown {
  margin-right: 25px;
  display: flex;
  flex-direction: row;
}
.inputDate {
  background-color: #e6edff !important;
  min-height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 9.625vw;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #002c94;
  justify-content: space-between;
  border-radius: 5px;
}
.inputText {
  background-color: #e6edff !important;
  height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 400px;
  font-size: 0.8rem;
  border-radius: 5px;
  // margin-right: 2vw;
  background: #e6edff;
}
.searchBox {
  margin-right: 1vw;
}
.inputSearch {
  align-items: center;
  justify-content: center;
}
.button {
  width: 140px !important;
  font-size: 1rem;
}
.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.clearLabel {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  color: #e67717;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
}
.datePicker {
  position: absolute;
  background-color: white;
  top: 3.66vh;
  left: 12px;
  box-shadow: 0px 0.27vh 0.55vh #00000029;
  z-index: 1;
}
.dateWrapper {
  position: relative;
}

.actionButtonsContainer {
  padding: 4px;

  button {
    color: #000;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    margin-right: 1vw;
  }

  .approve {
    background: #0ac15780;
  }
  .reject {
    background: #e6771780;
  }
}

.selectWrapper {
  margin-right: 1vw;
}

.defaultContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// background: red;

.noDataimage {
  height: 12.5vw;
  margin-bottom: 50px;
}

