@import '../../assets/sass/_variables.scss';
.rightSection {
  height: 100vh;
  width: 800 * $vw-const;
  width: 45vw;
  overflow-y: auto;
  font-family: 'Poppins-Regular';
  display: flex;
  align-items: center;
}

.leftSection {
  width: 50vw;
  background: linear-gradient(135deg, #001953 0%, #195eff 100%);
  background-size: contain;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 800px;
}

.leftSectionImage {
  width: 40vw;
  height: 100vh;
  overflow-y: hidden;
  background-image: url('../../assets/images/left-section.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.backgroundImage {
  // background-image: url('../../assets/images/login-background/image@3x.png');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // height: 100vh;
  // width: 100vw;
}

.authLayout {
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &__left_section {
    width: 55%;
    background: linear-gradient(135deg, #001953 0%, #195eff 100%);
    background-size: contain;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 800px;
    &__header {
      text-align: center;
      .title {
        display: flex;
        align-items: center;
        span {
          font-family: 'Poppins-Bold';
          font-size: 2rem;
          color: #fff;
          letter-spacing: 0.07rem;
          text-align: center;
          font-weight: 700;
        }
        img {
          width: 2.13rem;
          height: 2.13rem;
        }
      }
      span {
        font-family: 'Poppins-SemiBold';
        font-size: 1.5rem;
        color: #fff;
        letter-spacing: 0.06rem;
        text-align: center;
        font-weight: 600;
      }
    }
    &__image_container {
      width: 80%;
      height: 100%;
      background-image: url('../../assets/images/left-section.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__right_section {
    width: 45%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1080px) {
  .rightSection {
    width: 90%;
    flex-wrap: wrap;
    align-self: center;
    margin: 0 auto;
    > div {
      flex: 1;
    }
  }
  .leftSection {
    display: none;
  }
}
