.tableWrapper {
  // height: 600px;
  width: 100vw;
  max-height: 60vh;
  overflow: auto;
}

.image {
  width: 1.4vw;
  aspect-ratio: 1;
  margin-right: 2vw;
}
