.container {
  font-family: 'Poppins-Regular';
  background: #f8faff;
  // width: 70vw;
  // overflow: auto;
}

.header {
  background: #002c94;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
  position: relative;

  h1 {
    font-size: 1.4rem;
    color: #fff;
    text-align: center;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.columnLayout {
  margin-bottom: 1vh;
  width: 30%;
  flex: 1;
  flex-wrap: wrap;
}

.mainContainer {
  // justify-content: space-between;
  padding: 0 1vw;
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.imageTextContainer {
  margin-bottom: 10vh;
  // padding-top: 4vh;
}
.image {
  height: 10vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 2vh;
  align-self: flex-start;
}

.text {
  margin-bottom: 1vh;
  font-size: 0.8rem;
}

.checkboxes {
  align-items: center;
  padding: 0 1vw;
  margin-bottom: 2vh;

  .checkbox {
    margin-right: 0.2vw;
  }

  .label {
    margin-right: 2vw;
    font-size: 0.8rem;
  }
}

.bottomFields {
  padding: 0 1vw;
  margin-bottom: 2vh;
}

.formInput {
  width: 30%;
  margin-right: 5vw;
}

.buttonsContainer {
  justify-content: center;
  margin-bottom: 2vh;

  button {
    font-family: inherit;
    border: none;
    padding: 1vh 2vw;
    color: #fff;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
  }

  .approve {
    background: #0045e6;
  }
  .reject {
    background: #e67717;
    color: #fff;
  }
  .surrender {
    background: #e67717;
    color: #fff;
  }
}

.closeIcon {
  position: absolute;
  right: 1vw;
  width: 1vw;
  aspect-ratio: 1;
}

.containerClassNames {
  height: 40px !important;
  width: 18vw;
  margin-right: 2vw;
}

.labelClassNames {
  font-size: 0.8rem;
}

.error {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 2vh;
  color: red;
}

.history {
  width: 70%;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: bold;
  border: none;
  padding: 1vh 2vw;
  color: #0045e6;
  border-radius: 5px;
  margin-right: 2vw;
  background: #e6edff;
  cursor: pointer;
}
.finalButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.back {
  position: absolute;
  left: 1vw;
  background-color: #e67717;
}

.referenceId {
  color: #0045e6;
  font-family: 'Poppins-semibold';
}

.radioContainer {
  margin: 2vh 0vw;
}

.radioHeader {
  font-size: 1rem;
  margin-bottom: 1vh;
  font-family: 'Poppins-semibold';
}

.rejectText {
  color: #e67717;
  height: 6vh;
  text-align: center;
}
.rejectTextarea {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 1vh 1vw;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
