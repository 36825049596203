@import '../../assets/sass/variables';

.container {
  width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 400 * $vw-const;
  min-height: 400 * $vw-const;
  position: relative;
}

.pdfClassName {
  height: 80vh;
  overflow: auto;
}
.documentViewContainer {
  padding: 10px;
  background-color: #fff;
  min-width: 75vw;
  min-height: 85vh;
  max-width: 75vw;
  max-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// scrollbar CSS
.viewContainer::-webkit-scrollbar {
  width: 0.7vw;
  height: 0.7vw;
}
/* Track */
.viewContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #002c9470;
  border-radius: 10px;
  margin-block: 0.5em;
}

/* Handle */
.viewContainer::-webkit-scrollbar-thumb
// ::-moz-scrollbar-thumb
{
  background: #002c9470;
  border-radius: 10px;
  background-clip: content-box;
}

/* Handle on hover */
.viewContainer::-webkit-scrollbar-thumb:hover
// ::-moz-scrollbar-thumb:hover
{
  background: #002c9470;
  border: 3px solid transparent;
  background-clip: content-box;
}
.imageViewer {
  min-width: 30vw;
  max-width: 75vw;
  max-height: 85vh;
  object-fit: contain;
  overflow: auto;
}

.button {
  position: absolute;
  right: -10px;
  top: -10px;
  border: none;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
  // height: 20px;
  // width: 20px;
}

.crossIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0px;
}

.viewContainer {
  min-width: 30vw;
  max-width: 75vw;
  max-height: 85vh;
  // object-fit: fill;
  overflow: auto;
  flex: 1;
  width: 100%;
  height: 100%;
}

.buttonsContainer {
  position: fixed;
  bottom: 10px;
  border-radius: 5px;
  background: #fff;
  // padding: 0.8vh 2vw;
  display: flex;
  align-items: center;
  background: #0045e6;
  justify-content: space-around;
  width: 12vw;
  height: 6vh;
}
.actionButton {
  border: none;
  background-color: transparent;
  // margin-right: 0.5vw;
  // margin-left: 0.5vw;
  cursor: pointer;
}
.rotateIcon {
  height: 20px;
  // width: 20px;
  // aspect-ratio: 1;
}
