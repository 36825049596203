.DateRangePicker__MonthHeader {
  justify-content: center;
}

.DateRangePicker__CalendarSelection {
  background-color: #5968ea;
  border: 1px solid #5968ea;
}

.usageHistoryTable .ag-cell[col-id='reconciliationStatus'],
.usageHistoryTable  .ag-ltr .ag-cell[col-id='reconciliationStatus'] {
  border-right: 0px solid #979797;
  border-left: 1px solid #979797!important;
}

