.headerContainer {
  padding: 1vh 2vw;
  margin-bottom: 4vh;
}
.headerInfo {
  align-items: center;
  & img {
    width: 20px;
    margin-left: 5px;
  }
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 1.6rem;
}
.image {
  height: 1.8vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
}

.imageContainer {
  border-radius: 5px;
  overflow: hidden;
  background: #e6edff;
  padding: 0.2vw;
  margin-right: 1vw;
}
@import '../../../../../assets/sass/_variables.scss';

.headerContainer {
  align-items: center;
  justify-content: space-between;
}

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
  padding: 20 * $vw-const;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: $blue-100;
  font-size: 32 * $vw-const;
}
.header {
  width: 100%;
  margin-top: 50 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dateWrapper {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 2vw;
  height: 100%;
  .datePicker {
    position: absolute;
    background-color: white;
    left: 12 * $vw-const;
    box-shadow: 0px 0.27vh 0.55vh #00000029;
    z-index: 20000 !important;
  }
  .inputDate {
    background-color: #e6edff !important;
    height: 39px;
    border: 0;
    border-radius: 5px;
    outline: none;
    width: 240 * $vw-const;
    font-size: 16 * $vw-const;
    display: flex;
    align-items: center;
    padding-left: 10 * $vw-const;
    padding-right: 10 * $vw-const;
    color: #002c94;
    margin-top: 3px;
    justify-content: space-between;
  }
  .calendar {
    width: 15px;
  }
}

// .tableWrapper {
//   width: 95%;
//   margin: 0 auto;
//   overflow: auto;
//   max-height: 60vh;
//   .ag-root-wrapper {
//     border: none !important;
//   }
// }


.tableWrapperAutoHeight {
  max-height: 60vh;
  width: 100%;
  // overflow: auto;
  overflow: auto;
  margin-left: 40* $vw-const;;
  .ag-root-wrapper {
    border: none !important;
  }
}


.tableWrapperNormal {
  height: 60vh;
  width: 100%;
  margin-left: 40* $vw-const;;

  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}


.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  border: none;
  //   height: 80 * $vw-const;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
  .status {
    .statusLabel {
      background: rgba(211, 211, 212, 0.2);
      border: 1px solid #d3d3d4;
      border-radius: 5px;
      width: 110 * $vw-const;
      padding-left: 10 * $vw-const;
      display: flex;
      align-items: center;
      height: 20px;
      .roundIcon {
        width: 10 * $vw-const;
        height: 10 * $vw-const;
        background: #d3d3d4;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin: 0 auto;
  margin-top: 2vh;
  font-size: 14 * $vw-const;
}

.applyBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 140 * $vw-const;
  font-size: 16 * $vw-const;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 20 * $vw-const;
  justify-content: space-between;
  margin-left: 40* $vw-const;;

}

.viewButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 140 * $vw-const;
  font-size: 16 * $vw-const;
  background: #0045e6;
  color: #fff;
  padding: 15* $vw-const;

  overflow: hidden;
  border: none;
  outline: none;
  border-radius: 5px;
  height: 20 * $vw-const;
}

.searchBox {
  margin-right: 1vw;
  margin-top: 0.5vh;
}
.inputSearch {
  align-items: center;
  justify-content: center;
}
.inputText {
  background-color: #e6edff !important;
  height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 380 * $vw-const;
  font-size: 16 * $vw-const;
  border-radius: 5px;

  // margin-right: 2vw;
  background: #e6edff;
}
.inputText::placeholder {
  color: rgb(123, 121, 121);
  font-size: 13 * $vw-const;
}

.statusStyles {
  border-radius: 5px;
  padding: 0.2vh 2vw;
  width: 14vw;
  text-align: center;
  width: 120* $vw-const;
  height: 30* $vw-const;
  display: flex;
  align-items: center;
  justify-content: center;  text-align: center;
}

.dropdown {
  margin-right: 2vw;
  margin-top: 0.2vh;
  display: flex;
  flex-direction: row;
}
.clearLabel {
  margin-right: 20px;
  font-size: 16 * $vw-const;
  color: #e67717;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
}

.typeText {
  text-transform: capitalize;
}

.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #b3bac2;
  text-align: center;
  position: fixed;
  bottom: 30 * $vw-const;
}
