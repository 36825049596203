.container {
  // padding: 1vh 1vw;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 90%;
}

.crossIcon {
  position: absolute;
  right: 10px;
  top: 0.4rem;
  width: 1.5rem !important;
  height: 1.5rem !important;
  z-index: 300;

  img {
    height: 0.9rem;
    object-fit: contain;
  }
}

.content {
  padding: 2rem;
  width: 100%;
  height: 100%;
}
.video {
  box-shadow: 0px 20px 20px 10px #00000024;
  display: block;
  border-radius: 5px;
  height: 60vh;
}
