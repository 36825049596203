/* absolute z-[999] -left-px  h-[0.1rem] w-[0.1rem] 
rounded-[9999px] bg-blue-700 shadow-[0_0_0_1px_#ffffff10] rotate-[180deg] */

.meteor {
  position: absolute;
  z-index: 999;
  left: -1px;
  height: 0.1rem;
  width: 0.1rem;
  border-radius: 9999px;
  background: #6366f1;
  box-shadow: 0 0 0 1px #ffffff10;
  transform: rotate(-90deg);
  animation: meteor 3s linear;
  /* Add variables for animation delay and duration */
  animation-delay: var(--meteor-delay, 0s);
  animation-duration: var(--meteor-duration, 2s);
}

.meteor::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: var(--meteor-width, 50px);
  height: 1px;
  background: linear-gradient(90deg, #6366f1, #7dd3fc, transparent);
}

@keyframes meteor {
  0% {
    top: 0;
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
}
