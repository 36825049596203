.container {
  background: #fff;
  height: 80%;
  width: 80%;
  border-radius: 5px;
  overflow: auto;
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;

  p {
    font-size: 1rem;
    font-weight: 'bold';
    color: #fff;
  }
}
.detailsContainer {
  margin-bottom: 2vh;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
  height: 80%;
  //   background: red;
}
.detailsColumn {
  margin-right: 2vw;
}

.personImage {
  border-radius: 5px;
  width: 6vw;
  aspect-ratio: 1;
  margin-right: 2vw;
}

.labelValueContainer {
  font-size: 0.8rem;
  margin-bottom: 2vh;
}

.label {
  color: #20242898;
}
.value {
  color: #002c94;
}
.documentIcon {
  height: 1.8vh;
  aspect-ratio: 1;
  cursor: pointer;
}

.goBack {
  //   width: 20%;
  padding: 1vh 2vw;
  border-radius: 5px;
  margin: 0 auto;
  border: none;
  background: #e6edff;
  color: #0045e6;
  cursor: pointer;
}
