.popupContainerStyle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  

.mimicContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}


.sidebar {
    width: 20vw;
    background-color: red;
}

.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #E5ECFF;
    // padding-top: 10vh;
}
.topContainer {
    height: 16vh;
    border-radius: 10px;
    width: 95%;
    margin: 0 auto;
    background: linear-gradient(to bottom, #1C4BBD 0%, #062675 100%);
    margin-bottom: 12vh;
    padding: 1vh 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
    p {
        color: #fff;
        font-size: 0.8rem;
    };
}

.date {
    margin-right: 1vw;
}
.graphContainer {
    background-color: #fff;
    width: 95%;
    min-height: 70%;
    padding: 2rem;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1);
}
.rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

}
.liveContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        color: #123DA3;
        font-size: 0.8rem;
    }
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
    justify-content: space-evenly;

}

.redCircle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: red;
    margin-right: 5px;
}

.graphPopup {
    width: 90%;
    height: 90%;
    padding: 4rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1);

}