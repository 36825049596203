.buttonPrimary {
  background-color: #e6edff !important;
  color: #0045e6 !important;
}
.buttonSecondary {
  background-color: #0045e6 !important;
}
.buttonDelete {
  background: #e67717 !important;
  color: #fff;
}

.popupContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
