@import "../../../../../assets/sass/variables";
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap");
.container {
  display: flex;
  flex: 1;
  height: 100vh;
}
.rightContainer {
  width: 45%;
  display: flex;
  padding: 0 1.563vw;
}
.backlabel {
  margin-top: 2.77vh;
  cursor: pointer;
  font-size: 0.875rem;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.registrationlabel {
  margin-top: 6.01vh;
  margin-bottom: 4.629vh;
  justify-content: center;
  font-size: 1.5rem;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
}
.backButtonIcon {
  height: 2.22vh;
  aspect-ratio: 1;
  margin-right: 0.521vw;
}
.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.filterDropdown {
  height: 4.26vh;
  outline: none;
  width: 16.41vw;
  border: 1px solid #e1e1e1;
  border-radius: 0.45vh;
  font-size: 0.875rem;
  margin-bottom: 5.09vh;
}
.successIcon {
  height: 14.81vh;
  aspect-ratio: 1;
  margin-bottom: 2.96vh;
}
.message1 {
  font-family: "Poppins-SemiBold";
  font-size: 1.125rem;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 2.96vh;
}
.message2 {
  font-family: "Poppins-SemiBold";
  font-size: 0.875rem;
  color: #202428;
  letter-spacing: 0.64px;
  text-align: center;
  line-height: 2.31vh;
  font-weight: 500;
  margin-bottom: 100px;
}
.body {
  text-align: center;
  width: 100%;
  padding-top: 2rem;
}
.id {
  font-family: "Poppins-SemiBold";
  font-size: 0.875rem;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  line-height: 2.31vh;
  font-weight: 500;
}
.here {
  color: #0045e6;
  font-size: 0.875rem;
  letter-spacing: 0.56px;
  text-align: center;
  line-height: 1.94vh;
  font-weight: 400;
}
.message3 {
  // font-family: "Poppins-SemiBold";
  // font-size: 0.875rem;
  // color: #202428;
  // letter-spacing: 0.56px;
  // text-align: center;
  // line-height: 1.94vh;
  // font-weight: 400;
  margin-bottom: 3rem;

  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #202428;
  letter-spacing: 0.56px;
  text-align: center;
  line-height: 21px;
  font-weight: 400;
}

.title {
  font-family: "Poppins-SemiBold";
  font-size: 1.5rem;
  color: #002c94;
  letter-spacing: 0.06rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 8rem;
}
