.container {
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
}

.backlabel {
  // margin-top: 30px;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;

  img {
    width: 1vw;
    aspect-ratio: 1;
    margin-right: 0.5vw;
  }
}

.header {
  font-size: 1.5rem;
  text-align: center;
  color: #002c94;
  font-family: 'Poppins-semibold';
  margin-bottom: 4rem;
}

.formContainer {
  width: 55%;
  align-self: center;
}
