.container {
  width: 40%;
  position: relative;
  background-color: white;
  /* border-left: solid; */
  flex-direction: column;
  /* padding: 0.89vh; */
}
.bodyContainer {
  padding: 4.16vh;
  flex-direction: column;
}
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
  cursor: pointer;
}
.buttonContainer {
  height: auto;
  padding: 0.89vh;
  flex-direction: row;
  justify-content: center;
  background-color: #f4f4f4;
}
.buttonContainer button {
  /* padding: 5px;
    border-radius: 5px; */
  /* background-color: transparent; */
}

.textContainer {
  flex-direction: row;
  flex: 1;
  /* padding: 0.89vh; */
  padding-right: 1.78vh;
  color: #707070;
  font-size: 1.85vh;
  display: flex;
  align-items: center;
}

.heading {
  color: #464646;
  font-size: 2.4vh;
  font-weight: bold;
  margin-bottom: 1.57vh;
}
.message {
  font-family: 'Poppins-Regular';
  font-size: 2vh;
}

.button {
  font-family: 'Poppins-Regular';
  width: 7.854vw;
  min-height: 3.56vh;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #0045e6 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  font-size: 2vh;
  margin-right: 10px;
  border: 0.01vw solid #215020;
  cursor: pointer;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}
.popupTextarea {
  height: 200px;
  width: 90%;
  padding: 15px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}
