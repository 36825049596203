@import '../../../../../assets/sass/_variables.scss';
.container {
  position: relative;
}
.heading {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-top: 10 * $vh-const;
  margin-bottom: 40 * $vh-const;
}
.headerInfo {
  align-items: center;
  // justify-content: space-between;
  position: relative;
}
.info {
  position: relative;
  left: 10 * $vw-const;
  top: 5 * $vw-const;
}
.toolTip {
  max-width: 500 * $vw-const;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: clamp(16px, 32 * $vw-const, 32 * $vw-const);
  letter-spacing: 1.27px;
  font-weight: 700;
}
.dateText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 1rem;
  letter-spacing: 1.27px;
  font-weight: 700;
}
.image {
  height: 24 * $vw-const;
  aspect-ratio: 1;
  background-size: cover;
}

.imageContainer {
  border-radius: 5px;
  background: #e6edff;
  margin-right: 20 * $vw-const;
  padding: 5px;
  height: 40 * $vw-const;
  width: 40 * $vw-const;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
}

.topSection {
  background: #f8faff;
  position: fixed;
  width: 80%;
}
.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #b3bac2;
  text-align: center;
  position: fixed;
  bottom: 7 * $vw-const;
}
