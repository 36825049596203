@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap");
.container {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: hidden;
}
.rightContainer {
  width: 45%;
  display: flex;
  padding: 0 30px;
}
.backlabel {
  margin-top: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.registrationlabel {
  margin-bottom: 50px;
  justify-content: center;
  font-size: 24px;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
}
.backButtonIcon {
  height: 24px;
  aspect-ratio: 1;
  margin-right: 10px;
}
.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 3rem;
}
.filterDropdown {
  height: 4.26vh;
  outline: none;
  width: 16.41vw;
  border: 1px solid #e1e1e1;
  border-radius: 0.45vh;
  font-size: 14px;
  margin-bottom: 55px;
}
.dropdown {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.fieldsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.downloadContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-bottom: 1rem;
}

.rowLabel {
  font-family: "Poppins-Regular";

  font-size: 0.7rem;
  color: #002c94;
  letter-spacing: 0.96px;
  font-weight: 900;

  margin-top: 0;
  margin-bottom: 0;
  width: 40%;

  text-align: end;
  margin-right: 2rem;
}

.downloadButton {
  display: flex;
  background: #0045e6;
  width: 8rem;
  height: 1.5rem;
  border: 1px solid;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Poppins-Bold";

  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
}
.downloadButton:hover {
  background: #0045e6;
  color: #ffffff;
}
.downloadButtonWrapper {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
