.container {
  background: #ffffff;
  min-height: 60%;
  width: 70%;
  border-radius: 10px;
}
.header {
  background: #002c94;
  border-radius: 5px 5px 0 0;
  height: 15%;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  font-family: Poppins-SemiBold;
  font-size: 1.2rem;
  color: #ffffff;
  letter-spacing: 0.9px;
  text-align: center;
  margin-bottom: 1rem;
}

.formData {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.backBtn {
  margin-right: 2rem;
  padding: 0.5rem 2rem;
  color: #0045e6;
  background: #e6edff;
  border: none;
}

.addBtn {
  padding: 0.5rem 2.1rem;
  border: none;
}
.formInput{width: 300px}
.btnContainer{ 
  display: flex;
  justify-content: center;
  margin: 8rem 0 1.5rem}