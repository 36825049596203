@import '../../../../../../assets/sass/_variables.scss';

.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 800 * $vw-const;
  min-height: 60%;
  max-height: 85%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
  justify-content: space-between;

  .bodyContainer {
    flex: 1;
    padding: 0 30* $vw-const;
  }
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;

  p {
    font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

    font-weight: 'bold';
    color: #fff;
  }
}

.filterLabel {
  font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
  line-height: 18 * $vw-const;
  color: #000000;
  line-height: 2;
}

.filtersWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 4vh;
}

.selectWrapper {
  margin-right: 10 * $vw-const;
}

.searchContainer {
  height: 100%;
  justify-content: center;
  width: 70%;
}

.searchInput {
  flex: 1;
  background: #e6edff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  padding: 12 * $vw-const;
  margin-right: 1px;
  height: 2.4rem;
  margin-top: 2px
}

.searchInput::placeholder {
  color: #002c9470;
}

.searchButton {
  display: flex;
  background: #e6edff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.4vh 1vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
  width: 1vw;
  aspect-ratio: 1;
  // flex: 1;
}

.personCardsContainer {
  min-height: 20vh;
  background: #F6F6F6;
  margin-bottom: 4vh;
  margin: 0 auto;
  margin-bottom: 2vh;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #B3BAC2;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  height: 15px;
  cursor: pointer;
}

.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 20* $vw-const;
  margin-top: 10* $vw-const;

  button {
    min-width: 6vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }

  .finish {
    background: #0045e6;
    color: #ffffff;
  }
}

.successDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  line-height: 2;
  position: absolute;
  bottom: -75* $vw-const;

  img {
    width: 80* $vw-const;
  }
}

.checkBoxContainer {
  font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

  .addLabel {
    margin-left: 15* $vw-const;
  }

  padding: 20* $vw-const;
  border: 1px solid #B3BAC2;
  border-radius: 5px;
  min-height: 65* $vw-const;
  margin-top: 70* $vw-const;
  width: 100%;
}

.personDetails {
  width: 100%;
}

.imageTextContainer {
  align-items: center;
  padding: 20* $vw-const 30* $vw-const;

  .lineContainer {
    justify-content: space-between;

    .dataLabel {
      line-height: 2;
      font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
    }

    .dataValue {
      line-height: 2;
      font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
      font-weight: bold;
      margin-left: 40* $vw-const;
      color: #002C94;
      font-family: 'Poppins-Bold';
    }

  }
}

.personImage {
  width: 185 * $vw-const;
  border-radius: 5px;
  margin-right: 40* $vw-const;
  cursor: pointer;
}

.textArea {
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);

  p {
    color: #202428;
  }

  span {
    font-weight: 500;
  }
}

.personAlreadyAdded {
  text-align: center;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  color: #e67717;
}

.vehicleContainer {
  margin-top: 20* $vw-const;
  width: 95%;

  .vehicleImage {
    background-color: white;
    padding: 20* $vw-const;
    ;
    margin-right: 30* $vw-const;
    ;

    img {
      width: 100 * $vw-const;
    }
  }
}

.addTwoWheelContainer {}

.twoWheelerInputBoxLabel {
  color: #030385;
  font-size: clamp(14px, 18 * $vw-const, 18 * $vw-const);
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.twoWheelerInput {
  flex: 1;
  background: #e6edff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  padding: 12 * $vw-const;
  margin-right: 1px;
  height: 2.4rem;
  margin-top: 60* $vw-const;
  margin-bottom: 60* $vw-const;
}


.twoWheelerInput::placeholder {
  color: #002c9470;
}