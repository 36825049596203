@import '../../../src/assets/sass/variables';
// Resets
p {
  margin-bottom: 0;
}

.container {
  // background-color: green;
  // width: 100%;
  // height: 50px;
}

.input {
  display: flex;
  font-family: 'Poppins-regular';
  background-color: 'transparent';
  border: none;
  border-radius: 5px;
  flex: 1;
  padding: 0 0.5rem;
  height: 100%;
}
.label {
  font-family: 'Poppins-Regular';
  margin-bottom: 0.3rem;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
}

.labelContainer {
  // background-color: purple;
  height: 'auto';
}
.input::placeholder {
  color: #d3d3d4;
}

.error {
  color: #e67717;
  line-height: unset;
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
}

.leftIconStyles {
  height: 1.2vw;
  aspect-ratio: 1;
}
.imageContainer {
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;
  // padding-left: 0.6vw;
  // padding-right: 0.6vw;
  // height: 100%;
  border-right: 1px solid #b3bac2;
}

.inputBox {
  font-family: 'Poppins-regular';
  border: 1px solid #b3bac2;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  position: relative;
  height: inherit;
  height: 50px;

  // padding: 0.5rem;
}

.timepicker {
  height: 100%;
  width: 100%;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  // FIXME: Probable solution for timepicker height issue
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    font-family: Poppins-Regular;
    font-size: 14px;
    border: none;
  }
  input::placeholder {
    color: #d3d3d4;
  }
  a {
    display: none;
  }
}

.placeholder {
  color: #d3d3d4;
}
