.container {
  font-family: 'Poppins-Regular';
  background: #fff;
  height: 90vh;
  width: 70vw;
  border-radius: 5px;
  overflow: auto;
}

.header {
  background: #002c94;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
  position: relative;

  h1 {
    font-size: 1.4rem;
    color: #fff;
    text-align: center;
  }
}

.buttonsContainer {
  justify-content: center;

  button {
    font-family: inherit;
    border: none;
    padding: 1vh 2vw;
    color: #0045e6;
    border-radius: 5px;
    margin-right: 2vw;
    width: 8vw;
    cursor: pointer;
  }

  .okay {
    background: #e6edff;
  }
}

.childrenContainer {
  flex: 1;
  align-items: center;
  height: 60%;
  font-size: 0.8rem;

  .subheading {
    color: #e67717;
    font-weight: bold;
    margin-bottom: 4vh;
  }
  p {
    text-align: center;
  }
}

.image {
  width: 10vw;
  aspect-ratio: 1;
}

.formsContainer {
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  margin-bottom: 4vh;
}
.containerClassNames {
  height: 40px !important;
  width: 18vw;
  margin-right: 2vw;
}

.remark {
  background: #d3d3d4;
  border: none;
  width: 40%;
  border-radius: 5px;
  padding: 1vh 1vw;
}
.imageTextareaContainer {
  width: 80%;
  justify-content: space-between;

  // background: red;
}

.captureBox {
  align-items: flex-end;
  justify-content: center;
  height: 40vh;
  width: 40%;
  border-radius: 5px;
  background: #d3d3d4;

  overflow: hidden;
}

.captureButton {
  font-family: inherit;
  border: none;
  padding: 1vh 2vw;
  color: #0045e6;
  border-radius: 5px;
  margin-bottom: 2vh;
  // width: 8vw;
  background: #e6edff;
}
.captureIconBlue {
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #0045e6;
}
.videoContainer {
  // position: relative;
  // z-index: 100;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.video {
  width: 80%;
  height: 80%;
  object-fit: cover;
  margin-bottom: 2vh;
  border-radius: 5px;
}
.captureButtonsContainer {
  width: 100%;
  justify-content: space-evenly;
}

.errorContainer {
  height: 4vh;
  justify-content: center;
  color: #e67717;
}
