.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
}

.checkbox {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    position: relative;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    display: inline-block;
}

.icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 13px;
    aspect-ratio: 1;
}

.text {
    color: #767676;
    font-size: 0.7rem;
    cursor: pointer;
    display: inline-block;
}