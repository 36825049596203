.container {
  display: flex;
  width: 500px;
  height: 300px;
  background: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  .inputBlock {
    width: 200px;
    text-align: center;
  }
  .heading {
    font-size: clamp(12px, 0.72917vw, 0.72917vw);
    font-family: 'Poppins-Regular';
    text-align: left;
  }
  input {
    width: 200px;
    height: 40px;
    padding: 10px;
    border: 1px solid #b3bac2;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 40px;
  }
  .addButtonStyles {
    border: none;
    background: #e6edff;
    color: #0045e6;
    border-radius: 5px;
    cursor: pointer;
    min-width: 120px;
    font-size: clamp(12px, 0.83333vw, 0.83333vw);
    padding: 0.52083vw;
  }
  .addButtonStyles:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
