.buttonPrimary {
  background-color: #e6edff !important;
  color: #0045e6 !important;
  cursor: pointer;
}
.buttonSecondary {
  background-color: #0045e6 !important;
  cursor: pointer;
  transition: all 0.1s ease;
}
  
.buttonSecondary:hover {
  background-color: #3773ff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.buttonSecondary:active {
  transform: scale(0.95);
}

.popupContainerStyle {
  position: absolute;
  top: 0;
}
