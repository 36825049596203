.inputBoxClassName {
  height: 50px;
}

.mobileInputBoxClassName {
  height: 50px;
}

// .selectBoxClassName {
//   background-color: red;
// }

.dateClassNames {
  //   background: red;
  height: 50px;
}

.uploadBoxClassNames {
  height: 50px;
}
