@import '../../../../../assets/sass/variables';

.personCardsContainer {
  background: #f8faff;
  font-family: 'Poppins-Regular';
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 1.6rem;
}
.image {
  height: 1.8vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
}
.imageBlue {
  height: 4.5vw;
}

.imageContainer {
  border-radius: 5px;
  overflow: hidden;
  background: #e6edff;
  padding: 0.2vw;
  margin-right: 1vw;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
  padding: 1vh 2vw;
  margin-bottom: 4vh;
}

.filterBarContainer {
  // width: 90%;
  // margin-top: 50px;
  padding: 0 2vw;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.inputText {
  background-color: #e6edff !important;
  height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 400px;
  font-size: 1.75vh;
  border-radius: 5px;
  background: #e6edff;
  color: #202428;
}
.searchBox {
  margin-right: 1vw;
}
.button {
  width: 140px !important;
  font-size: 1rem;
}

.h165 {
  height: 165px;
}
.selectWrapper {
  margin-right: 1vw;
}

.bodyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.defaultContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formContainer {
  padding: 0 1vw;
  padding-top: 4vh;
  padding-bottom: 4vh;
  flex: 1;
}
.formFields {
  // width: 33%;
  // padding-left: 30px;
  // flex-wrap: wrap;
  // background: red;
  // margin-bottom: 1vh;
  // width: 30%;
  // // flex: 1;
  // flex-wrap: wrap;
  // background: red;
  margin-right: 1vw;
  margin-bottom: 1vh;
  // width: 3%;
  flex: 1;
  flex-wrap: wrap;
}

.containerClassNames {
  height: 40px !important;
  width: 18vw;
  margin-right: 1vw;
}

.vehicleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.vehicleInfoLable {
  width: 100%;
  font-family: Poppins-Medium;
  font-size: 14px;
  color: #202428;
  letter-spacing: 0.56px;
  font-weight: 500;
  margin-bottom: 10px;
}
.labelClassNames {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #202428;
  letter-spacing: 0.56px;
  font-weight: 400;
}
.topSection {
  display: flex;
  margin-bottom: 4vh;
  // padding-left: 2vw;
}
.bottomSection {
  display: flex;
  margin-left: 30px;
  flex-direction: row;
}
.formInput {
  width: 25%;
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
}
.checkboxes {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-left: 30px;

  .checkbox {
    margin-right: 0.2vw;
  }

  .label {
    margin-right: 2vw;
    font-size: 0.8rem;
  }
}

.bottomFields {
  padding: 0 30px;
}

.buttonsContainer {
  justify-content: center;
  margin-bottom: 2vh;

  button {
    font-family: inherit;
    border: none;
    padding: 1vh 2vw;
    color: #fff;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
    font-weight: 400;
    min-width: 12vw;
    font-size: 0.8rem;
  }
}
.noDataimage {
  height: 12.5vw;
  margin-bottom: 50px;
}
.approvedStatus {
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0.56px;
  text-align: center;
  font-weight: 500;
  padding: 2px 16px;
  color: white;
  background: #0ac157 100%;
  border-radius: 3px;
}

.holdStatus {
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0.56px;
  text-align: center;
  font-weight: 500;
  padding: 2px 16px;
  color: white;
  border-radius: 3px;
  background: #e67717;
}
.successContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .messageWrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 2vh;
    // height: 70%;
    background-color: #fff;
    justify-content: center;
    align-items: center;
  }
  .message {
    font-family: Poppins-SemiBold;
    font-size: 18px;
    color: #002c94;
    letter-spacing: 0.72px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .remarks {
    font-family: Poppins-Medium;
    font-size: 14px;
    color: #202428;
    letter-spacing: 0.56px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 100px;
  }
  .referenceNumber {
    font-family: Poppins-Medium;
    font-size: 14px;
    color: #202428;
    letter-spacing: 0.56px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .imageBlue {
    margin-bottom: 30px;
  }
  button {
    font-family: Poppins-SemiBold;
    font-size: 16px;
    border: none;
    padding: 0.6vh 0.6vw;
    color: #0045e6 !important;
    border-radius: 5px;
    // margin-right: 2vw;
    width: 8vw;
    cursor: pointer;
    background: #e6edff;
  }
  .successIcon {
    height: 160px;
    aspect-ratio: 1;
    margin-bottom: 32px;
  }
}

.avatarImage {
  height: 10vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 2vh;
  align-self: flex-start;
  padding: 2vw;
}

.text {
  margin-bottom: 1vh;
  font-size: 0.8rem;
}
.history {
  width: 70%;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: bold;
  border: none;
  padding: 1vh 2vw;
  color: #0045e6;
  border-radius: 5px;
  margin-right: 2vw;
  background: #e6edff;
  cursor: pointer;
}
.inputText {
  font-size: 0.8rem;
}

// .SannaLogo {
//   position: absolute;
//   left: 0;
//   bottom: 0;
// }

.editSubmit {
  background: #0045e6;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  // width: 10vw!important;
}

.editCancelBtn {
  background: #e6edff;
  color: #0045e6 !important;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  // width: 10vw!important;
}
.editButtonsContainer {
  margin-top: -175 * $vw-const;
  margin-left: -250 * $vw-const;
  margin-bottom: 100 * $vw-const;
}
