.featuresContainer {
  min-height: 40rem;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: 10rem 4rem;
}

.leftContainer {
  // width: 40%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.rightContainer {
  display: flex;

  // width: 100%;
  flex-direction: column;
  // border-left: 1px dashed #d9d9d9;
  position: relative;
}

.borderLeft {
  position: absolute;
  left: 0;
  top: -10rem;
  height: 200%;
  width: 1px;
  border-left: 1px dashed #d9d9d9;
}
.smartPorts {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  color: #000;
}
.topHeader {
  display: flex;
  align-items: flex-start;
}
.polygon {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-left: -1px;
  margin-top: 0.5rem;
}
.subText {
  margin-top: 1rem;
  margin-right: 1rem;

  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  color: #464646;
  font-size: 1.2rem;
}

.topHeaderText {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  max-width: 45rem;
  line-height: 1.8rem;
}

.strong {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  // padding: 0rem 1rem;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.imageContainer {
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  margin-bottom: 1rem;
}

.gridItemText {
  text-align: center;
  // font-size: 0.8rem;
  max-width: 16rem;
  margin: 0 auto;
  font-size: 1.2rem;
}

.divider {
  border-right: 1px dashed #d9d9d9;
}

.leftHeaderText {
  margin-top: 4rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  // max-width: 20rem;
  // line-height: 1.8rem;
  color: #464646;
  font-size: 1.2rem;
}
