// @import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap');
.container {
  font-family: 'Poppins-SemiBold';
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 50%;
  justify-content: space-between;
  background: #ffffff;
  align-items: center;
  border-radius: 4px;
}
.header {
  height: 12%;
  width: 100%;
  background: #002c94;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  // border-bottom-left-radius: 2px;
  border-top-right-radius: 4px;
  // border-bottom-right-radius: 4px;
  & p {
    // height: 100%;
    font-family: 'Poppins-SemiBold';
    font-size: 1.2rem;
    letter-spacing: 0.04rem;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
  }
}

.body {
  font-size: 1.1rem;
  color: #e67717;
  letter-spacing: 1.27px;
  text-align: center;
  font-weight: 700;
  & img {
    margin-bottom: 1.6rem;
    height: 2.3rem;
    width: 3rem;
  }
}

.footer {
  // margin-bottom: 2rem;
  display: flex;
  width: 44%;
  height: 20%;
  justify-content: space-around;
  & button {
    height: 50%;
    padding: 0rem 1rem;
    border: none;
    border-radius: 5px;
  }
  & .delete {
    background: #e67717;

    font-size: 0.8rem;
    color: #ffffff;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
  }
  & .cancel {
    background: #e6edff;

    font-size: 0.8rem;
    color: #0045e6;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
  }
}
