@import '../../../../../../assets/sass/_variables.scss';
.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 90%;
  height: 90%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;

  p {
    font-size: 1rem;
    font-weight: 'bold';
    color: #fff;
  }
}
.h2 {
font-size: 18* $vw-const;
color: #202428;
letter-spacing: 0.72px;
font-weight: 600;
text-align: center;
margin-top: 20* $vw-const;
margin-bottom: 20* $vw-const;
}
.formContainer {
  flex-wrap: wrap;
  margin-top: 35 * $vh-const;
  margin-left: 50* $vw-const;
  

  > div {
    width: 344 * $vw-const;
    margin-right: 60 * $vw-const;
    margin-bottom: 20 * $vh-const;
  }
}
.containerClassNames {
  font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);  
}
.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-top: 120* $vh-const;
  position: relative;
  button {
    min-width: 160  * $vw-const;;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 2px;
    cursor: pointer;
  }
  .delete {
    background: #E67717;
    color: #ffffff;
    position: absolute;
    left: 50* $vw-const;
  }
  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
  }
}