.DateRangePicker__MonthHeader {
    justify-content: center;
  }
  
  .DateRangePicker__CalendarSelection {
    background-color: #5968ea;
    border: 1px solid #5968ea;
  }
  
  .DateRangePicker__MonthHeader {
    justify-content: center;
  }
  
  .DateRangePicker__CalendarSelection {
    background-color: #5968ea;
    border: 1px solid #5968ea;
  }
  
  .ag-header-cell[col-id='action']{
    border-right: 0px solid #979797 !important;
  }