:root {
  --first-circle-initial: 100deg;
  --first-circle-final: 460deg;

  --second-circle-initial: -40deg;
  --second-circle-final: 320deg;

  --third-circle-initial: 200deg;
  --third-circle-final: 560deg;

  --fourth-circle-initial: 10deg;
  --fourth-circle-final: 370deg;

  --random-rotation: 0deg;
}

.technologyOverviewContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40rem;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: 10rem 4rem;

  // height: 55rem;
}

.leftContainer {
  height: 100%;
}
.leftContainerContent {
  position: relative;
  height: 100%;
}

.rightBar {
  position: absolute;
  right: -1rem;
  height: 80%;
  width: 4px;
  border-radius: 10px;
  background: #d9d9d9;
}

.header {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: #ec8940;
  text-transform: uppercase;
  margin-bottom: 5rem;
}

.featuresContainer {
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;

  h3 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  p {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
    padding-bottom: 1rem;
  }
}
// .featuresContainer:hover {
//   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
// }
.widthBar {
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: #ec8940;
  height: 4px;
  border-radius: 10px;
}

.regularBorder {
  border-bottom: 1px dashed #9e9e9e30;
}
.highlightedBorder {
  border-bottom: 1px dashed #ec8940;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  //   background: green;
}
.rightContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circleBoundary {
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
  border: 1px dashed #82828270;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.illustration {
  width: 20.5rem;
  height: 20.5rem;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 40;
}
.illustrationAI {
  width: 20.5rem;
  height: 20.5rem;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 40;
}
.illustrationSmartAnalytics {
  width: 20.5rem;
  height: 20.5rem;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1.2rem;
  right: 0;
  margin: auto auto;
  z-index: 40;
}
.cctvIllustration {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 40;
}

.circle {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  box-shadow: 0px 0px 10px 2px #00000024;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: #ffffff50;

  img {
    opacity: 0.6;
  }
}

.circle:hover {
  // -webkit-animation-play-state: paused;
  // -moz-animation-play-state: paused;
  // -o-animation-play-state: paused;
  // animation-play-state: paused;
  cursor: pointer;
}

.ciclegraph {
  position: relative;
  width: 20rem;
  height: 20rem;
  // margin: calc(100px / 2 + 0px);
  margin: 0 auto;
  transition: all 1s linear;
}

.ciclegraph:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border: 1px dashed #9e9e9e30;
  width: calc(100% - 2px * 2);
  height: calc(100% - 2px * 2);
  border-radius: 50%;
}
.innerCircleCCTV {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ciclegraph .circleCCTV {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  margin: calc(-3rem / 2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(3px);
}
.cctvCircleAnimation {
  -webkit-animation: cctvCircle 20s linear infinite;
  animation: cctvCircle 20s linear infinite;
}
.cctvCircleAnimationReverse {
  -webkit-animation: cctvCircleReverse 20s linear infinite;
  animation: cctvCircleReverse 20s linear infinite;
}

.firstCircleAnimation {
  -webkit-animation: firstCircle 20s linear infinite;
  animation: firstCircle 20s linear infinite;
}

.secondCircleAnimation {
  -webkit-animation: secondCircle 30s linear infinite;
  animation: secondCircle 30s linear infinite;
}
.thirdCircleAnimation {
  -webkit-animation: thirdCircle 50s linear infinite;
  animation: thirdCircle 50s linear infinite;
}

.fourthCircleAnimation {
  -webkit-animation: fourthCircle 50s linear infinite;
  animation: fourthCircle 50s linear infinite;
}

.progressValue {
  animation: load 10s linear forwards;
  border-radius: 100px;
  background: #ec8940;
  height: 5px;
  width: 0;
  margin-bottom: -2px;
}

.animateSpinSlow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 22rem;
  width: 22rem;
  margin: auto auto;
  animation: spinSlow 2s linear infinite;
}

@keyframes spinSlow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Animations
@keyframes load {
  0% {
    width: 1%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes cctvCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cctvCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cctvCircleReverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes cctvCircleReverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes firstCircle {
  0% {
    transform: rotate(var(--first-circle-initial)) translate(-7.6rem)
      rotate(calc(var(--first-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--first-circle-final)) translate(-7.6rem)
      rotate(calc(var(--first-circle-final) * -1));
  }
}
@keyframes firstCircle {
  0% {
    transform: rotate(var(--first-circle-initial)) translate(-7.6rem)
      rotate(calc(var(--first-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--first-circle-final)) translate(-7.6rem)
      rotate(calc(var(--first-circle-final) * -1));
  }
}

// Second
@-webkit-keyframes secondCircle {
  0% {
    transform: rotate(var(--second-circle-initial)) translate(10rem)
      rotate(calc(var(--second-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--second-circle-final)) translate(10rem)
      rotate(calc(var(--second-circle-final) * -1));
  }
}
@keyframes secondCircle {
  0% {
    transform: rotate(var(--second-circle-initial)) translate(10rem)
      rotate(calc(var(--second-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--second-circle-final)) translate(10rem)
      rotate(calc(var(--second-circle-final) * -1));
  }
}

// third
@-webkit-keyframes thirdCircle {
  0% {
    transform: rotate(var(--third-circle-initial)) translate(-12.5rem)
      rotate(calc(var(--third-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--third-circle-final)) translate(-12.5rem)
      rotate(calc(var(--third-circle-final) * -1));
  }
}
@keyframes thirdCircle {
  0% {
    transform: rotate(var(--third-circle-initial)) translate(-12.5rem)
      rotate(calc(var(--third-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--third-circle-final)) translate(-12.5rem)
      rotate(calc(var(--third-circle-final) * -1));
  }
}

// fourth
@-webkit-keyframes fourthCircle {
  0% {
    transform: rotate(var(--fourth-circle-initial)) translate(15rem)
      rotate(calc(var(--fourth-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--fourth-circle-final)) translate(15rem)
      rotate(calc(var(--fourth-circle-final) * -1));
  }
}
@keyframes fourthCircle {
  0% {
    transform: rotate(var(--fourth-circle-initial)) translate(15rem)
      rotate(calc(var(--fourth-circle-initial) * -1));
  }
  100% {
    transform: rotate(var(--fourth-circle-final)) translate(15rem)
      rotate(calc(var(--fourth-circle-final) * -1));
  }
}
