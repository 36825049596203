.container {
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vw;
}

.formContainer {
  width: 70%;
  align-self: center;
}
.backlabel {
  // margin-top: 30px;
  font-family: "Poppins-Regular";
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;

  img {
    width: 4vw;
    aspect-ratio: 1;
    margin-right: 2vw;
  }
}

.header {
  font-size: 1.5rem;
  text-align: center;
  color: #002c94;
  font-family: "Poppins-semibold";
  margin-bottom: 4rem;
}

.forgotPasswordContainer {
  align-self: flex-end;
  margin-bottom: 2vh;
}

.forgotPassword {
  font-family: "Poppins-Regular";
  background: transparent;
  border: none;
}
.submitButton {
  font-size: 1rem;
}
