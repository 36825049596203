@import '../../../../../assets/sass/_variables.scss';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 99%;
  padding: 20 * $vw-const;
  background: #e5ecff;
  
}

.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 32 * $vw-const;
  margin-top: 24 * $vh-const;
}
.header {
  width: 100%;
  margin-top: 50 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContainer {
  display: flex;
    justify-content: space-between;
    margin-bottom: 25* $vw-const;
    margin-top: 25* $vw-const;
}
.profileContainer {
  min-width: 200 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10 * $vw-const;
  cursor: pointer;
  background: white;
  border-radius: 5px;
  // margin-top: 30 * $vw-const;
  .pic {
    width: 40 * $vw-const;
    height: 40 * $vw-const;
    // background-color: gray;
    margin-right: 10px;
  }
  .name {
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    font-family: 'Poppins-SemiBold';
    color: #002C94;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
  }
  .role {
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    font-family: 'Poppins-Regular';
    color: #202428;
    letter-spacing: 0.56px;
    text-align: right;
    font-weight: 400;
  }
  .settingBtn {
    width: clamp(10px, 30 * $vw-const, 30 * $vw-const);
    position: relative;
    margin-left: 10px;
    img {
      position: absolute;
      right: 10px;
    }
  }
}
.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 50px;
  color: #b3bac2;
  text-align: center;
}
.link {
  text-decoration: none;
}



.toolTip {
  max-width: 500 * $vw-const;
}
.legendContainer {
  font-size: 16* $vw-const;
  margin-top: 20* $vw-const;
  justify-content: center;
  display: flex;
  div {
    align-items: center;
  }
  .fillColor {
    width: 30* $vw-const;
    height: 30* $vw-const;
    margin-left: 30* $vw-const;
    margin-right: 10 * $vw-const;
  
  }
}

.detailsContainer {
  display: flex;
  width: 100%;
  background-image: linear-gradient(134deg, #0057C6 13%, #002C94 100%);
  border-radius: 10* $vw-const;
  height: 180* $vw-const;
  justify-content: flex-start;
  margin-bottom: 175* $vw-const;
  padding-top: 10* $vw-const;
  flex-direction: column;
    align-items: center;
    .filterContainer {
      display: flex;
      justify-content: space-between;
      width: 95%;
      margin-bottom: 10px;
      .name {
        font-family: 'Poppins-Medium';
        font-size: 28* $vw-const;
        color: #F0F0F0;
        letter-spacing: 1.12px;
        font-weight: 500;
      }
    }
  .statusContainer {
    display: flex;
    margin-bottom: -175* $vw-const;
    align-items: center;
    justify-content: center;
    width: 97%;
  .statusCard {
//    margin-right: 20* $vw-const;
  }
}

}

.detailsContainer .statusCard {
  background: #FFFFFF;
  box-shadow: 3px 2px 10px -4px rgba(0,0,0,0.50);
  border-radius: 10px;
  width: 339* $vw-const;
  height: 186* $vw-const;
  padding: 20 * $vw-const;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20 * $vw-const;
  .title {
    font-family: 'Poppins-SemiBold';
    font-size: 16* $vw-const;
    color: #141414;
    letter-spacing: 0.64px;
    text-align: left;
    font-weight: 600;

  }
  .icon {
    width: 25 * $vw-const;
  }
  .boxContainer {
    display: flex;
    justify-content: space-between;
    .approved {
      background-image: linear-gradient(180deg, #F2FAF6 0%, #E1F3E9 100%);
    }
    .rejected {
      background-image: linear-gradient(180deg, #FCF7F3 0%, #F7ECE2 100%);
    }
    .pending {
      background-image: linear-gradient(180deg, #F8F2E0 0%, #EEE1BE 100%);
      border-radius: 5px;
    }
    .box {
      border-radius: 5px;
      margin-right: 10* $vw-const;
      width: 95* $vw-const;
      height: 72* $vw-const;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    .count {
      font-family: 'Poppins-SemiBold';
      font-size: 20* $vw-const;
      color: #000000;
      letter-spacing: 0.8px;
      text-align: center;
      font-weight: 600;
    }
    .label {
      font-family: 'Poppins-Light';
      font-size: 14* $vw-const;
      color: #000000;
      letter-spacing: 0.56px;
      text-align: center;
      font-weight: 200;
    }
  }
  }
}

.dateWrapper {
  position: absolute;
  left: 35%;
  .datePicker {
    position: absolute;
    background-color: white;
    left: 12 * $vw-const;
    box-shadow: 0px 0.27vh 0.55vh #00000029;
    z-index: 1;
  }

  .dateRange {
   // background-color: #0ac157;
  }
}
.inputDate {
  color: #e6edff;
  font-size: clamp(8px, 14 * $vw-const, 14 * $vw-const);
  padding-left: 10 * $vw-const;
  padding-right: 10 * $vw-const;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropDown {
  height: 2.2rem;
  margin-top: 0.2rem;
  width: 11rem;
  border-width: 0;
  border-radius: 5;
  font-family: 'Poppins-Light';
  font-size: 16* $vw-const;
  color: #141414;
  letter-spacing: 0.64px;
  font-weight: 200;
  
}