@import '../../assets/sass/_variables.scss';

.col1DisplayItems {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 3vh;
  padding-bottom: 3vh;
  width: 20vw;
  flex: 1;
  height: 100vh;
  overflow: auto;
}

.topContainer {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.centerAlign {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* flex: 1 1; */
  align-items: center;
  background-color: #e6edff;
  border-radius: 5px;
  overflow: hidden;
  /* background-color: red; */
  width: 90%;
  /* align-self: flex-center; */
  margin: 0 auto;
}

.imageParent {
  width: 100%;
  height: 50px;
  /* padding: 2.67vh 3.67vh 2.67vh 3.67vh; */
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  align-items: center;
  background-color: #e6edff;
  border: none;
  margin-bottom: 1px;
  color: #ffffff;
}

.bottomButton {
  width: 90%;
  min-height: 50px;
  margin-top: 20px;
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  align-items: center;
  background-color: #e6edff;
  border: none;
  margin-bottom: 1px;
  color: #ffffff;
  align-self: center;

  justify-self: flex-end;
  border-radius: 5px;
  cursor: pointer;
}

.currentModule {
  width: 90%;
  height: 50px;
  /* padding: 2.67vh 3.67vh 2.67vh 3.67vh; */
  justify-content: flex-start;
  padding-left: 1vw;
  padding-right: 1vw;
  align-items: center;
  // background-color: #002c94;
  border: none;
  color: #ffffff;
  margin: 0 auto;
  margin-bottom: 0.2vh;
  border-radius: 5px;
  cursor: pointer;
}

.currentModuleText {
  font-family: Poppins-Medium;
  font-size: 19 * $vw-const;
  letter-spacing: 0px;
  opacity: 0.7;
  color: #002c94;
  letter-spacing: 0.72px;
  text-overflow: ellipsis;
  //  white-space: nowrap;
  text-decoration: unset;
  text-transform: capitalize;
}

.currentParent {
  width: 90%;
  height: 50px;
  /* padding: 2.67vh 3.67vh 2.67vh 3.67vh; */
  justify-content: flex-start;
  padding-left: 1vw;
  padding-right: 1vw;
  align-items: center;
  //  background-color: #e5ecff;
  border: none;
  margin: 0 auto;
  margin-bottom: 0.2vh;
  border-radius: 5px;

  .currentModuleText {
    opacity: 0.7;
    color: #002c94;
    letter-spacing: 0.72px;
  }
}

.imageChild {
  width: 2.49vh;
  height: 2.23vh;
  margin-right: 1.25vw;
}

.renderCol1Text {
  font-family: Poppins-Medium;
  font-size: 16 * $vw-const;
  letter-spacing: 0px;
  color: #002c94;
  opacity: 1;
  text-overflow: ellipsis;
  // white-space: nowrap;
  text-decoration: unset;
}

.link {
  /* color: unset; */
  display: flex;
  width: 100%;
  text-decoration: unset;
  /* display: flex; */
}

.linkText {
  text-decoration: unset;
}

.logoIcon {
  height: 130 * $vw-const;
  margin-right: 0.625rem;
}

.edrishtiLogo {
  height: 80px;
  // aspect-ratio: 1;
  margin-right: 0.625rem;
}

.logoInfo {
  /* background: red; */
  width: 90%;
  margin-left: 0.625rem;
  margin-bottom: 60px;
  align-items: center;
  justify-content: space-between;
}

.dashboard {
  width: 90%;
  margin-left: 0.625rem;
  margin-bottom: 30px;
  align-items: center;
}

.rowLabel {
  font-family: Poppins-Medium;
  font-size: 1.2rem;
  color: #0045e6;
  letter-spacing: 0.96px;
  font-weight: bold;
}

.dashboardLabel {
  font-family: Poppins-Regular;
  color: #002c94;
  opacity: 0.7;
  font-weight: 600;
}

.dashboardIcon {
  height: 1.2rem;
  margin-right: 1.2rem;
}

.rightIcon {
  width: 1vw;
  aspect-ratio: 1;
}

.rotateIcon {
  transform: rotate(90deg);
  margin-left: 40px;
}

.logo {
  width: 1.4vw;
  margin-right: 0.6vw;
}

.zIndex9999 {
  z-index: 9999 !important;
}

.popUpStyle {
  left: 120px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}