@import '../../../../assets/sass/variables';

.container {
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  padding: 2vw;
  align-items: center;
}

.mainContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.paymentButton {
  color: #fff;
  background: rgb(38, 130, 255);
  border: none;
  border-radius: 5px;
  min-height: 6vh;
  width: 10%;
  cursor: pointer;
}

.backlabel {
  // margin-top: 30px;

  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}

.backButtonIcon {
  height: 24px;
  aspect-ratio: 1;
  margin-right: 10px;
}
.topContainer {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4vh;
}
.languageContainer {
  // position: absolute;
  // right: 15 * $vw-const;
  // top: 15 * $vw-const;
  background: #e6edff;
  border-radius: 16.5 * $vw-const;
  font-size: 14 * $vw-const;
  width: 170 * $vw-const;
  display: flex;
  justify-content: space-between;
  div {
    padding: 5px 10 * $vw-const;
    cursor: pointer;
  }
  .currentLanguage {
    background: rgba(131, 175, 251, 0.35);
    border-radius: 16.5 * $vw-const;
    font-family: 'Poppins-Medium';
    font-size: 14 * $vw-const;
    color: #202428;
    letter-spacing: 0.56px;
    text-align: center;
    width: 90 * $vw-const;
    text-decoration: underline;
  }
}
.header {
  font-size: 1.4rem;
  color: #002c94;
  margin-bottom: 1vh;
}
.paymentContainer {
  background: #e6edff;
  padding: 2vh 1vw;
  width: 100%;
  margin-bottom: 2vh;
  border-radius: 5px;

  button {
  }
}
.tryAgain {
  background: #0045e6;
  border-radius: 5px;
  border: none;
  color: #fff;
  width: 40%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: pointer;
  margin-bottom: 2vh;
}

.payLater {
  background: #fff;
  border: 1px solid #0045e6;
  border-radius: 5px;
  color: #0045e6;
  width: 40%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: pointer;
}

.paymentRow {
  justify-content: space-between;
  margin-bottom: 2vh;
}

.payButton {
  background: #0045e6;
  border-radius: 5px;
  border: none;
  color: #fff;
  width: 40%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: pointer;
}

.paymentRowLabel {
  font-size: 0.8rem;
  color: #202428;
}

.divider {
  height: 4vh;
  margin-bottom: 4vh;
  border-bottom: 1px dashed #000;
}

.footerContainer {
  font-family: 'Poppins-Regular';
  font-size: 12 * $vw-const;
  color: #86909e;
  letter-spacing: 0.48px;
  font-weight: 400;
  margin-top: 100 * $vh-const;
  .copyright {
    margin-top: 10 * $vw-const;
  }
  .privacy,
  .terms,
  .help {
    cursor: pointer;
    margin-right: 10 * $vw-const;
  }
  .privacy:hover,
  .terms:hover,
  .help:hover {
    text-decoration: underline;
    color: #687e9c;
  }
}
.successContainer {
  align-items: center;
  .successIcon {
    width: 8vw;
    aspect-ratio: 1;
    margin-bottom: 2vh;
  }
  h1 {
    font-size: 1.2rem;
    color: #002c94;
    font-weight: bold;
    margin-bottom: 4vh;
  }
  p {
    font-size: 0.8rem;
    color: #202428;
    // margin-bottom: 4vh;
  }
  .applicationType {
    font-size: 0.8rem;
    margin-bottom: 4vh;
    text-align: center;
  }
  .refIdText {
    text-align: center;
    margin-bottom: 4vh;
  }
  .refId {
    color: #0045e6;
    font-weight: 900;
  }
  .bottomText {
    text-align: center;
    max-width: 70%;
    margin-bottom: 4vh;
  }
  .here {
    color: #0045e6;
    text-decoration: underline;
    cursor: pointer;
  }
}
.failureContainer {
  align-items: center;
  width: 100%;
  .successIcon {
    width: 8vw;
    aspect-ratio: 1;
    margin-bottom: 2vh;
  }
  h1 {
    font-size: 1.2rem;
    color: #002c94;
    font-weight: bold;
    margin-bottom: 4vh;
  }
  p {
    font-size: 0.8rem;
    color: #202428;
    // margin-bottom: 4vh;
  }
  .applicationType {
    font-size: 0.8rem;
    margin-bottom: 4vh;
    text-align: center;
  }
  .refIdText {
    text-align: center;
    margin-bottom: 4vh;
  }
  .refId {
    color: #0045e6;
    font-weight: 600;
  }
  .bottomText {
    text-align: center;
    max-width: 70%;
    margin-bottom: 4vh;
  }
  .here {
    color: #0045e6;
    text-decoration: underline;
    cursor: pointer;
  }
  .failureBottomText {
    color: #e67717;
    margin-bottom: 4vh;
    text-align: center;
  }
}
