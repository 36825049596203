@import '../../../../../../assets/sass/_variables.scss';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //  width: 95%;
  padding: 20 * $vw-const 0;
}
.bodyContainer {
  width: 95%;
  margin: 0 auto;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: $blue-100;
  font-size: 32 * $vw-const;
}
.header {
  width: 100%;
  margin-top: 30 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  align-items: center;
  .agencyNameDropdown {
    margin-right: 25 * $vw-const;
    display: flex;
    flex-direction: row;
    * {
      font-size: 16 * $vw-const;
    }
  }
  .status {
    margin-right: 25 * $vw-const;
    display: flex;
    flex-direction: row;
    font-size: 16 * $vw-const;
    * {
      color: #002c94 !important;
      font-size: 16 * $vw-const;
    }
  }
  .dateWrapper {
    position: relative;
    .datePicker {
      position: absolute;
      background-color: white;
      left: 12 * $vw-const;
      box-shadow: 0px 0.27vh 0.55vh #00000029;
      z-index: 1;
    }
    .inputDate {
      background-color: #e6edff !important;
      height: 39px;
      border: 0;
      border-radius: 0;
      outline: none;
      width: 240 * $vw-const;
      font-size: 16 * $vw-const;
      display: flex;
      align-items: center;
      padding-left: 10 * $vw-const;
      padding-right: 10 * $vw-const;
      color: #002c94;
      margin-top: 3px;
      justify-content: space-between;
    }
    .calendar {
      width: 15px;
    }
  }
}
.right {
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  .inputText {
    background-color: #e6edff;
    height: 39px;
    border: 0;
    border-radius: 0;
    outline: none;
    width: 600 * $vw-const;
    font-size: 14 * $vw-const;
    margin-left: 0px;
    border-radius: 5px;
    margin-right: 0;
  }
  .clearLabel {
    font-size: 14 * $vw-const;
    color: #e67717;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 100;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 20px;
  }
 
  .applyBtn {
    width: 140 * $vw-const;
    font-size: 16 * $vw-const;
    transition: all 0.1s ease;
  }
  .applyBtn:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .applyBtn:active {
    transform: scale(0.95);
  }

}

.tableWrapperAutoHeight {
  max-height: 60vh;
  width: 100%;
  overflow: auto;
}

.tableWrapperNormal {
  height: 60vh;
  width: 100%;
  overflow: auto;
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  border: none;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;

  .status {
    .statusLabel {
      background: rgba(211, 211, 212, 0.2);
      border: 1px solid #d3d3d4;
      border-radius: 5px;
      width: 110 * $vw-const;
      padding-left: 10 * $vw-const;
      display: flex;
      align-items: center;
      height: 20px;
      .roundIcon {
        width: 10 * $vw-const;
        height: 10 * $vw-const;
        background: #d3d3d4;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
  .statusApproved {
    .statusLabel {
      background: rgba(10, 193, 87, 0.2);
      border: 1px solid #0ac157;
      border-radius: 5px;
      // background: rgba(211, 211, 212, 0.2);
      // border: 1px solid #d3d3d4;
      .roundIcon {
        background: #0ac157;
      }
    }
  }
  .statusRejected {
    .statusLabel {
      background: rgba(230, 119, 23, 0.2);
      border: 1px solid #e67717;
      .roundIcon {
        background: #e67717;
      }
    }
  }
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  font-size: 14 * $vw-const;
}

.downloadBtn {
  background: #0045e6;
  font-size: 0.8rem;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  width: 140 * $vw-const;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.8vh;
  margin-right: 1vw;
}
.SannaLogo {
  position: absolute;
  bottom: 0;
  left: 40%;
}