@import '../../../src/assets/sass/variables';

// Resets
p {
  margin-bottom: 0;
}

.container {
  // background-color: green;
  // width: 100%;
  // height: 50px;
}
.mobCode {
  color: #d3d3d4;
  font-family: 'Poppins-Regular';
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  letter-spacing: 0.56px;
  font-weight: 400;
}
.input {
  display: flex;
  font-family: 'Poppins-regular';
  background-color: 'transparent';
  border: none;
  border-radius: 5px;
  flex: 1;
  padding: 0 0.5rem;
  height: 100%;
  width: 100%;
}
.label {
  font-family: 'Poppins-Regular';
  margin-bottom: 0.3rem;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
}

.labelContainer {
  // background-color: purple;
  height: 'auto';
}
.input::placeholder {
  color: #d3d3d4;
}

.error {
  color: #e67717;
  line-height: unset;
  margin-right: 2.5rem;
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.2;
}

.leftIconStyles {
  height: 1.2vw;
  aspect-ratio: 1;
}
.imageContainer {
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;
  // padding-left: 0.6vw;
  // padding-right: 0.6vw;
  // height: 100%;
  border-right: 1px solid #dfdfe0;
}

.inputBox {
  font-family: 'Poppins-regular';
  border: 1px solid #b3bac2;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  position: relative;
  height: inherit;
  height: 50px;

  // padding: 0.5rem;
}
.mobileVerified {
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  position: absolute;
  bottom: -30 * $vw-const;
  color: #002c94;
  right: 0;
}
.verifyOtpText {
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  width: clamp(50px, 100 * $vw-const, 100 * $vw-const);
  text-align: right;
  position: absolute;
  bottom: -30 * $vw-const;
  right: 0px;
}

@media screen and (max-width: 700px) {
  .verifyOtpText {
    font-size: 0.7rem;
    top: 3.5rem;
    width: 100%;
    position: absolute;
    bottom: 0px;

  }
}
