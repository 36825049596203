.buttonPrimary {
  background-color: #e6edff !important;
  background: #e6edff !important;

  color: #0045e6 !important;
}
.buttonSecondary {
  background-color: #0045e6 !important;
}

.popupContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
