.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 80%;
  height: 80%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #002a94;
  & .left {
    padding: 1rem 0 1rem 2.7rem;
    width: 30%;
    & img {
      height: 4rem;
      width: 5rem;
    }
    & .sanna {
      align-items: center;
      & img {
        height: 0.7rem;
        width: 2.3rem;
      }
      & p {
        padding: 0.2rem 0.2rem 0 0;
        color: #d9d7d7;
        font-size: 0.6rem;
        font-weight: 600;
      }
    }
  }
  .center {
    color: #2d6dcd;
    font-size: 1.8rem;
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
  }
  & .right {
    width: 30%;
    font-family: Poppins-Medium;
    font-size: 12px;
    color: #030303;
    letter-spacing: 0.48px;
    text-align: left;
    font-weight: 500;
    background: #002a94;
    padding: 1rem 2rem 1rem 1.5rem;
    border-bottom: 1px solid #002a94;

    & .name {
      font-family: Poppins-Bold;
      font-size: 1.2rem;
      color: #f0f0f0;
      letter-spacing: 0.72px;
      line-height: 20px;
      font-weight: 700;
    }
    & .value {
      font-family: Poppins-SemiBold;
      font-size: 1.2rem;
      color: #f0f0f0;
      letter-spacing: 0.72px;
      line-height: 20px;
      font-weight: 600;
    }
    & .date {
      font-family: Poppins-Bold;
      font-size: 0.7rem;
      color: #f0f0f0;
      letter-spacing: 0.44px;
      font-weight: 700;
      margin-top: 1rem;
    }
  }
}
.body {
  width: 100%;
  justify-content: space-between;
  & .left,
  .right {
    width: 50%;
    padding: 2rem 0 1rem 2rem;
  }
  & .right {
    width: 50%;
    padding: 2rem 0 10rem 4em;
  }

  & P {
    align-items: flex-start;
    font-family: Poppins-Regular;
    font-size: 0.9rem;
    color: #000000;
    letter-spacing: 0.56px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 0.3rem;
  }
  .left {
    
  }
}
.table {
  margin-bottom: 10rem;

  & table {
    width: 100%;
    border-collapse: collapse;
    
  }
}
.body {
  & .left {
    border-right: 1px solid #d2d2d2;
  }
}

.table thead {
  padding: 1rem;
  font-family: Poppins-Bold;
  font-size: 10px;
  color: #f3f3f3;
  letter-spacing: 0.4px;
  text-align: center;
  font-weight: 700;
  background: #002a94;
  & tr th {
    padding: 1rem;
  }
}

.table tbody {
  font-family: Poppins-Medium;
  font-size: 12px;
  color: #030303;
  letter-spacing: 0.48px;
  text-align: center;
  font-weight: 500;
  & .lightBlue td {
    background: #f4f7ff;
  }
  & td {
    padding: 1rem;
  }
}

tfoot {
  border-top: 1px solid #002a94;
  & td {
    padding: 0.4rem;
  }
  & .key {
    font-family: Poppins-Bold;
    font-size: 0.7rem;
    color: #040404;
    letter-spacing: 0.4px;
    text-align: center;
    font-weight: 700;
  }
  & .value {
    font-family: Poppins-Medium;
    font-size: 0.8rem;
    color: #030303;
    letter-spacing: 0.48px;
    text-align: center;
    font-weight: 500;
  }
  & .blueKey,
  .blueValue {
    background-color: #002a94;
    padding: 1rem;
    font-family: Poppins-Bold;
    font-size: 0.9rem;
    color: #f8f8f8;
    letter-spacing: 0.56px;
    text-align: center;
    font-weight: 700;
  }
}
.body {
  & .left {
    & .bold {
      font-family: Poppins-Bold;
      font-size: 1rem;
      color: #000000;
      letter-spacing: 0.56px;
      line-height: 21px;
      font-weight: 700;
    }
  }
  & .right {
    & .bold {
      font-family: Poppins-Bold;
      font-size: 1rem;
      color: #000000;
      font-weight: 700;
    }
  }
}

.right {
  & .rightPlace {
    .key {
      font-family: Poppins-SemiBold;
      font-size: 0.8rem;
      color: #000000;
      letter-spacing: 0.48px;
      line-height: 18px;
      font-weight: 600;
    }
    .value {
      font-family: Poppins-Regular;
      font-size: 0.8rem;
      color: #000000;
      letter-spacing: 0.48px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

.content {
  justify-content: space-between;
  padding: 1rem;
  align-items: end;
  & .left {
    padding-left: 1rem;
    & .bold {
      font-family: Poppins-Bold;
      font-size: 0.8rem;
      color: #000000;
      letter-spacing: 0.48px;
      line-height: 18px;
      font-weight: 700;
    }
    & p {
      font-family: Poppins-Regular;
      font-size: 10px;
      color: #000000;
      letter-spacing: 0.48px;
      line-height: 16px;
      font-weight: 400;
    }
    & img {
      height: 1.7rem;
      width: 1.8rem;
    }
  }
  & .right {
    padding-right: 1rem;
    & .key {
      font-family: Poppins-Bold;
      font-size: 10px;
      color: #000000;
      letter-spacing: 0.4px;
      font-weight: 700;
    }
    & .value {
      font-family: Poppins-Regular;
      font-size: 10px;
      color: #000000;
      letter-spacing: 0.4px;
      text-align: right;
      line-height: 16px;
      font-weight: 400;
    }
    p {
      font-family: Poppins-Regular;
      font-size: 10px;
      color: #000000;
      letter-spacing: 0.48px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}

.voidContent {
  background: #002a94;
  height: 4vh;
  margin-bottom: 4vh;
}

.rowBgcolor {
  background: #f4f7ff;
}

.invoiceButton {
  border: none;
  padding: 0.2vh 1vw;
  background: #0045E6;
  color: #fff;
  border-radius: 5px;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  height: 4vh;
  cursor: pointer;
  width: 6vw;
}
.closeButton {
  border: none;
  padding: 0.2vh 1vw;
  background: #f4f7ff;
  color: #0045E6;
  border-radius: 5px;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  height: 4vh;
  cursor: pointer;
  width: 6vw;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 4vh;
}

.tableHeader {
  border-bottom: 1px solid #002a94;
}

.bottomText {
  text-align: center;
  padding: 0;
  font-size: 14px;
  margin-bottom: 20px;
}
.playOfSupply {
  margin-top: 50px;
  font-size: 14px;
}