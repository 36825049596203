@import '../../assets/sass/variables';
//  Person details css in RequestCard Popup
.cardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40 * $vh-const;
}
.cardWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15 * $vw-const;
  // opacity: 0.5;
  background: #eceff7;
  border-radius: 5px;
  min-height: 120 * $vw-const;
  padding: 10 * $vw-const;
}
.cardheader {
  font-size: 18 * $vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  font-weight: 600;
}
.cardImage {
  height: 80 * $vw-const;
  width: 80 * $vw-const;
  margin-right: 20 * $vw-const;
}
.info {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14 * $vw-const;
}
.requestLabel {
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 500;
  position: relative;
}
.requestKey {
  font-size: 14 * $vw-const;
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 400;
  margin-bottom: 7 * $vh-const;
}
.truckIcon {
  height: 50 * $vw-const;
  width: 50 * $vw-const;
  margin-right: 30 * $vw-const;
}
.documentIcon {
  width: 20 * $vw-const;
  margin-left: 5 * $vw-const;
  position: absolute;
}
