@import "../../../assets/sass/variables";

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoContainer {
  padding-top: 2.81rem;
  text-align: center;
  display: block;
  margin-bottom: 2.5rem;
  width: 23.69rem;
  img {
    margin-bottom: 0.93rem;
  }
  h2 {
    font-family: "Poppins-Bold";
    font-size: 2rem;
    color: #0045e6;
    letter-spacing: 0.07rem;
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-family: "Poppins-SemiBold";
    font-size: 24px;
    color: #0045e6;
    letter-spacing: 0.96px;
    text-align: center;
    font-weight: 600;
  }
}

.formContainer {
  width: 21.5rem;
  margin-bottom: 2.5rem;
  p {
    font-family: "Poppins-SemiBold";
    font-size: 1.5rem;
    color: #002c94;
    letter-spacing: 0.06rem;
    font-weight: 600;
  }

  input[type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.loginButton {
  background: #0045e6;
  width: 21.5rem;
  height: 3rem;
  border: 1px solid;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Poppins-Bold";
  margin: 5px auto;
  margin-bottom: 1.88rem;
}

.forgotPasswordRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins-Regular";
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -20px;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.8rem;
    }
  }
  a {
    font-family: "Poppins-Regular";
    font-size: 0.75rem;
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
}

.create_user {
  text-align: center;
  display: block;
  margin-bottom: 0.88rem;
  span {
    font-family: "Poppins-Regular";
    font-size: 0.88rem;
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 400;
    width: 13.94rem;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 2.13rem;
  .buttonPrimaryOutlined {
    background: #ffffff;
    width: 21.5rem;
    height: 3rem;
    border: 1px solid;
    border-radius: 5px;
    color: $blue-100;
    font-family: "Poppins-Bold";
    margin: 5px auto;
    margin-bottom: 1.88rem;
    font-size: 1rem;
  }
}
