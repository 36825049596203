@import '../../../src/assets/sass/variables';
.dropdown {
  // width: 16.25vw;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  //padding: 0rem 1rem;
}
.selectLabelClass {
  //margin-bottom: 0.5rem;
}

.select {
  background-color: white;
  border: 1px solid #b3bac2;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;

  // color: #d3d3d4;
}
select,
select option {
  color: #000000;
}

select:invalid,
select option[value=''] {
  color: red;
}
.option {
  width: 100%;
}
.optionPlaceholder {
  // color: #d3d3d4;
  color: red;
}

.label {
  margin-bottom: 0.3rem;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  font-family: 'Poppins-Regular';
}
.error {
  color: #e67717;
  margin-bottom: '0.3rem';
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
}
