@import "../../../../../assets/sass/variables";

.parentContainer {
  background: #f8faff;
  font-family: 'Poppins-Regular';
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
  margin: 1vh 2vw;
  margin-bottom: 2vh;
  .headerText {
    font-family: 'Poppins-Bold';
    color: #002c94;
    font-size: 1.6rem;
  }
  .image {
    height: 1.8vw;
    aspect-ratio: 1;
    background-size: cover;
    border-radius: 5px;
  }
  .imageBlue {
    height: 4.5vw;
  }
  .imageContainer {
    border-radius: 5px;
    overflow: hidden;
    background: #e6edff;
    padding: 0.2vw;
    margin-right: 1vw;
  }
}
.newMessage {
  // background: #0045E6;
  display: flex;
  margin: 0vh 2vw;
  justify-content: flex-end;
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 48px;
    margin-bottom: 15px;
    width: 160px;
    background-color: #0045e6;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
  }
}

.bodyContainer {
  display: flex;
  flex-direction: row;
  // height: 100%;
  height: 80%;

  .leftContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    padding: 20px;
    background: #f1f5f9;
    border-radius: 5px;
    height: 95%;
    .buttons {
      display: flex;
      margin-top: 20px;
      justify-content: flex-start;
      width: 90%;
      background: #0045e6;

      border-radius: 5px;
    }
  }
  .rightContainer {
    display: flex;
    // align-items: center;
    flex-direction: column;
    // justify-content: center;
    height: 95%;
    width: 70%;
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
    padding: 15px;
    .noDataimage {
      height: 12.5vw;
      padding-bottom: 50px;
    }
  }
}
.activeMessage {
  height: 100%;
  .date {
    font-family: Poppins-Regular;
    font-size: 12px;
    color: #202428;
    letter-spacing: 0.48px;
    font-weight: 400;
    padding-bottom: 30px;
  }
  .profilePic {
    height: 6.4vh;
    aspect-ratio: 1;
    border-radius: 3.4vh;
    margin-right: 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .leftWrapper {
    display: flex;
    align-items: center;
    font-family: Poppins-SemiBold;
    font-size: 18px;
    color: #202428;
    letter-spacing: 0.72px;
    font-weight: 600;
  }
  .rightWrapper {
    font-family: Poppins-SemiBold;
    font-size: 18px;
    color: #202428;
    letter-spacing: 0.72px;
    font-weight: 600;
  }
  .message {
    height: 85%;
    padding: 15px;
    background: #f1f5f9;
    border-radius: 5px;
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #202428;
    letter-spacing: 0.56px;
    font-weight: 400;
  }
}

.noMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
  font-family: inherit;
  border: none;
  padding: 1vh 0.6vw;
  color: rgb(0, 0, 0);
  background-color: #ffffff !important;
  border-radius: 5px;
  width: 9vw;
  cursor: pointer;
}
.activeButton {
  background-color: #0045e6 !important;
  font-family: inherit;
  border: none;
  padding: 1vh 0.6vw;
  color: #fff;
  border-radius: 5px;
  // margin-right: 2vw;
  width: 100%;
  cursor: pointer;
}
.sendNewMessage {
  font-family: Poppins-Medium;
  font-size: 14px;
  color: #0045e6;
  letter-spacing: 0.56px;
  text-align: center;
  line-height: 21px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.messageContainer {
  width: 100%;
  overflow-y: auto;
  // padding: 0vh 1vw;
  .messageCard {
    display: flex;
    height: 100px;
    // background-color: red;
    margin-top: 8px;
    border-radius: 5px;
    cursor: pointer;
  }

  .messsageImage {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 6.94vh;
      aspect-ratio: 1;
      border-radius: 3.47vh;
    }
  }
  .messageInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .time {
      opacity: 0.5;
      font-family: Poppins-Regular;
      font-size: 10px;
      color: #202428;
      letter-spacing: 0.48px;
      font-weight: 400;
    }
  }
  .messageLabel {
    display: flex;
    justify-content: space-between;
    font-family: Poppins-Regular;
    font-size: 12px;
    color: #202428;
    letter-spacing: 0.48px;
    font-weight: 400;
    width: 100%;
    margin: 0 10px;
  }
  .roleLabel {
    font-family: Poppins-Medium;
    font-size: 0.6rem;
    color: #202428;
    letter-spacing: 0.56px;
    font-weight: 500;
    margin: 0 10px;
  }
}
.addNewMessage {
  width: 95%;
  height: 100%;
  .popupTextarea {
    width: 100%;
    height: 70%;
    background: #f1f5f9;
    border-radius: 5px;
    padding: 15px;
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
  .button {
    font-family: Poppins-SemiBold;
    border: none;
    padding: 1vh 0.6vw;
    color: #0045e6;
    background-color: #e6edff !important;
    border-radius: 5px;
    width: 9vw;
    cursor: pointer;
    margin-right: 40px;
  }
  .buttonPrimary {
    font-family: Poppins-SemiBold;
    border: none;
    padding: 1vh 0.6vw;
    color: #ffffff;
    background-color: #0045e6 !important;
    border-radius: 5px;
    width: 9vw;
    cursor: pointer;
  }
}

.error {
  height: 4vh;
  margin-bottom: 2vh;
  color: #e67717;
}
.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #b3bac2;
  text-align: center;
  position: fixed;
  bottom: 20 * $vw-const;
}
