@import "../../assets/sass/_variables.scss";

.container1 {
  // height: 70%;
  background-color: #002c94;
  border-radius: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  & .header {
    // padding: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    & .left,
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & .purpose {
        font-family: Arial-BoldMT;
        font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);

        color: #ffffff;
        letter-spacing: 0.48px;
        text-align: center;
        line-height: 1rem;
        font-weight: 700;
      }
      & .work {
        font-family: 'Arial-BoldMT';
        font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);
        color: #ffffff;
        letter-spacing: 0.48px;
        font-weight: 400;
      //  width: 19rem;
        text-align: left;
        margin-bottom: 10px;
      }
    }
  }
  & .card {
    border-radius: 2rem;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;
    width: 100%;
    flex-wrap: wrap;
    & .leftcol,
    .rightcol,
    .midcol {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}
.data {
  display: flex;
  padding: 0.5rem 0;
  & .name {
    font-family: 'Poppins-SemiBold';
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);

    color: #111111;
    letter-spacing: 0.4px;
    text-align: center;
    line-height: 3px;
    font-weight: 700;
    margin-right: 0.2rem;
  }
  & .value {
    font-family: 'Poppins-Regular';
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);

    color: #111111;
    letter-spacing: 0.4px;
    text-align: center;
    line-height: 3px;
    font-weight: 400;
  }
}
