@import '../../../../../../assets/sass/variables';

.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 80%;
  height: 80%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;

  p {
    font-size: 1rem;
    font-weight: 'bold';
    color: #fff;
  }
}
.filtersWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 4vh;
}
.selectWrapper {
  margin-right: 2vw;
}

.searchContainer {
  height: 100%;
  justify-content: center;
  width: 100%;
}

.searchInput {
  flex: 1;
  background: #e6edff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  padding: 1.5vh 1vw;
  margin-right: 1px;
}

.searchInput::placeholder {
  color: #002c9470;
}

.searchButton {
  display: flex;
  background: #e6edff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.4vh 1vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
  width: 1vw;
  aspect-ratio: 1;
}

.personCardsContainer {
  min-height: 20vh;

  margin-bottom: 4vh;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2vh;
  justify-content: flex-start;
  align-items: flex-start;
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins-Regular;
}
.tableMetadata {
  justify-content: space-between;
}

.tableWrapper {
  // height: 600px;
  width: 90%;
  margin: 0 auto;
  min-height: 20vh;
  max-height: 20vh;
  overflow: auto;
  margin-bottom: 10vh;
}

.image {
  height: 15px;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 4vw;
}

.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 2vh;
  button {
    min-width: 6vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
  }
}

.personDetails {

  padding: 1vh 2vw;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.imageTextContainer {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vh;
 
}

.personImage {
  width: 4vw;
  aspect-ratio: 1;
  border-radius: 5px;
  margin-right: 1vw;
}

.textArea {
  font-size: 0.8rem;

  p {
    color: #202428;
  }

  span {
    font-weight: 500;
  }
}
.personAlreadyAdded {
  text-align: center;
  font-size: 0.8rem;
  color: #e67717;
}

.cardButtonContainer {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4vh;
  justify-content: space-between;
  align-items: center;
}

.addMaterial {
  border: none;
  padding: 1vh 4vw;
  border-radius: 5px;
  background: #e6edff;
  color: #0045e6;
  cursor: pointer;
}

.radioGroup {
  justify-content: center;
  align-items: center;
  margin-bottom: 4vh;

  div {
    margin-right: 2vw;
  }
  input {
    margin-right: 0.4vw;
  }
  label {
    font-size: 0.8rem;
  }
}
.bottomContainer {
  flex: 1;
  min-height: 40vh;
  overflow: auto;
}

.searchCardContainer {
  width: 50vw;
  margin: 0 auto;
}
.personAlreadyAdded {
  text-align: center;
  font-size: 0.8rem;
  color: #e67717;
}

// overriding ag-theme classes
.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}
.ag-overlay-no-rows-wrapper {
  opacity: 0.5;
  font-family: 'Poppins-Medium';
  font-size: 16 * $vw-const;
  color: #202428 !important;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 500;
}
.dropdownContainer {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4vh;
  justify-content: center;
  align-items: center;
}

.containerClassNames {
  min-width: 20vw;
}
.notFound {
  text-align: center;
  font-size: 16 * $vw-const;
  color: #202428 !important;
}

.driverDetailsContainer {
  min-height: 20vh;
  margin-bottom: 40px;
}
