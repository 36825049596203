.button {
  border: none;
  background: #e6edff;
  color: #0045e6;
  border-radius: 5px;
  cursor: pointer;
  min-width: 120px;
  font-size: clamp(12px, 0.83333vw, 0.83333vw);
  padding: 0.52083vw;
  margin-right: 0.4vw;
}
