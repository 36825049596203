.btn {
  font-family: "Poppins-SemiBold";
  outline: none;
  box-shadow: none !important;
  // border-radius: 5px;
  &-outline-primary,
  &-outline-primary-200 {
    height: 48px;
    &:active,
    &:hover {
      color: $blue-100;
      background-color: $white;
    }
  }
  &-primary,
  &-primary-200 {
    height: 48px;
    color: $white !important;
  }
  &.f-240 {
    width: 240px;
  }
  &.f-292 {
    width: 292px;
  }
  &.base-btn {
    display: block;
    width: 100%;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;

    &-sm {
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 292px;
    }
  }
}
.back-link-wrapper {
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.back-link {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.270833px;
  color: $primary-text-color !important;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-right: 16px;
  }
}

.toggle-btn {
  width: 32px;
  height: 16.94px;
  position: relative;
  background: $gray-20;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 0;
  transition: 0.3s;
  &::before {
    content: "";
    transition: inherit;
    border-radius: 100%;
    position: absolute;
    width: 13px;
    height: 13px;
    background: $white;
    top: 2px;
    left: 2px;
  }
  &.active {
    background: $primary;
    &::before {
      left: calc(100% - 15px);
    }
  }
}
