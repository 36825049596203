@import '../../../../../../assets/sass/variables';
.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 90%;
  height: 80%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;
  position: relative;
  
  p {
    font-size: 1rem;
    font-weight: 'bold';
    color: #fff;
  }
}

.searchContainer {
  width: 50%;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 2vh;
  // background: red;
}

.searchInput {
  flex: 1;
  background: #e6edff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  padding: 1.5vh 1vw;
  margin-right: 1px;
}

.searchInput::placeholder {
  color: #002c9470;
}

.searchButton {
  display: flex;
  background: #e6edff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.4vh 1vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
  width: 1vw;
  aspect-ratio: 1;
  // flex: 1;
}

.personCardsContainer {
  min-height: 20vh;
  // background: green;
  margin-bottom: 4vh;
  width: 90%;
  margin: 0 auto;
  // margin-bottom: 1vh;
  justify-content: flex-start;
  align-items: flex-start;
}

.image {
  height: 15px;
  cursor: pointer;
}


.addRemoveButtonsContainer 
{
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2vh;
  margin-top: 30px;
  display: flex;
  button {
    min-width: 6vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
    transition: all 0.1s ease;
  }

  .finish:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .finish:active {
    transform: scale(0.95);
  }
}

.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 30px;
  button {
    min-width: 6vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
    transition: all 0.1s ease;
  }

  .finish:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .finish:active {
    transform: scale(0.95);
  }
}

.personDetails {
  // background: blue;
  padding: 1vh 2vw;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.imageTextContainer {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vh;
  // width: 100%;
  // background: red;
}

.personImage {
  width: 4vw;
  aspect-ratio: 1;
  border-radius: 5px;
  margin-right: 1vw;
}

.textArea {
  font-size: 0.8rem;

  p {
    color: #202428;
  }

  span {
    font-weight: 500;
  }
}
.personAlreadyAdded {
  text-align: center;
  font-size: 0.8rem;
  color: #e67717;
}

// .containerClassNames {
//   width: 20vw;
//   margin-right: 2vw;
//   height: 4vw;
//   background: #fff;
// }

.addMaterialHeader {
  color: #002c94;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 30* $vw-const;

}

.mainDropdownContainer {
  padding: 0vh 2vw;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 80* $vw-const;
}

.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 2vh;

  button {
    min-width: 8vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
    transition: all 0.1s ease;
  }

  .finish:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .finish:active {
    transform: scale(0.95);
  }
}
.closeIcon {
  width: 30* $vw-const;
  height: 30* $vw-const;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.formFieldsContainer {
  // min-height: 20%;
  padding: 0vh 2vw;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 80* $vw-const;;
}
// .containerClassNames {
//   width: 17vw;
//   margin-right: 2vw;
//   // height: 4vw;
//   height: 40px !important;

//   background: #fff;
// }

.containerClassNames {
  width: 344 * $vw-const;
  margin-right: 2vw;
  // height: 4vw;
  height: 45px !important;
  background-color: white;
  label {
    height: 45px !important;
  }
}

.tableWrapper {
  min-height: 20vh;
  width: 95%;
  overflow: auto;
  border: 1px solid gray;
  margin-right: 2vw;
  margin-bottom: 30px;
  margin-top: 20px;

  .ag-root-wrapper {
    border: none !important;
  }
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  border: none;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
}

.gridRowClass:hover {
  //  background: #eeeaea !important;
}
.fetchImportLink {
  font-size: 16 * $vw-const;
  color: #002c94;
  display: flex;
  align-items: initial;
  // width: 85%;
  // justify-content: end;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 2vw;
  align-items: center;
}
// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.checkBoxCounter {
  input {
    cursor: pointer;
  }
}

.editableInput, .ag-input-field-input {
  width: 100 * $vw-const;
    border: 1px solid grey;
    border-radius: 5 * $vw-const;
    font-size: 14 * $vw-const;
    padding: 2 * $vw-const;
}