@import '../../../src/assets/sass/variables';
// Resets
p {
  margin-bottom: 0;
}

.container {
  // background-color: green;
  // width: 100%;
  // height: 50px;
}

.input {
  display: flex;
  font-family: 'Poppins-regular';
  background-color: 'transparent';
  border: none;
  border-radius: 5px;
  flex: 1;
  padding: 0 0.5rem;
  height: 100%;
}
.label {
  font-family: 'Poppins-Regular';
  margin-bottom: 0.3rem;
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  align-items: center;
}

.labelContainer {
  // background-color: purple;
  height: 'auto';
}
.input::placeholder {
  color: #d3d3d4;
}

.error {
  color: #e67717;
  line-height: unset;
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
}

.leftIconStyles {
  height: 1.2vw;
  aspect-ratio: 1;
}
.rightIconStyles {
  height: 1.6vh;
  aspect-ratio: 1;
}
.imageContainer {
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;
  // padding-left: 0.6vw;
  // padding-right: 0.6vw;
  // height: 100%;
  border-right: 1px solid #b3bac2;
}
.rightImageContainer {
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;
  // padding-left: 0.6vw;
  // padding-right: 0.6vw;
  // height: 100%;
  // border-right: 1px solid #b3bac2;
}
.fileContainer {
  height: 100%;
  width: 110px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  text-decoration: underline;
  font-family: Poppins-Regular;
  font-size: 12px;
  color: #002c94;
  letter-spacing: 0.48px;
  font-weight: 400;
  cursor: pointer;
}

.inputBox {
  font-family: 'Poppins-regular';
  border: 1px solid #b3bac2;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  position: relative;
  height: inherit;
  height: 50px;

  // padding: 0.5rem;
}

.viewed {
  color: 'green';
  height: 1.2rem;
  width: 1.2rem;
  aspect-ratio: 1;
  margin-left: 0.2vw;
}
