@import '../../assets/sass/variables';
.containerClassNames {
  width: 70%;
  align-self: center;
}
.statusCardContainer {
  width: 620 * $vw-const;
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px 5px 0 0;
  flex-direction: column;
  margin: 0 auto;
  border: 1px solid gray;
  .heading {
    width: 100%;
    height: 30px;
    padding: 10 * $vw-const;
    background: #202428;
    font-size: 18 * $vw-const;
    color: #ffffff;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 500;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .cardDetails {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16 * $vw-const;
    color: #202428;
    letter-spacing: 0.48px;
    line-height: 18 * $vw-const;
    font-weight: 500;
    margin-top: 20 * $vw-const;
    margin-bottom: 20 * $vw-const;

    .icon {
      width: 80 * $vw-const;
      height: 80 * $vw-const;
      margin-right: 10 * $vw-const;
    }
  }
  .contentContainer {
    display: flex;
    line-height: 1.5;
    flex-direction: column;
  }
  .buttonContainer {
    flex: 1;
    display: flex;
    height: auto;
    padding: 10px 20px;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
    justify-content: space-around;

    .button {
      width: 120 * $vw-const;
      border-radius: 0.37vh;
      color: #ffffff;
      border: none;
      background-color: #e6edff;
      color: #0045e6;
      justify-content: end;
      justify-self: flex-end;
      font-size: 14 * $vw-const !important;
      margin-bottom: 20 * $vw-const;
      padding: 10 * $vw-const 20 * $vw-const;
      cursor: pointer;
      //  flex: 1
    }

    .buttonDownload {
      background: #0045e6 !important;
      width: 180 * $vw-const;
      color: #ffffff !important;
    }
  }
  .buttonPrimary {
    background-color: #e6edff !important;
    color: #0045e6 !important;
  }
  .buttonSecondary {
    background-color: #0045e6 !important;
  }
}

.blueText {
  font-size: clamp(10px, 18 * $vw-const, 18 * $vw-const);
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
  margin-bottom: clamp(10px, 30 * $vw-const, 30 * $vw-const);
}

.rejectedMessage {
  margin: 0 auto;
  width: 80%;
  font-size: 14 * $vw-const;
  margin-bottom: 20 * $vw-const;
  .message {
    border: 1px solid #f3f2f2;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    color: #333;
    margin-top: 3px;
  }
}
.bolder {
  font-size: 18 * $vw-const;
  color: #000;
  font-weight: bold;
}

.invoiceList {
  width: 54%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: clamp(10px, 30 * $vw-const, 30 * $vw-const);
  .invoiceHeading {
    font-size: clamp(10px, 20 * $vw-const, 20 * $vw-const);
    color: #002c94;
    letter-spacing: 0.72px;
    text-align: center;
    font-weight: 600;
    margin-top: clamp(10px, 30 * $vw-const, 30 * $vw-const);
    margin-bottom: clamp(10px, 30 * $vw-const, 30 * $vw-const);
    text-align: left;
  }
  .invoiceItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    font-size: clamp(10px, 18 * $vw-const, 18 * $vw-const);
    font-family: 'Poppins-Regular';
    color: #202428;
    letter-spacing: 0.64px;
    font-weight: 400;
    border-top: 1px solid #d2d2d2;
    height: clamp(50px, 100 * $vw-const, 100 * $vw-const);
  }
  .lastItem {
    border-bottom: 1px solid #d2d2d2;
  }
  .invoiceDownloadBtn {
    width: 120 * $vw-const;
    border-radius: 0.37vh;
    border: none;
    background-color: #e6edff;
    color: #0045e6 !important;
    justify-content: end;
    justify-self: flex-end;
    font-size: 16 * $vw-const;
    padding: 10 * $vw-const 20 * $vw-const;
    cursor: pointer;
    font-weight: bold;
  }
}

.surrenderStyles {
  width: 10vw !important;
  background-color: #e67717 !important;
  color: #fff !important;
}
.surrenderContainer {
  h1 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    color: black;
  }
}
.surrenderPopupContainer {
  min-width: 60vw;
  min-height: 60vh;
  padding: 0px !important;
  margin: 0px;
}

.surrenderPopupComponent {
  text-align: center;
  width: 100%;
  font-family: 'Poppins-Bold';
  color: #5c9a0a;

  .surrenderPopupComponentHeading {
    color: #e67717;
    font-family: 'Poppins-Bold';
    font-size: 1.2rem;
  }
  p {
    margin: 0.4rem 0rem;
    font-family: 'Poppins-Regular';
  }
}
