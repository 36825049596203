.gates {
    justify-content: center;
    margin-top: 2rem;
    
}

.gate {
    font-size: 0.8rem;
    padding: 2px 4px;
    margin-right: 0.4rem;
    background-color: #3b82f6;
    color: #1e40af;
    border-radius: 5px;
}