.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  z-index: 2;
  left: 0;
}
