@import '../../../../../assets/sass/variables';

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoContainer {
  padding-top: 4.07vh;
  text-align: center;
  display: block;
  margin-bottom: 3.7vh;
  width: 90%;
  img {
    margin-bottom: 0.93rem;
    width: 20vw;
    aspect-ratio: 1;
  }
  h2 {
    font-family: 'Poppins-Bold';
    font-size: 1.4rem;
    color: #0045e6;
    letter-spacing: 0.07rem;
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-family: 'Poppins-SemiBold';
    font-size: 1rem;
    color: #0045e6;
    letter-spacing: 0.96px;
    text-align: center;
    font-weight: 600;
  }
}

.customLabel {
  font-size: 0.8rem;
}

.formContainer {
  width: 80%;
  margin-bottom: 2.5rem;
  // p {
  //   font-family: "Poppins-SemiBold";
  //   font-size: 1rem;
  //   color: #002c94;
  //   letter-spacing: 0.06rem;
  //   font-weight: 600;
  // }
  input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.loginText {
  font-family: 'Poppins-SemiBold';
  font-size: 1.2rem;
  color: #002c94;
  letter-spacing: 0.06rem;
  font-weight: 600;
  margin-bottom: 4vh;
}

.loginButton {
  background: #0045e6;
  width: 85%;
  height: 5.84vh;
  border: 1px solid;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 5px auto;
  margin-bottom: 1.88rem;
}

.forgotPasswordRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Poppins-Regular';
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -1.85vh;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.677vw;
    }
  }
  a {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
}

.create_user {
  font-family: 'Poppins-Regular';
  font-size: 0.7rem;
  text-align: center;
  display: block;
  margin-bottom: 1.29vh;
  // width: 80%;
  span {
    font-family: 'Poppins-Regular';
    font-size: 0.7rem;
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 400;
    width: 11.617vw;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 3.14vh;
  .buttonPrimaryOutlined {
    background: #ffffff;
    width: 90%;
    height: 8vh;
    border: 1px solid;
    border-radius: 5px;
    color: $blue-100;
    font-family: 'Poppins-Bold';
    margin: 5px auto;
    margin-bottom: 1.88rem;
    font-size: 1rem;
  }
}

.submitButton {
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  height: 6vh;
}

.footerContainer {
  font-family: 'Poppins-Regular';
  font-size: 10px;
  color: #86909e;
  letter-spacing: 0.48px;
  font-weight: 400;
  margin-top: 50 * $vh-const;
  .copyright {
    margin-top: 10 * $vw-const;
  }
  .privacy,
  .terms,
  .help {
    cursor: pointer;
    margin-right: 10 * $vw-const;
  }
  .privacy:hover,
  .terms:hover,
  .help:hover {
    text-decoration: underline;
    color: #687e9c;
  }
}
.updatedDate {
  display: flex;
  width: 97%;
  justify-content: end;
  font-size: clamp(8px, 12 * $vw-const, 12 * $vw-const);
  color: #d3d3d4;
  letter-spacing: 0.48px;
  font-weight: 400;
}
.mobilePlayStoreIcons {
  margin-bottom: 10px;
  display: flex;
  .playStoreIcon {
    width: 20 * $vw-const;
    margin-right: 10 * $vw-const;
    margin-left: 10 * $vw-const;
  }
  .appStoreIcon {
    width: 20 * $vw-const;
    margin-left: 10 * $vw-const;
  }
}
