@import '../../assets/sass/variables';

.textInputWrapperRow {
  /* margin-bottom: 1.78vh; */
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
  flex: 1;
  //padding: 0rem 1rem;
}
.textInputWrapperColumn {
  position: relative;
  // margin-bottom: 1.78vh;
  flex-direction: column;
  align-items: center;
  // background: purple;
}
.inputTextLabel {
  margin-right: 1.78vh;
  // min-width: 6.35vw;
  /* color: #707070; */
  font-size: 14px;
  font-family: 'Poppins-Regular';
  font-size: 0.88rem;
  color: #202428;
  letter-spacing: 0.03rem;
  font-weight: 400;
}
.inputTextLabelColumn {
  // margin-bottom: 0.89vh;
}
.inputText {
  background: #ffffff;
  border: 1px solid #b3bac2!important;
  border-radius: 5px;
  padding: 0.45vh 1.78vh;
  flex: 1;
  height: 3rem;
  outline: none;
  //width: 16.25vw;
  // margin-right: 3.56vh;
  font-size: 0.88rem;
  width: 21.5rem;
}
.rightIcon {
  height: 15px;
  top: 38px;
  right: 10px;
  position: absolute;
}
.error {
  border: 1px solid red;
}
.inputText::placeholder {
  font-family: 'Poppins-Regular';
  font-size: 0.88rem;
  color: #d3d3d4;
  letter-spacing: 0.03rem;
  font-weight: 400;
}

.inputTextFile {
  background: #ffffff;
  border: 1px solid #b3bac2;
  border-radius: 5px;
  padding: 0.45vh 1.78vh;
  flex: 1;
  height: 3rem;
  outline: none;
  // margin-right: 3.56vh;
  font-size: 0.88rem;
  width: 21.5rem;
}

input[type='file'] {
  display: none;
}

// input[type="date"] {
//   //display: none;
// }

.customInput {
  position: relative;
  background-color: #ffffff;
  height: 3rem;
  border-radius: 5px;
  border: 1px solid #b3bac2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 21.5rem;
  .placeholder {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: #d3d3d4;
    letter-spacing: 0.56px;
    font-weight: 400;
    padding-left: 1rem;
  }

  .fileNames {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    letter-spacing: 0.56px;
    font-weight: 400;
    padding-left: 1rem;
    overflow-x: auto;
  }

  img {
    margin-left: auto;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
}

// input[type="date"] {
//   background-color: #ffffff;
//   outline: none;
//   background: #fff url("../../assets//images/Icon/Calender.png") 97% 50% no-repeat;
// }

// input[type="date"]::-webkit-inner-spin-button {
//   display: none;
// }
// input[type="date"]::-webkit-calendar-picker-indicator {
//   opacity: 0;
// }

//new
.dashboardIcon {
  height: 20px;
  top: 10px;
  right: 10px;
  position: absolute;
}
input::-webkit-datetime-edit {
  font-family: 'Poppins-Regular';
  font-size: 14px;
  color: #d3d3d4;
  letter-spacing: 0.56px;
  font-weight: 400;
}

input::-webkit-datetime-edit-fields-wrapper {
  -webkit-user-modify: read-only !important;
  display: inline-block;
  padding: 1px 0;
  white-space: pre;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

// FIXME:
@media screen and (max-width: 760px) {
  .inputText,
  .customInput,
  .inputTextFile {
    width: 90%;
  }
}
