.containerClassNames {
  height: 40px !important;
  margin-bottom: 1vh !important;
}

.labelClassNames {
  font-size: 0.8rem;
}

.shiftRows {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.timeContainerClassNames {
  height: 40px !important;
  margin-bottom: 1vh !important;
  min-width: 16vw;
}
