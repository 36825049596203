@import '../../../../../assets/sass/variables';

.loginContainer {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 2vh;
}

.logoContainer {
  padding-top: 4.07vh;
  text-align: center;
  display: block;
  margin-bottom: 3.7vh;
  // width: 19.167vw;
  width: 24vw;
  img {
    margin-bottom: 1.38vh;
  }

  h2 {
    font-family: 'Poppins-Bold';
    font-size: 1.8rem;
    margin-bottom: 1vh;
    color: #0045e6;
    letter-spacing: 0.07rem;
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-family: 'Poppins-SemiBold';
    font-size: 1.5rem;
    color: #0045e6;
    letter-spacing: 0.96px;
    text-align: center;
    font-weight: 600;
  }
}

.formContainer {
  width: 24vw;
  margin-bottom: 3.7vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // p {
  //   font-family: "Poppins-SemiBold";
  //   font-size: 1.5rem;
  //   color: #002c94;
  //   letter-spacing: 0.06rem;
  //   font-weight: 600;
  // }
  input[type='checkbox'] {
    width: 1.042vw;
    height: 1.85vh;
  }
}
.loginText {
  font-family: 'Poppins-SemiBold';
  font-size: 1.4rem;
  color: #002c94;
  letter-spacing: 0.06rem;
  font-weight: 600;
  margin-bottom: 2vh;
}
.customLabel {
  font-size: 0.8rem;
}

.loginButton {
  background: #0045e6;
  width: 17.917vw;
  height: 4.44vh;
  border: 1px solid;
  border-radius: 0.46vh;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 0.46vh auto;
  margin-bottom: 4.44vh;
}

.forgotPasswordRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Poppins-Regular';
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -1.85vh;
  margin-bottom: 2vh;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.677vw;
    }
  }
  a {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
}

.create_user {
  text-align: center;
  display: block;
  margin-bottom: 1.29vh;
  span {
    font-family: 'Poppins-Regular';
    font-size: 0.88rem;
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 400;
    width: 11.617vw;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 3.14vh;
  .buttonPrimaryOutlined {
    background: #ffffff;
    width: 17.917vw;
    height: 4.44vh;
    border: 1px solid;
    border-radius: 0.46vh;
    color: $blue-100;
    font-family: 'Poppins-Medium';
    margin: 0.46vh auto;
    margin-bottom: 2vh;
    font-size: 1rem;
  }
}

.logoText {
  font-size: 0.8rem;
}

.inputContainer {
  margin-bottom: 20px;
}

.divider {
  margin-bottom: 2vh;
}
// .buttonContainer {
//   background-color: blue;
//   width: 100%;
// }
.submitButton {
  width: 100%;
  height: 4vh;
  // padding-top: 1.8vh;
  // padding-bottom: 1.8vh;
}

.otpContainer {
  margin-bottom: 20px;
  label {
    font-family: 'Poppins-Regular';
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    height: auto;
  }
}

.backlabel {
  font-family: 'Poppins-Regular';
  margin-top: 2.77vh;
  cursor: pointer;
  font-size: 0.875rem;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
  width: 2.083vw;
  display: flex;
  padding-left: 1.833vw;
  align-items: center;
  align-self: flex-start;
  img {
    height: 2.22vh;
    aspect-ratio: 1;
    margin-right: 1.11vh;
  }
}
