@import "../../assets/sass/variables";

.card {
  width: 100%;
  & .wrapper {
    border: 2px solid #002c94;
    border-top: none;
    border-radius: 0 0 2rem 2rem;
  }

  & .header {
    width: 100%;
    background-color: #002c94;
    display: flex;
    justify-content: space-around;
    padding: 1rem 0rem;
    border-radius: 1rem 1rem 0 0;
    flex-wrap: wrap;
    padding: 0px 75 * $vw-const;
  }
  & .content {
    display: flex;
    width: 100%;
    background: rgba(230, 237, 255, 0.6);
    // border: 2px solid #002c94;
    // border-top: none;
    // border-bottom: none;

    & .vehicleDetails {
      width: 50%;
      border-right: 1px solid #979797;
      padding: 0 0 2rem 3rem;
    }
    & .materialDetails {
      width: 50%;
      padding: 0 0 2rem 3rem;
    }
  }
  & .bottom {
    display: flex;
    justify-content: space-around;
    // border-top: 2px solid #002c94;
  }
}

.data {
  display: flex;
  flex-wrap: wrap;
  & .key {
    font-family: Poppins-SemiBold;
    font-size: 16 * $vw-const;
    color: #ffffff;
    letter-spacing: 0.3px;
    text-align: center;
    line-height: 33px;
    font-weight: 600;
  }
  & .value {
    font-family: Poppins-Regular;
    font-size: 16 * $vw-const;
    color: #ffffff;
    letter-spacing: 0.3px;
    text-align: center;
    line-height: 33px;
    font-weight: 400;
    padding-left: 0.3rem;
  }
}
.rowhead {
  font-family: Poppins-SemiBold;
  font-size: 18 * $vw-const;
  color: #002c94;
  letter-spacing: 0.8px;
  padding: 1rem 0;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: #002c94;
}

.vehicleDetails,
.materialDetails {
  & .data {
    & .key {
      font-family: Poppins-SemiBold;
      font-size: 18 * $vw-const;
      color: #000000;
      letter-spacing: 0.3px;
      text-align: center;
      line-height: 30px;
      font-weight: 600;
    }
    & .value {
      font-family: Poppins-Regular;
      font-size: 16 * $vw-const;
      color: #000000;
      letter-spacing: 0.3px;
      text-align: center;
      line-height: 30px;
      font-weight: 400;
    }
  }
}
.bottom {
  border-top: 2px solid #002c94;
  border-radius: 1rem;
  & .data {
    display: block;
    padding: 1rem 3rem;
    & .key {
      font-family: Poppins-SemiBold;
      font-size: 18 * $vw-const;
      color: #002c94;
      letter-spacing: 0.3px;
      text-align: center;
      font-weight: 600;

      text-decoration: underline;
      text-decoration-color: #002c94;
    }
    & .value {
      // font-family: Poppins-Regular;
      font-size: 16 * $vw-const;
      color: #000000;
      letter-spacing: 0.3px;
      text-align: center;
      font-weight: 400;
    }
  }
}
.bottom {
  & .img {
    display: flex;
    align-items: center;
    position: relative;
    bottom: 2rem;
    & img {
      width: 1.4vw;
      height: 2.7vh;
      margin-right: 0.6rem;
    }
  }
}
