@import '../../../../assets/sass/variables';
.container {
  width: 100vw;
  height: 100vh;
}

.header {
  height: 18rem;
  width: 100%;
  background-image: url('../../../../assets/images/backdrop-blue/image.png');
  background-position: center;
  background-repeat: repeat;
}

.logo {
  width: 5rem;
  aspect-ratio: 1;
  object-fit: contain;
}

.homeButton {
  background: none;
  border: none;
  img {
    width: 2rem;
    aspect-ratio: 1;
    object-fit: contain;
  }
}

.topBar {
  padding: 1rem 2rem 0rem 2rem;
}

.textHeading {
  .resources {
    font-family: Poppins-Bold;
    font-size: 1.4rem;
    color: #ffffff;
    letter-spacing: 1.27px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .para {
    font-family: Poppins-Regular;
    font-size: 1.2rem;
    color: #ffffff;
    letter-spacing: 1.27px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}

.searchContainer {
  width: 70%;
  height: 3rem;
  margin: 0 auto;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  padding: 0 1rem;
  margin-bottom: 0.4rem;

  .searchButton {
    width: 1rem;
    margin-right: 1rem;
  }
  .searchInput {
    width: 100%;
    border: none;
    background: none;
  }
  .searchInput:focus {
    outline: none;
    color: #fff;
  }

  .searchInput::placeholder {
    color: #fff;
  }
}

.example {
  width: 70%;
  margin: 0 auto;
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  color: #fff;
  font-style: italic;
}

.content {
  max-width: 70vw;
  margin: 0 auto;
  padding: 2rem 0;
}
.linkTab {
  font-size: 0.8rem;
  gap: 4px;

  span {
    color: #6d6c6c;
    font-size: 0.6rem;
  }
  .homeLink {
    // letter-spacing: 1.27px;
    color: #6d6c6c;
    text-align: center;
    line-height: 25px;
    font-weight: 400;
    text-decoration: none;
    font-style: italic;
  }
  .resourceLink {
    color: #6d6c6c;
    // letter-spacing: 1.27px;
    text-align: center;
    line-height: 25px;
    font-weight: 400;
    font-family: Poppins-Bold;
    text-decoration: none;
  }
}

.contentFAQHeading {
  font-family: Poppins-Bold;
  margin: 1rem 0rem;
}

.faqContainer {
  // padding: 1rem;
  border: 1px solid #cfcfcf;
  background-color: rgba(216, 216, 216, 0);
  border-radius: 5px;
  height: 40rem;
  overflow-y: auto;
  margin-bottom: 2rem;

  .sidebar {
    width: 30%;
    background-color: rgba(230, 237, 255, 0.25);
    padding: 1rem;
    height: 100%;
    border-right: 1px solid #cfcfcf;
  }
  .faqContent {
    width: 70%;
    padding: 1rem 1.4rem;
    height: 100%;
    overflow-y: auto;
  }
}

.sidebarCard {
  padding: 0.4rem;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #979797;
  margin-bottom: 1rem;
  border-radius: 5px;
  gap: 10px;
  background: white;
  width: 100%;
  cursor: pointer;

  .iconContainer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  .sidebarIcon {
    width: 1rem;
    flex-shrink: 0;
  }
  p {
    width: 70%;
    flex-shrink: 1;
  }
}

.faqContentHeader {
  font-size: 1.6rem;
  font-family: Poppins-Bold;
  color: #2e3339;
}

.contentHeading {
  font-size: 1.1rem;
  color: #202428;
  margin: 0.8rem 0;
}

.answerText {
  color: #5d6774;
  font-size: 0.8rem;
  font-family: Poppins-Light;
  margin: 1rem 0;
}
.bulletPointsUL {
  padding: 0 2rem;

  li {
    margin: 0.4rem 0;
    color: #5d6774;
    font-size: 0.8rem;
    font-family: Poppins-Light;
  }
}

.link {
  color: $blue-100;
  font-size: 0.8rem;
  font-family: Poppins-Light;
  margin: 1rem 0;
}

.guides {
  margin-bottom: 4rem;
}

.guideCard {
  height: 10rem;
  border-radius: 5px;
  width: 48%;
  border: 1px solid #cfcfcf;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .guidesIcon {
    height: 10rem;
    margin-right: 2rem;
  }

  p {
    font-family: Poppins-Bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.2rem;
  }
}

.playButton {
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(216, 216, 216, 0.2);
  cursor: pointer;
  border: none;

  img {
    width: 1rem;
    aspect-ratio: 1;
  }
}

.bottomText {
  h2 {
    text-align: center;
    margin-bottom: 0.4rem;
    color: #2e3339;
  }
  p {
    max-width: 60%;
    margin: 0 auto;
    font-family: Poppins-Regular;
    font-size: 0.8rem;
    color: #5d6774;
    text-align: center;
    font-weight: 400;
    margin-bottom: 2rem;
  }
}

.contact {
  margin-bottom: 4rem;
}

.contactCard {
  height: 10rem;
  border-radius: 5px;
  width: 48%;
  border: 1px solid #cfcfcf;
  overflow: hidden;
  position: relative;

  .imageIllustrationContainer {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 20%;
    margin-right: 6rem;
  }
  .contactIcon {
    width: 2rem;
    position: relative;
    z-index: 4;
    margin-bottom: 4rem;
  }
  .filler {
    position: absolute;
    left: -4rem;
    top: -6rem;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: #f8f9fc;
    z-index: 1;
  }
  .fillerSecond {
    position: absolute;
    left: -5rem;
    top: -6rem;
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    background-color: #002c9430;
    z-index: 0;
  }
}

.contactContent {
  div {
    margin-bottom: 0.4rem;
  }
  h2 {
    font-family: Poppins-Light;
    color: #002c94;
    font-size: 0.8rem;
    max-width: 10rem;
  }
  h4 {
    font-family: Poppins-Bold;
    color: #002c94;
    font-size: 1.2rem;
  }
}
