.popupContainer {
  background: white;
  border-radius: 5px;
  width: 50%;
  height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  background: #0045e6;
  color: #fff;
  text-align: center;
  padding: 1vh 0vw;
  margin-bottom: 2vh;
}

.description {
  font-size: 1rem;
}

.button {
  background: #0045e6;
  padding: 1vh 2vw;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-bottom: 2vh;
}
