@import '../../../../../assets/sass/variables';

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoContainer {
  padding-top: 4.07vh;
  text-align: center;
  display: block;
  margin-bottom: 3.7vh;
  //width: 19.742vw;
  img {
    margin-bottom: 0.93rem;
  }
  h2 {
    font-family: 'Poppins-Bold';
    font-size: 2rem;
    color: #0045e6;
    letter-spacing: 0.07rem;
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-family: 'Poppins-SemiBold';
    font-size: 1.5rem;
    color: #0045e6;
    letter-spacing: 0.96px;
    text-align: center;
    font-weight: 600;
  }
}

.passwordInput {
  width: '400px';
  height: '50px';
}

.formContainer {
  .h2 {
    font-family: 'Poppins-SemiBold';
    font-size: 1.5rem;
    color: #002c94;
    letter-spacing: 0.06rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  //width: 17.917vw;
  margin-bottom: 3.7vh;
  input[type='checkbox'] {
    width: 1.042vw;
    height: 1.85vh;
  }
}

.loginButton {
  background: #0045e6;
  width: 21.5rem;
  height: 3rem;
  border: 1px solid;
  border-radius: 0.46vh;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 0.46vh auto;
  margin-bottom: 2.77vh;
}

.forgotPasswordRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins-Regular';
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -20px;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.8rem;
    }
  }
  a {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
}

.create_user {
  text-align: center;
  display: block;
  margin-bottom: 1.29vh;
  span {
    font-family: 'Poppins-Regular';
    font-size: 0.88rem;
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.85vh;
    font-weight: 400;
    width: 11.617vw;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 1.775vw;
  .buttonPrimaryOutlined {
    background: #ffffff;
    width: 17.917vw;
    height: 4.44vh;
    border: 1px solid;
    border-radius: 0.46vh;
    color: $blue-100;
    font-family: 'Poppins-Bold';
    margin: 0.46vh auto;
    margin-bottom: 2.77vh;
    font-size: 1rem;
  }
}
