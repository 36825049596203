@import '../../../../../../assets/sass/variables';
.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 80%;
  height: 100%;
  background: #f8f8f8;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
  position: relative;
  border: 10px solid #002892;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 100%;
  }
}

.subcontainer {
  // background-color: #d5d5d5;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  & .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    & .left {
      //   display: flex;
      & .sanna {
        display: flex;
        align-items: center;
        & img {
          height: 0.8rem;
          width: clamp(35px, 80 * $vw-const, 80 * $vw-const);
        }
        & p {
          padding: 0.4rem 0.1rem 0 0;
          color: #d9d7d7;
          font-size: clamp(4px, 14 * $vw-const, 14 * $vw-const);
          font-weight: 600;
        }
      }
    }
    & h1 {
      font-family: 'Poppins-Bold';
      font-size: clamp(12px, 20 * $vw-const, 20 * $vw-const);
      color: #002c94;
      letter-spacing: 0.8px;
      text-align: center;
      font-weight: 700;
      // text-decoration: underline;
      text-decoration-color: #002c94;
      margin-left: 5px;
      margin-right: 5px;
    }
    & img {
      width: 4rem;
    }
  }
  & p {
    //  font-family: 'Poppins-Bold';
    font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);
    color: #111111;
    letter-spacing: 0.56px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0.2rem;
  }
  & .body {
    height: 70%;
    width: 100%;
    margin-bottom: 4rem;
    position: relative;
    & .container2 {
      width: 100%;
      margin-top: 4rem;
      background: rgba(0, 44, 148, 0.11);
      border-radius: 1rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem 2rem 1rem;
      & .main {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 2rem;

        & .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  & .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.data {
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
}

.left {
  & .approvalDpt {
    font-family: 'Poppins-Regular';
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    color: #111111;
    letter-spacing: 0.72px;
    line-height: 8px;
    font-weight: 400;
    padding-bottom: 1rem;
  }
  & .department {
    font-family: 'Poppins-Bold';
    font-size: clamp(14px, 20 * $vw-const, 20 * $vw-const);
    color: #111111;
    letter-spacing: 0.72px;
    line-height: 8px;
    font-weight: 700;
  }
}

@media (max-width: 786px) {
  .left {
    & .approvalDpt {
      line-height: 20px;
    }
    & .department {
      line-height: 20px;
    }
  }
}

.scanner {
  display: flex;
  justify-content: center;
  border: 5px solid #002c9430;
  padding: 2px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-right: 0.4rem;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  & .QR {
    font-family: 'Poppins-Bold';
    font-size: 0.8rem;
    color: #111111;
    letter-spacing: 0.4px;
    text-align: center;
    line-height: 11px;
    font-weight: 700;
    padding-top: 0.4rem;
  }
  & .viewpermit {
    font-family: 'Poppins-Regular';
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    color: #111111;
    letter-spacing: 0.4px;
    text-align: center;
    line-height: 9px;
    font-weight: 400;
  }
}

.info {
  font-family: 'Poppins-Bold';
  font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
  color: #111111;
  letter-spacing: 0.32px;
  text-align: center;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #111111;
}

// .body ::before {
//   content: '';
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%) rotate(300deg);

//   font-family: Arial-BoldMT;
//   font-size: 6rem;
//   color: #1111111a;
//   letter-spacing: 3.98px;
//   text-align: center;
//   font-weight: 700;
// }

.closeBtn {
  position: absolute;
  right: 1vw;
  top: 1vh;
  background: transparent;
  color: black;
  border: none;
}

// New CSS
.splitCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  .splitCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.2rem;

    h2 {
      font-size: 0.8rem;
      font-weight: 500;
      font-family: 'Poppins-Light';
      color: #002892;
    }
  }

  .splitCardBody {
    border-radius: 15px;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 500;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .splitCardLeftContainer {
    width: 75%;
    background-color: #002892;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: 100%;
  }
  .labelValueContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    width: 100%;
  }
  .label {
    font-family: 'Poppins-bold';
    display: inline-block;
    min-width: 8rem;
  }
  .value {
    font-family: 'Poppins-Light';
    margin-left: 5px;
  }

  .splitCardRightContainer {
    width: 25%;
    background-color: #01237b;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    height: 100%;
  }
  .QRContainer {
    width: 25%;
    background-color: #f5f5f5;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: 100% !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

.mobilePermitType {
  display: none !important;
}

// Media query for mobile devices
@media (max-width: 350px) {
  .splitCardLeftContainer {
    width: 100% !important;
    font-size: 0.5rem !important;
  }
  .splitCardRightContainer {
    width: 0% !important;
    display: none !important;
  }
  .labelValueContainer {
    flex-direction: column !important;
    margin-bottom: 0.4rem !important;
  }
  .dateContainerText {
    font-size: 0.4rem !important;
  }
  .dateIcon {
    display: none;
  }
  .QRContainer {
    display: none !important;
  }
  .mobilePermitType {
    display: flex !important;
  }
}
