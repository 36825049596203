@import '../../assets/sass/variables';
.container {
  height: 450 * $vw-const;
  width: 550 * $vw-const;
  background-color: white;
  border-radius: 5px;
  align-items: center;
  padding-top: 30 * $vw-const;
  & img {
    width: 180 * $vw-const;
  }
  & P {
    margin: 30 * $vw-const 0;
  }
}
.button {
  width: 100%;
  justify-content: space-around;
  padding-right: 30 * $vw-const;
}
.okayBtn,
.cancelBtn {
  background: #0045e6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: $white;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin: 2.4rem 0 1rem 2rem;
  width: 6rem;
  cursor: pointer;
  cursor: pointer;
  text-decoration: none;
}
.cancelBtn {
  background: #e6edff;
  color: #0045e6;
  width: 6rem;
}

.message {
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}
