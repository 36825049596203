.whyUsContainer {
  //   height: 60vh;
  background-color: #001c35;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0rem;
}

.header {
  color: #ec8940;
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  padding-bottom: 5rem;
}

.subHeader {
  font-size: 1rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  max-width: 40rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  text-align: center;
  color: #bfbfbf;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 0 5rem 4rem 5rem;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
  img {
    margin-bottom: 1rem;
  }

  p {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
    color: #bfbfbf;
    max-width: 8rem;
    margin: 0 auto;
  }
}

.divider {
  border-right: 1px dashed #9e9e9e40;
}
