@import '../../../../../assets/sass/variables';
.container {
  display: flex;
  flex: 1;
  // height: 100vh;
}
.rightContainer {
  width: 45%;
  display: flex;
  padding: 0 30 * $vw-const;
}
.backlabel {
  margin-top: 30 * $vw-const;
  cursor: pointer;
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.registrationlabel {
  margin-top: 65 * $vw-const;
  margin-bottom: 50 * $vw-const;
  justify-content: center;
  font-size: 24 * $vw-const;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins-Bold';
}
.backButtonIcon {
  height: 24px;
  aspect-ratio: 1;
  margin-right: 10 * $vw-const;
}
.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.filterDropdown {
  height: 4.26vh;
  outline: none;
  width: 16.41vw;
  border: 1px solid #e1e1e1;
  border-radius: 0.45vh;
  font-size: 14 * $vw-const;
  margin-bottom: 55 * $vw-const;
}
.dropdown {
  width: 16.25vw;
  margin-bottom: 30 * $vw-const;
  font-family: 'Poppins-Regular';
  font-size: 0.88rem;
  color: #202428;
  letter-spacing: 0.03rem;
  font-weight: 400;
  //padding: 0rem 1rem;
  div {
    min-height: 2.4rem;
  }
}
.menu {
  height: 50 * $vw-const;
}

.nextButtonStyle {
  text-align: center;
  background: #e6edff;
  width: 21.5rem;
  height: 3rem;
  margin: 3.7vh 0;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: #0045e6;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.dropdownContainerStyle {
  //  width: 400 * $vw-const;
}

.signatoryContainer {
  width: 90%;
  .topHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      font-family: 'Poppins-SemiBold';
      font-size: 1.12rem;
      color: #002c94;
      letter-spacing: 0.04rem;
      text-align: right;
      font-weight: 700;
    }
    margin-bottom: 1rem;
  }
  .header {
    display: flex;
    margin-bottom: 3.5px;
    span {
      font-family: 'Poppins-SemiBold';
      font-size: 1.12rem;
      color: #002c94;
      letter-spacing: 0.04rem;
      text-align: right;
      font-weight: 600;
    }
  }
  .signatoryOrgFirlds {
    display: flex;
    flex-direction: row;
  }
  .signatorySection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1vw;
    // flex-wrap: wrap;
  }
}

.gridOfTwo {
  display: grid;
  width: 90%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1vw;
  > div {
    width: 20vw;
  }
  // FIXME: shift to other class
}

.divider {
  margin-bottom: 1rem;
}
.formFields {
  font-size: 0.9rem;
  margin-bottom: 1.4rem !important;
  width: 20vw;
}
.page1Container {
  width: 100%;
  // background: red;
  align-items: center;
}
.topHeader {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  span {
    font-family: 'Poppins-SemiBold';
    font-size: 1.12rem;
    color: #002c94;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 700;
  }
}

@media screen and (max-width: 1080px) and( min-width:767px) {
  .gridOfTwo {
    grid-column-gap: 2vw;
    > div {
      width: 40vw;
    }
  }
  .signatorySection {
    width: 2vw;
  }
  .formFields {
    width: 40vw;
  }
}
