.data-editor {
  height: 100% !important;
  width: 100% !important;
  /* display: inline-block; */
}
.button {
  border: none;
  background: #e6edff;
  color: #0045e6;
  border-radius: 5px;
  cursor: pointer;
  min-width: 120px;
  font-size: clamp(12px, 0.83333vw, 0.83333vw);
  padding: 0.52083vw;
  margin-right: 0.4vw;
}

.submit {
  border: none;
  background: #0045e6;
  color: #e6edff;
  border-radius: 5px;
  cursor: pointer;
  min-width: 120px;
  font-size: clamp(12px, 0.83333vw, 0.83333vw);
  padding: 0.52083vw;
}
.table-classes {
  margin: 0 auto;
  font-size: 0.8rem;
  background: #fff;
}
.value-viewer {
}
table.data-grid tr td.cell {
  /* overflow: hidden; */
}
