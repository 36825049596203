@import '../../../../../../assets/sass/variables';

.container {
  height: 100%;
  width: 100%;
  //   background-color: #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 3rem;
  & .header {
    font-family: Poppins-SemiBold;
    font-size: 18 * $vw-const;
    flex-wrap: wrap;
    color: #ffffff;
    letter-spacing: 0.96px;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #002c94;
    padding: 0.5rem 0;
    left: 0;
    top: 0;
  }
  & .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20* $vw-const;
    // margin: 2rem auto;
    & .permitcard {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
      & .permit {
        display: flex;
        align-items: center;
        margin-bottom: 0.3rem;
        & p {
          font-family: Poppins-Bold;
          font-size: 16 * $vw-const;
          color: #002c94;
          letter-spacing: 0.88px;
          text-align: center;
          font-weight: 700;
        }
        & img {
          width: 1.2vw;
          height: 2.5vh;
          margin-right: 0.6rem;
        }
      }
    }
  }
  & .footer {
    width: 20%;
    display: flex;
    justify-content: space-around;
    // font-family: Poppins-SemiBold;
    margin: 2rem auto;
    font-size: 16 * $vw-const;
    & .cancel {
      background-color: #e6edff;
      color: #0045e6;
      letter-spacing: 0.64px;
      text-align: center;
      padding: 0.25rem 1.5rem;
      border: none;
    }
    & .confirm {
      background-color: #0045e6;
      color: #ffffff;
      letter-spacing: 0.4px;
      text-align: center;
      padding: 0.25rem 1.5rem;
      border: none;
    }
  }
}
