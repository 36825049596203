@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap');
@import '../../assets/sass/fonts.scss';
.container {
  display: flex;
  flex: 1;
  //height: 100vh;
  margin-bottom: 2.96vh;
}
.rightContainer {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  align-items: flex-start;
}
.backlabel {
  font-family: 'Poppins-Regular';
  margin-top: 2.77vh;
  cursor: pointer;
  font-size: 0.875rem;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
  width: 2.083vw;
  display: flex;
  padding-left: 1.833vw;
  align-items: center;
  align-self: flex-start;
  img {
    height: 2.22vh;
    aspect-ratio: 1;
    margin-right: 1.11vh;
  }
}

.registrationlabel {
  margin-top: 6.01vh;
  margin-bottom: 4.629vh;
  justify-content: center;
  font-size: 1.5rem;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins-Bold';
}
.backButtonIcon {
  // height: 24px;
  // aspect-ratio: 1;
  // margin-right: 10px;
}
.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .topHeader {
    width: 90%;
    display: flex;
    justify-content: space-between;
    span {
      font-family: 'Poppins-SemiBold';
      font-size: 1.12rem;
      color: #002c94;
      letter-spacing: 0.04rem;
      text-align: right;
      font-weight: 700;
    }
    margin-bottom: 1rem;
  }
}
.tcLabel {
  color: #0045e6;
  cursor: pointer;
  margin-left: 0.26vw;
  font-size: 0.75rem;
  letter-spacing: 0.48px;
  line-height: 1.66vh;
  text-decoration: underline;
}
.acceptLabel {
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.48px;
  line-height: 1.66vh;
  font-weight: 400;
}
.form {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.checkBoxIcon {
  height: 1.85vh;
  width: 1.64vw;
  // margin-right: 0.260vw;
  cursor: pointer;
}
.tcWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4.62vh;
  margin-left: 2.646vw;
}

.submitButton:disabled,
.submitButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.submitButton {
  background: #0045e6;
  // width: 21.17.917vw;
  width: 40%;
  height: 6vh;
  border: 1px solid;
  border-radius: 0.46vh;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 0.46vh auto;
}
