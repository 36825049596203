@import '../../assets/sass/variables';
.container {
  width: 90%;
  position: relative;
  background-color: white;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  padding-bottom: 30 * $vw-const;
  min-height: 400px;
}
.secondContainer {
  overflow: auto;
  max-height: 90vh;
}
.bodyContainer {
  padding: 0 30 * $vw-const 0 30 * $vw-const;
  flex-direction: column;
}

.requestInfo * {
  font-size: 14 * $vw-const;
}

.closeIcon {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
  font-size: 16 * $vw-const;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
}
.buttonContainer {
  flex: 1;
  display: flex;
  height: auto;
  padding: 0.89vh;
  flex-direction: row;
  justify-content: center;
  /* background-color: #F4F4F4; */
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 60 * $vw-const;
  font-size: 24 * $vw-const;
  font-weight: bold;
  margin-bottom: 30 * $vw-const;
  background: #002c94;
  border-radius: 5px 5px 0 0;
}

.button {
  height: 48 * $vh-const;
  width: 160 * $vh-const;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #5a69ed 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  height: 48 * $vh-const !important;
  font-size: 16 * $vh-const !important;

  margin-right: 10px;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24 * $vw-const;
  font-size: 14 * $vw-const;
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 400;
}
.requestLabel {
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 500;
}
.infoContainer {
  min-height: 400px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}
