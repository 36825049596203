// .container {
//   font-family: "Poppins-Regular";
//   background: #fff;
//   height: 90vh;
//   width: 70vw;
//   border-radius: 5px;
//   overflow: hidden;
// }

// .header {
//   background: #002c94;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 2vh;
//   position: relative;

//   h1 {
//     font-size: 1.4rem;
//     color: #fff;
//     text-align: center;
//   }
// }

// .buttonsContainer {
//   justify-content: center;

//   button {
//     font-family: inherit;
//     border: none;
//     padding: 1vh 2vw;
//     color: #0045e6;
//     border-radius: 5px;
//     margin-right: 2vw;
//     width: 8vw;
//   }

//   .okay {
//     background: #e6edff;
//   }
// }

// .childrenContainer {
//   flex: 1;
//   height: 80%;
//   justify-content: center;
//   align-items: center;
//   font-size: 0.8rem;

//   .subheading {
//     color: #002c94;
//     font-weight: bold;
//     margin-bottom: 2vh;
//   }
//   p {
//     text-align: center;
//   }
// }

// .image {
//   width: 10vw;
//   aspect-ratio: 1;
// }

.container {
  background: #f8faff;
  font-family: "Poppins-Regular";
  padding-bottom: 10vh;
}

.successContainer {
  justify-content: center;
  align-items: center;
}
.avatar {
  border-radius: 5px;
  width: 8vw;
  aspect-ratio: 1;
  margin-bottom: 1vh;
}
.refNumber {
  font-size: 0.8rem;
  margin-bottom: 4vh;
}
.successBox {
  width: 80%;

  background: #fff;
  padding: 2vw;
  align-items: center;
}

.successIcon {
  width: 8vw;
  aspect-ratio: 1;
  margin-bottom: 2vh;
}
.mainTextContainer {
  margin-bottom: 10vh;
  align-items: center;
}
.mainText {
  color: #002c94;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 2vh;
}

.okay {
  font-family: inherit;
  border: none;
  padding: 1vh 2vw;
  color: #0045e6;
  border-radius: 5px;

  width: 8vw;
  background: #e6edff;
}

.remark {
  color: #202428;
  font-size: 0.8rem;
}
