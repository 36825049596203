@import '../../../../../../assets/sass/_variables.scss';
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  input:focus,
  input:active {
    outline: none;
    border: none;
  }
}

.container {
  min-width: 40%;
  // height: 85%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  background: #002c94;
  display: flex;
  justify-content: center;
  padding: 1vh 0vw;
  margin-bottom: 4vh;

  h1 {
    text-align: center;
    font-size: 1rem;
    color: #fff;
  }
}

.form {
  height: 40vh;
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #e6edff;
  border-radius: 5px;
  margin: 0 1vw;
  cursor: pointer;
  margin-bottom: 6vh;
  position: relative;
  overflow: hidden;

  .formHeader {
    color: #002c94;
    opacity: 0;
  }
  .innerContainer {
    // background: #0ac15750;
    border-radius: 5px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #202428;
      font-size: 1.6rem;
    }
  }
  .footerText {
    font-size: 0.8rem;
    font-family: 'Poppins-Regular';
    color: #002c94;
  }
}
.selectedForm {
  background: #002c94;
  overflow: auto;

  p {
    color: #fff !important;
  }
}
.radiosContainer {
  align-items: center;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
}
.radioButtonContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  // width: 100%;
  // max-width: 16vw;
  width: 80%;
  min-width: 18vw;
  min-height: 100%;
}
.radioInput {
  background-color: red;
}

.quantityContainer {
  justify-content: space-between;

  align-items: flex-end;
  margin: 0 4vw;
  margin-bottom: 10vh;
}
.rightContainer {
}

.rightColumn {
  justify-content: center;
  align-items: center;
  margin-right: 4vw;
  width: 6vw;
}
.label {
  font-size: 0.8rem;
  margin-bottom: 0.6vh;

  .required {
    color: red;
  }
}

.quantityButtons {
  border: 1px solid #002c94;
  border-radius: 5px;
  padding: 1vh 1vw;
  color: #002c94;
  margin-right: 0.4vw;
  background: none;
  cursor: pointer;
}

.quantity {
  border: 1px solid #002c94;
  border-radius: 5px;
  // padding: 1vh 3vw;
  text-align: center;
  width: 4vw;
  display: flex;
  justify-content: center;
  color: #002c94;
  margin-right: 0.4vw;
  background: none;
}
.quantity:focus,
.quantity:active {
  border: 1px solid #002c94;
  border: 1px solid #002c94;
}

.buttonsContainer {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  height: 10vh;
  margin-bottom: 10px;
  button {
    border: none;
    width: clamp(140px, 160 * $vw-const, 170 * $vw-const);
    height: clamp(40px, 50 * $vw-const, 50 * $vw-const);
    margin-right: 1vw;
    margin-left: 1vw;
    border-radius: 3px;
    cursor: pointer;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .positive {
    background: #0045e6;
    color: #fff;
    transition: all 0.1s ease;
}
  
.positive:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.positive:active {
  transform: scale(0.95);
}

}

.labelAddButton {
  background: white;
  border: none;
  border-radius: 2px;
  width: 2vw;
  cursor: pointer;
}
.labelInputQuantity {
  background: white;
  border: none;
  border-radius: 2px;
  width: 410 * $vw-const;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding: 10px 25px;
}
.labelAmt {
  color: white;
  font-size: 18 * $vw-const;
  margin-left: 6px;
  margin-top: 50* $vw-const;
  text-align: left;
}
.rupeeSign {
  position: absolute;
  top: 6px;
  left: 15px;
}
.defaultPriceList {
  display: flex;
  width: 75%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 50* $vw-const;

}

.defaultPrice {
  width: 160 * $vw-const;
  height: 48 * $vw-const;
  display: flex;
  align-items: center;
  background: #E6EDFF;
  color: #0045E6;
  border-radius: 5px;
  font-size: 18 * $vw-const;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;
  text-align: center;
}


.totalContainer {
  overflow: hidden;
}
.totalBottom {
  font-size: 20 * $vw-const;
  margin-bottom: 6vh;
  margin-left: 8px;
  margin-bottom: 30* $vw-const;
  font-family: 'Poppins-SemiBold';
}
.viewMoreContainer {
  width: 100%;
  justify-content: flex-end;
}
.viewMore {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 3px;
  padding: 0.5vh 1vw;
  margin-bottom: 3vh;
  margin-right: 1vw;
  align-self: flex-end;
  font-size: 0.6rem;
  cursor: pointer;
}

.iconImage {
  // width: 4vw;
  height: 8vh;

  margin-bottom: 4vh;
}
