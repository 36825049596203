.authLayout {
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &__left_section {
    width: 55%;
    background: linear-gradient(135deg, #001953 0%, #195eff 100%);
    background-size: contain;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 800px;
    &__header {
      text-align: center;
      .title {
        display: flex;
        align-items: center;
        span {
          font-family: "Poppins-Bold";
          font-size: 2rem;
          color: $white;
          letter-spacing: 0.07rem;
          text-align: center;
          font-weight: 700;
        }
        img {
          width: 2.13rem;
          height: 2.13rem;
        }
      }
      span {
        font-family: "Poppins-SemiBold";
        font-size: 1.5rem;
        color: $white;
        letter-spacing: 0.06rem;
        text-align: center;
        font-weight: 600;
      }
    }
    &__image_container {
      width: 80%;
      height: 100%;
      background-image: url("../images/left-section.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__right_section {
    width: 45%;
  }

  // @include media-breakpoint-down(md) {
  //   &__left_section {
  //     width: 0%;
  //   }
  //   &__right_section {
  //     width: 100%;
  //   }
  // }
}
