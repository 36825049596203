@import '../../../../../../assets/sass/_variables.scss';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //  width: 95%;
  padding: 20 * $vw-const 0;
  width: 100%;
}
.bodyContainer {
  width: 95%;
  margin: 0 auto;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: $blue-100;
  font-size: 32 * $vw-const;
}
.header {
  width: 100%;
  margin-top: 30 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  .inputText {
    background-color: #e6edff;
    height: 39px;
    border: 0;
    border-radius: 0;
    outline: none;
    width: 400 * $vw-const;
    font-size: 16 * $vw-const;
    margin-left: 0px;
    border-radius: 5px;
    margin-right: 0;
  }
  .clearLabel {
    font-size: 14 * $vw-const;
    color: #e67717;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 100;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 20px;
  }
  .applyBtn {
    width: 140 * $vw-const;
    font-size: 16 * $vw-const;
  }
}
.tableWrapper {
  height: 35vh;
  width: 100%;
  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  border: none;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
  .status {
    .statusLabel {
      background: rgba(211, 211, 212, 0.2);
      border: 1px solid #d3d3d4;
      border-radius: 5px;
      width: 110 * $vw-const;
      padding-left: 10 * $vw-const;
      display: flex;
      align-items: center;
      height: 20px;
      .roundIcon {
        width: 10 * $vw-const;
        height: 10 * $vw-const;
        background: #d3d3d4;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
  .statusApproved {
    .statusLabel {
      background: rgba(10, 193, 87, 0.2);
      border: 1px solid #0ac157;
      border-radius: 5px;
      // background: rgba(211, 211, 212, 0.2);
      // border: 1px solid #d3d3d4;
      .roundIcon {
        background: #0ac157;
      }
    }
  }
  .statusRejected {
    .statusLabel {
      background: rgba(230, 119, 23, 0.2);
      border: 1px solid #e67717;
      .roundIcon {
        background: #e67717;
      }
    }
  }
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  font-size: 14 * $vw-const;
}

.availableCredits {
  font-size: 16 * $vw-const;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1230 * $vw-const;
  margin: 30 * $vw-const auto 70 * $vh-const auto;
  .flexBox {
    display: flex;
  }
  .creditsBox {
    width: 600 * $vw-const;
    height: 150 * $vw-const;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .leftTopLabel {
      font-family: 'Poppins-Medium';
      font-size: 14 * $vw-const;
      color: #ffffff;
      letter-spacing: 0.56px;
      font-weight: 500;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    .credits {
      font-family: 'Poppins-Bold';
      font-size: 32 * $vw-const;
      color: #ffffff;
      letter-spacing: 1.27px;
      text-align: center;
      font-weight: 700;
    }
  }
  .creditsBoxGreen {
    background-color: #0ac157;
    margin-right: 30px;
  }
  .creditsBoxGrey {
    background-color: #5d6774;
  }
}
.creditsLabel {
  font-family: 'Poppins-SemiBold';
  font-size: 16 * $vw-const;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  border: 1px solid #0045e7;
  height: 48 * $vw-const;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
.learnMore {
  font-family: 'Poppins-Medium';
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  text-align: center;
  font-weight: 500;
  text-decoration: underline;
  text-align: right;
  margin-top: 5px;
  cursor: pointer;
}
