.container {
  width: 40%;
  position: relative;
  background-color: white;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  overflow: auto;
}
.bodyContainer {
  /* padding: 4.16vh; */
  padding: 0 4.16vh;
  padding-top: 0;
  flex-direction: column;
}

.buttonContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  height: auto;
  /* padding: 0.89vh; */
  flex-direction: row;
  /* background-color: red; */
}

.heading {
  display: flex;
  font-family: Poppins-SemiBold;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 60px;
  font-size: 2.4vh;
  margin-bottom: 1.57vh;
  background: #002c94;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

.button {
  width: 7.854vw;
  height: 3.56vh;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #5a69ed 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  font-size: 1.94vh;
  margin-right: 10px;
  cursor: pointer;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}

.gridOfTwo {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
.gridOfThree {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  grid-column-gap: 2rem;
}
.divider {
  margin-bottom: 1rem;
}
.imageWrapper {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.userIcon {
  height: 110px;
}
.imageSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 160px;
  background: #e6edff;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}

.containerClassNames {
  height: 40px !important;
  margin-bottom: 1vh !important;
}

.labelClassNames {
  font-size: 0.8rem;
}

.shiftRows {
  width: 100%;
  justify-content: space-between;
}
