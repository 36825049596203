@import "../../assets/sass/variables";
//  Person details css in RequestCard Popup


.mainContainer {
  width: 95%;
  margin: 0 auto;
  padding: 30 * $vw-const;
  background: #ffffff;
//  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.tabsContainer {
  //  flex-shrink: 0;
  margin-right: 20 * $vw-const;
  width: 250 * $vw-const;
  border-right: 1px solid #cbc9c9;
  padding-right: 30 * $vw-const;
  .imgContainer {
    .imgIcon {
      width: 195 * $vw-const;
      height: 195 * $vw-const;
   //   background-color: #002c94;
      border-radius: 15 * $vw-const;
      margin: 0 auto;
      margin-bottom: 10 * $vw-const;
     
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .centerIcon {
      width: 195 * $vw-const;
      height: 195 * $vw-const;
      border-radius: 15 * $vw-const;
    }
    .name {
      font-family: 'Poppins-Regular';
      font-size: 18 * $vw-const;
      color: #002C94;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 10* $vw-const;
    }
    .nameLabel {
      font-family: 'Poppins-Regular';
      font-size: 18 * $vw-const;
      color: #989191;
      letter-spacing: 0;
      font-style: italic;
      line-height: 1.5;
    }

    .label {
      font-family: 'Poppins-Regular';
      font-size: 18 * $vw-const;
      color: #002c94;
      letter-spacing: 0;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30 * $vw-const;
    }
    
  }

  .button {
    font-family: 'Poppins-Regular';
    font-size: 16 * $vw-const;

    color: #767676;
    letter-spacing: 0.64px;
    font-weight: 100;
    border-top: 1px solid #cbc9c9;
    cursor: pointer;
    height: 75 * $vw-const;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .editIconWrapper {
      width: 32 * $vw-const;
      height: 32 * $vw-const;
      margin-right: 10 * $vw-const;
      background: #f3f3f3;
      border-radius: 16 * $vw-const;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background: #002c94;
    }
    img {
      width: 18 * $vw-const;
    }
  }
  .lastButton {
    border-bottom: 1px solid #cbc9c9;
  }
}

.dataItemWrapper {
  width: 530 * $vw-const;
  border-bottom: dashed;
  border-width: thin;
  border-color: #d8d8d8;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Poppins-Regular';
  font-size: 16 * $vw-const;
  color: #202428;
  letter-spacing: 0.64px;
  font-weight: 400;
  padding: 15 * $vw-const 0;
  display: flex;
  justify-content: flex-start;
  .label {
    min-width: 280 * $vw-const;
    margin-right: 10 * $vw-const;
  }
  .agencyLabelWidth {
    min-width: 220 * $vw-const;
  }
  .profileLabelWidth {
    min-width: 280 * $vw-const;
  }
}
.marginLeft30 {
  margin-left: 30 * $vw-const;
}

.headLabel {
  font-family: 'Poppins-Regular';
  font-size: 18 * $vw-const;
  color: #002c94;
  letter-spacing: 0;
  line-height: 1.5;
  font-weight: 400;
}

.dataContainer {
  //  background: #e6edff;
  padding: 0vh 15 * $vw-const;
  width: 100%;
  border-radius: 5px;
  min-height: 50vh;
}

.profileImage {
  border-radius: 5px;
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 2vw;
}


.dropdownContainerStyle {
  height: 5.5vh;
}
.SannaLogo {
  margin-top: 25 * $vw-const;
}
.documentViewLink {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  margin-top: -3px;
  img {
    width: 25  * $vw-const;
    cursor: pointer;
  }
}
.greyColor {
  color: #b0b0b0;
}
.blackListBtn {
  width: 160 * $vw-const;
  font-size: 18 * $vw-const;
  margin-top: 30 * $vw-const;
  
}
.unBlackListBtn {
  width: 200 * $vw-const;
  font-size: 18 * $vw-const;
  margin-top: 30 * $vw-const;
  
}