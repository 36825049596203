@import '../../../../../../assets/sass/_variables.scss';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //  width: 95%;
  padding: 20 * $vw-const 0;
}
.bodyContainer {
  width: 95%;
  margin: 0 auto;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: $blue-100;
  font-size: 32 * $vw-const;
}
.header {
  width: 100%;
  margin-top: 30 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  align-items: center;

  .learnMore {
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    color: #86909e;
    letter-spacing: 0.56px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}
.right {
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  .inputText {
    background-color: #e6edff;
    height: 39px;
    border: 0;
    border-radius: 0;
    outline: none;
    width: 400 * $vw-const;
    font-size: 16 * $vw-const;
    margin-left: 0px;
    border-radius: 5px;
    margin-right: 0;
  }
  .clearLabel {
    font-size: 14 * $vw-const;
    color: #e67717;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 100;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 20px;
  }
  .applyBtn {
    width: 160 * $vw-const;
    font-size: 16 * $vw-const;
  }
}



.tableWrapper {
  .ag-root-wrapper {
    border: none !important;
  }

  .actionContainer {
    .editIcon,
    .deleteIcon {
      width: 15 * $vw-const;
      margin-right: 15 * $vw-const;
      cursor: pointer;
    }
  }
}

.tableWrapperAutoHeight {
  max-height: 60vh;
  width: 100%;
  // overflow: auto;
  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}


.tableWrapperNormal {
  height: 60vh;
  width: 100%;
  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}
.addRoleBtn {
  background: #0ac157;
  border-radius: 5 * $vw-const;
  color: white;
  border: none;
  font-size: clamp(10px, 12 * $vw-const, 12 * $vw-const);
  padding: 5 * $vw-const;
  cursor: pointer;
  min-width: 90 * $vw-const;
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  border: none;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
  .status {
    .statusLabel {
      background: rgba(211, 211, 212, 0.2);
      border: 1px solid #d3d3d4;
      border-radius: 5px;
      width: 110 * $vw-const;
      padding-left: 10 * $vw-const;
      display: flex;
      align-items: center;
      height: 20px;
      .roundIcon {
        width: 10 * $vw-const;
        height: 10 * $vw-const;
        background: #d3d3d4;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
  .statusApproved {
    .statusLabel {
      background: rgba(10, 193, 87, 0.2);
      border: 1px solid #0ac157;
      border-radius: 5px;
      // background: rgba(211, 211, 212, 0.2);
      // border: 1px solid #d3d3d4;
      .roundIcon {
        background: #0ac157;
      }
    }
  }
  .statusRejected {
    .statusLabel {
      background: rgba(230, 119, 23, 0.2);
      border: 1px solid #e67717;
      .roundIcon {
        background: #e67717;
      }
    }
  }
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  font-size: 14 * $vw-const;
}
.SannaLogo {
  position: absolute;
  bottom: 0;
  left: 40%;
}