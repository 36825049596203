@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap');
@import '../../../../../assets/sass/variables';
.container {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: hidden;
  font-family: 'Poppins';
}
.rightContainer {
  width: 45%;
  display: flex;
  padding: 0 30px;
}

.backlabel {
  // margin-top: 30px;

  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.registrationlabel {
  margin-top: 65px;
  margin-bottom: 50px;
  justify-content: center;
  font-size: 24px;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
}
.backButtonIcon {
  height: 24px;
  aspect-ratio: 1;
  margin-right: 10px;
}
// .formContainer {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
// }
.filterDropdown {
  height: 4.26vh;
  outline: none;
  width: 16.41vw;
  border: 1px solid #e1e1e1;
  border-radius: 0.45vh;
  font-size: 14px;
  margin-bottom: 55px;
}
.dropdown {
  // width: 16.25vw;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  //padding: 0rem 1rem;
}
.selectLabelClass {
  //margin-bottom: 0.5rem;
}

.fieldsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}
// @media screen and (min-width:10)
.gridOfTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1vw;
  > div {
    width: 20vw;
  }
  // FIXME: shift to other class
}
@media screen and (max-width: 1080px) and( min-width:767px) {
  .gridOfTwo {
    grid-column-gap: 2vw;
    > div {
      width: 40vw;
    }
  }
}

.formContainer {
  flex: 1;
  height: 100vh;
  overflow: auto;
  background-color: '#F8FAFF';
  padding: 2rem 2rem 4rem 2rem;
}

.header {
  font-size: 1.5rem;
  text-align: center;
  color: #002c94;
  font-family: 'Poppins-semibold';
  margin-bottom: 4rem;
}

.divider {
  margin-bottom: 1rem;
}

.formFields {
  font-size: 0.9rem;
  margin-bottom: 1.4rem;
}

.submitButton {
  width: 40%;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1080px) {
  .rightContainer {
    display: none;
  }
}
