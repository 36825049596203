// @import '../../../../../../../assets/sass/_variables.scss';
@import '../../../../../assets/sass/variables';

.mimicContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #E5ECFF;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 32 * $vw-const;
  margin-top: 24 * $vh-const;
}
.header {
  width: 100%;
  margin-top: 50 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.headerContainer {
  display: flex;
    justify-content: space-between;
    margin-bottom: 25* $vw-const;
    margin-top: 25* $vw-const;
    padding: 0 2rem;
    
}

.profileContainer {
  min-width: 200 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10 * $vw-const;
  cursor: pointer;
  background-color: #E5ECFF;
  border-radius: 5px;
  // margin-top: 30 * $vw-const;
  .pic {
    width: 40 * $vw-const;
    height: 40 * $vw-const;
    // background-color: gray;
    margin-right: 10px;
  }
  .name {
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    font-family: 'Poppins-SemiBold';
    color: #002C94;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
  }
  .role {
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    font-family: 'Poppins-Regular';
    color: #202428;
    letter-spacing: 0.56px;
    text-align: right;
    font-weight: 400;
  }
  .settingBtn {
    width: clamp(10px, 30 * $vw-const, 30 * $vw-const);
    position: relative;
    margin-left: 10px;
    img {
      position: absolute;
      right: 10px;
    }
  }
}


.sidebar {
  width: 20vw;
  background-color: red;
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E5ECFF;
  overflow: auto;
  padding-bottom: 4rem;
  // padding-top: 10vh;
}
.topContainer {
  height: 16vh;
  min-height: 16vh;
  border-radius: 10px;
  width: 95%;
  margin: 0 auto;
  background: linear-gradient(to bottom, #1C4BBD 0%, #062675 100%);
  margin-bottom: 12vh;
  padding: 1vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  
  p {
      color: #fff;
      font-size: 0.8rem;
      font-family: 'Poppins-Bold'
  };
}

.date {
  margin-right: 1vw;
}
.graphContainer {
  background-color: #fff;
  width: 95%;
  height: 100%;
  flex: 1;
  min-height: 50rem;
  overflow: auto;
  // min-height: 70%;
  // overflow: auto;
  padding: 2rem;
  border-radius: 10px;
  margin: 2rem auto;
  box-shadow: 0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1);
}
.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

}
.liveContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
      color: #123DA3;
      font-size: 0.8rem;
  }
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 5px;
  justify-content: space-evenly;

}

.greenCircle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #16a34a;
  margin-right: 5px;
}

.inputDate {
  font-size: 10px;
  text-align: center;
}

.metrics {
  width: 90%;

  align-self: center;
  margin-top: -10rem;
  margin-bottom: 4rem;
  justify-content: flex-start;

  gap: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.metricCardContainer {
  background-color: #FFFFFF;
  padding: 0.4rem;
  height: 5.5rem;
  min-height: 5.5rem;
  max-width: 15rem;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}

.imageContainer {
  height: 100%;
  width: 30%;
  background-color: #F4F4F4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.4rem;
  border-radius: 10px;
  position: relative;

  img {
     width: 1.4rem;
     aspect-ratio: 1;
     object-fit: contain;
  }
}

.metric {
  color: #082F8E;
  font-family: 'Poppins-Bold';
  font-size: 2rem;
}

.title {
  font-size: 0.8rem;
  font-family: 'Poppins-Light';
}

.numberContainer {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #BABABE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateWrapper {
  position: absolute;
  left: -40%;
  top: 100%;
  .datePicker {
    position: absolute;
    background-color: white;
    left: 12 * $vw-const;
    box-shadow: 0px 0.27vh 0.55vh #00000029;
    z-index: 1;
  }

  .dateRange {
   // background-color: #0ac157;
  }
}

.number {
  color: #fff;
  font-size: 0.5rem;
}

.rightFiltersContainer {
  align-items: flex-start;
  position: relative;

  gap: 10px;
}

.metricButton {
  cursor: pointer;
  height: 75%;
  width: 2.5rem;
  background-color: #EFEFEF;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metricButtonIcon {
  height: 1rem;
  aspect-ratio: 1;
  object-fit: contain;
}
.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #F9F9F9;
  padding: 1rem;
}
.metricsFiltersHeader {
  font-size: 0.8rem;
  color: #767676;
  margin-bottom: 1rem;
}
.gateFiltersContainer {
  border-left: 1px solid #e2e8f0;
  padding-left: 2rem;
}

.apply {
  height: 70%;
  width: 6rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background-color:  #002c94;
  color: #fff;
  font-size: 0.8rem;
}