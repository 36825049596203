@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap");
@import "../../../assets/sass/variables";
.container {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: hidden;
}
.rightContainer {
  width: 45%;
  display: flex;
  padding: 0 30px;
}
.backlabel {
  margin-top: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.registrationlabel {
  margin-top: 65px;
  margin-bottom: 50px;
  justify-content: center;
  font-size: 24px;
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
}
.backButtonIcon {
  height: 24px;
  aspect-ratio: 1;
  margin-right: 10px;
}
.formContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.filterDropdown {
  height: 4.26vh;
  outline: none;
  width: 16.41vw;
  border: 1px solid #e1e1e1;
  border-radius: 0.45vh;
  font-size: 14px;
  margin-bottom: 55px;
}
.dropdown {
  // width: 16.25vw;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  //padding: 0rem 1rem;
}
.selectLabelClass {
  //margin-bottom: 0.5rem;
}

.fieldsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}
.backButton {
  background-color: #E6EDFF;
  color: #0045E6;
  cursor: pointer;
}

.buttonPrimary {
  background-color: #e67717!important;
  cursor: pointer;
}
.buttonSecondary {
  background-color: #0045e6 !important;
  cursor: pointer;
}
.blueText {
font-size: clamp(10px,18 * $vw-const,18 * $vw-const);
color: #002C94;
letter-spacing: 0.72px;
text-align: center;
font-weight: 600;
}
.successIcon {
  height: clamp(90px,160 * $vw-const,160 * $vw-const); 
  aspect-ratio: 1;
  margin-bottom: 12*$vw-const;
}
.requestSuccess {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 45vh;
  flex: 1;
}
.successIconContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
}