.solutionsContainer {
  // height: 55rem;
  width: 100%;
  background-image: url('../../../../assets/images/Landing/solutions/image.png');
  // background-size: cover;
  position: relative;
  background-size: 150% 150%;
  animation: gradient 15s ease infinite;
  //   background-position: 0 -10rem;
  // overflow-y: auto;
  // position: sticky;
  // top: 0;
}

.overlay {
  background-color: #001c3550;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.content {
  position: relative;
  // padding-top: 10rem;
  padding: 2rem 0rem;
  display: flex;
  padding: 2rem 4rem;
}

.leftContainer {
  max-width: 40%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.rightContainer {
  position: relative;
  height: 100%;
}

.header {
  color: #ec8940;
  text-transform: uppercase;

  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
}

.loopContainer {
  padding-top: 1rem;
}
.subHeader {
  color: #ec8940;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  padding-bottom: 4rem;
}

.subHeaderText {
  color: #bababa;
  font-size: 1rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 2rem;
}

.bullets {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 2rem;

  p {
    color: #bababa;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
  }
}

.ciclegraph {
  position: relative;
  width: 20rem;
  height: 20rem;
  margin: calc(100px / 2 + 0px);
  transition: all 1s linear;
}

.animate {
  animation: blip 2s linear infinite !important;
}

@keyframes blip {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.8);
  }
}

.ciclegraph:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  // border: 1px dashed #9e9e9e30;
  width: calc(100% - 2px * 2);
  height: calc(100% - 2px * 2);
  border-radius: 50%;
}
.innerCircle {
  // width: 100%;
  // height: 100%;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 2rem 0rem;
}
.ciclegraph .circle {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  width: 5rem;
  height: 5rem;
  // margin: calc(-5rem / 2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(3px);
  position: relative;
}
.animateSpinSlow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 75%;
  width: 75%;
  margin: auto auto;
  animation: spinSlow 2s linear infinite;
}

@keyframes spinSlow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
