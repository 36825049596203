@import '../../../../assets/sass/variables';
.popupContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
// overriding ag-theme classes
.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}
.ag-overlay-no-rows-wrapper {
  opacity: 0.5;
  font-family: 'Poppins-Medium';
  font-size: 16 * $vw-const;
  color: #202428 !important;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 500;
}
