@import '../../assets/sass/_variables.scss';
.container {
  width: 40%;
  position: relative;
  background-color: white;
  border-radius: 5px;
  /* border-left: solid; */
  flex-direction: column;
  /* padding: 0.89vh; */
}
.bodyContainer {
  padding: 4.16vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
}
.buttonContainer button {
  /* padding: 5px;
    border-radius: 5px; */
  /* background-color: transparent; */
}

.textContainer {
  flex-direction: row;
  flex: 1;
  /* padding: 0.89vh; */
  padding-right: 1.78vh;
  //color: #707070;
  font-size: 1.85vh;
  display: flex;
  align-items: center;
  text-align: center;
}
.content {
  font-size: 16 * $vw-const;
  text-align: center;
  margin-bottom: 20 * $vw-const;
}
.resendOTPText {
  font-size: 16 * $vw-const;
  margin-top: 20 * $vw-const;
  text-decoration: underline;
  color: #0045e6;
  text-align: center;
  cursor: pointer;
  width: 6vw;
  align-self: center;
}
.heading {
  font-family: 'Poppins-SemiBold';
  font-size: 24 * $vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
  margin: 0px auto;
  margin-bottom: 30 * $vw-const;
}
.message {
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}

.button {
  text-align: center;
  background: $blue-100;
  height: 3rem;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: $white;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin: 1rem;
  width: 15rem;
  cursor: pointer;
}

.okButton {
  text-align: center;
  background: #e6edff;
  height: 3rem;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: #0045e6;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin: 1rem;
  width: 15rem;
  cursor: pointer;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}
.popupTextarea {
  height: 200px;
  width: 90%;
  padding: 15px;
}

.timer {
  font-size: 16 * $vw-const;
  margin-top: 20 * $vw-const;
  color: #e67300;
  text-align: center;
  width: 15vw;
  align-self: center;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  // .button {
  //   width: 15.854vw;
  //   font-size: 1.42vh;
  //   height: 3.2vh;
  // }
}

.otpError {
  display: flex;
  justify-content: center;
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}
