.helpCenter {
  // padding: 1vh 1vw;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.illustration {
  width: 20rem;
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 0.8rem;
}
.cardBody {
  padding: 1rem;
  border-radius: 10px;
  background-color: #e6edff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.icon {
  // width: 2rem;
  height: 2rem;
  object-fit: contain;
  aspect-ratio: 1;
  margin-right: 2rem;
}

.cardTextContent {
  color: #002c94;
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;
  p {
    font-family: Poppins-Bold;
    display: inline-block;
    margin-right: 0.2rem;
  }
  span {
    font-size: 0.7rem;
    font-family: Poppins-Light;
    font-style: italic;
  }
}

.divide {
  height: 1px;
  border-top: 1px dashed #e6edff;

  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.CTAButton {
  // width: 45%;
  font-size: 0.8rem !important;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  text-decoration: none;
}
.instructionsHeader {
  color: #002c94;

  font-family: Poppins-Medium;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0.6rem;
}

.customPadding {
  padding-top: 2rem;
}

.crossIcon {
  position: absolute;
  right: 10px;
  top: -2.8rem;
  width: 1rem !important;
  height: 1rem !important;

  img {
    height: 0.6rem;
    object-fit: contain;
  }
}
