.container {
  // padding: 1vh 1vw;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.crossIcon {
  position: absolute;
  right: 10px;
  top: -2.8rem;
  width: 1rem !important;
  height: 1rem !important;
  z-index: 300;

  img {
    height: 0.6rem;
    object-fit: contain;
  }
}

.content {
  padding: 1rem 2rem;
}

.heading {
  font-size: 0.8rem;
  font-family: Poppins-Medium;
  margin-bottom: 1rem;
}
.video {
  cursor: pointer;
}

.videoContainer {
  width: 15rem;
  height: 10rem;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bottomBadge {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000070;
  height: 1.2rem;
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  color: #fff;
  padding: 0rem 0.4rem;
  align-items: center;
}
.play {
  width: 4rem;
  aspect-ratio: 1;
  object-fit: contain;
}

.videoContent {
  position: relative;
  margin-top: 0.4rem;

  p {
    font-size: 0.8rem;
    font-family: Poppins-Bold;
  }
  span {
    font-size: 0.6rem;
    color: #86909e;
    font-family: Poppins-Light;

    display: block;
    margin-top: -0.2rem;
  }
}

.webLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}