.container {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pdfClassName {
  height: 80vh;
  overflow: auto;
}

.imageViewer {
  max-width: 70vw;
  max-height: 70vh;
  overflow: auto;
}

.button {
  position: absolute;
  right: -10px;
  top: -10px;
  border: none;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
  // height: 20px;
  // width: 20px;
}

.crossIcon {
  height: 20px;
  width: 20px;
}
