.buttonPrimary {
  background-color: #e6edff !important;
  color: #0045e6 !important;
  cursor: pointer;
}
.buttonSecondary {
  background-color: #0045e6 !important;
  cursor: pointer;
}

.popupContainerStyle {
  position: absolute;
  top: 0;
}
