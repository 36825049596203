
$vw-const: (100/1920) * 1vw;
$vh-const: (100/1200) * 1vh;

// colors
$white: #ffffff;
$ghost-white: #f8faff;
$orange-100: #e67717;

$blue-100: #0045e6;
$blue-160: #002c94;
$blue-60: #3773ff;
$blue-40: #5f8fff;
$blue-20: #87abff;
$blue-5: #e6edff;

$gray-20: #b3bac2;
$gray: #b3bac2;
$lightGray: #b3bac2;

// // scss-docs-start theme-color-variables
$primary: $blue-100;
// $secondary: $pink;

$primary-text-color: $blue-100;

$body-bg: $ghost-white;
