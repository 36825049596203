@import '../../../../../assets/sass/_variables.scss';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 99%;
  padding: 20 * $vw-const;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 32 * $vw-const;
  margin-top: 24 * $vh-const;
}
.header {
  width: 100%;
  margin-top: 50 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cards {
  display: flex;
  margin-top: 20 * $vw-const;
  margin-bottom: 40 * $vw-const;
  flex-wrap: wrap;
}
.leftContainer {
  flex: 1;
}
.topContainer {
  background: #002c94;
  border-radius: 5px;
  color: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20 * $vw-const;
  padding-right: 50 * $vw-const;
  width: 1000 * $vw-const;
  margin-top: 30 * $vw-const;
  align-items: center;
  .name {
    opacity: 0.75;
    font-size: 20 * $vw-const;
    color: #d3d3d4;
    letter-spacing: 0.8px;
    font-weight: 500;
  }
  .agencyName,
  .role {
    font-size: 25 * $vw-const;
    color: #d3d3d4;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 23 * $vw-const;
  }
  img {
    width: 200 * $vw-const;
  }
}
.statusCardContainer {
  width: 485 * $vw-const;
  height: 236 * $vw-const;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-right: 25 * $vw-const;
  margin-bottom: 60 * $vw-const;
  color: #202428;
  letter-spacing: 0.96px;
  text-align: center;
  background: #e6edff;
  border: 1px solid #0045e6;
  box-shadow: -7px -7px 23px 0 #ffffff, 7px 7px 23px 0 rgba(55, 84, 170, 0.15),
    inset 4px 4px 17px 0 rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  .countContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .count {
    font-family: 'Poppins-Bold';
    font-size: 50 * $vw-const;
    color: #002c94;
    letter-spacing: 1.99px;
    text-align: center;
    font-weight: 700;
  }
  .status {
    font-weight: 600;
    font-size: 16 * $vw-const;
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    //    margin-top: 80 * $vw-const;
  }
  img.rightImg {
    height: 100%;
  }
  img.leftTopImg {
    width: 68 * $vw-const;
    top: 10px;
    left: 10px;
    position: absolute;
  }
}
.applyBtn {
  width: 160 * $vw-const;
  font-size: 16 * $vw-const;
  margin-top: 40 * $vw-const;
  transition: all 0.1s ease;
}
  
.applyBtn:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.applyBtn:active {
  transform: scale(0.95);
}

.profileContainer {
  width: 425 * $vw-const;
  height: 315 * $vw-const;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  justify-content: end;
  padding: 25 * $vw-const;
  margin-top: 30 * $vw-const;
  .pic {
    width: 100 * $vw-const;
    height: 100 * $vw-const;
    // background-color: gray;
  }
  .name {
    font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);
    color: #002c94;
    letter-spacing: 0.72px;
    text-align: center;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .role {
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    color: #202428;
    letter-spacing: 0.56px;
    text-align: center;
    font-weight: 400;
  }
  .settingBtn {
    background: #e6edff;
    width: 90%;
    height: 48 * $vw-const;
    margin-top: 20 * $vw-const;
    font-size: clamp(10px, 16 * $vw-const, 16 * $vw-const);
    color: #002c94;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    position: relative;
    img {
      width: clamp(10px, 22 * $vw-const, 22 * $vw-const);
      position: absolute;
      right: 10px;
    }
  }
}
.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #b3bac2;
  text-align: center;
}
.link {
  text-decoration: none;
}
.dateIcon {
  width: clamp(10px, 45 * $vw-const, 45 * $vw-const);
}
.dateIconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 100%;
    top: -15* $vw-const;
}