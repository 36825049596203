@import '../../../../../assets/sass/variables';

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2vh;
  background: white;
  width: 100%;
  height: 100vh;
  overflow: auto;
  border-radius: 5px;
}

.formContainer {
  width: 400 * $vw-const;
  margin-bottom: 20 * $vw-const;

  input[type='checkbox'] {
    width: 1.042vw;
    height: 1.85vh;
  }
}
.loginText {
  font-family: 'Poppins-SemiBold';
  font-size: 24 * $vw-const;
  text-align: center;
  color: #002c94;
  letter-spacing: 0.06rem;
  font-weight: 600;
  margin-bottom: 2vh;
}
.customLabel {
  font-size: 0.8rem;
}

.loginButton {
  background: #0045e6;
  width: 17.917vw;
  height: 4.44vh;
  border: 1px solid;
  border-radius: 0.46vh;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 0.46vh auto;
  margin-bottom: 4.44vh;
}

.forgotPasswordRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Poppins-Regular';
  font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -1.85vh;
  margin-bottom: 2vh;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.677vw;
    }
  }
  a {
    transition: all 0.2s ease;
    font-family: 'Poppins-Regular';
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
  a:hover {
    color: #334155;
  }
  a:active {
    transform: scale(0.95);
  }
}

.create_user {
  text-align: center;
  display: block;
  margin-bottom: 1.29vh;
  span {
    font-family: 'Poppins-Regular';
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 400;
    width: 11.617vw;
  }
  button {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    background: none;
    border: none;
    transition: all 0.2s ease;
  }
  button:hover {
    color: $blue-160;
    text-decoration: none;
  }
  button:active {
    transform: scale(0.95);
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50 * $vh-const;

  .buttonPrimaryOutlined {
    transition: all 0.1s ease;
    background: #ffffff;
    width: 344 * $vw-const;
    height: 48 * $vw-const;
    border: 1px solid;
    border-radius: 0.46vh;
    color: $blue-100;
    font-family: 'Poppins-Medium';
    margin: 0.46vh auto;
    margin-bottom: 2vh;
    font-size: clamp(10px, 16 * $vw-const, 16 * $vw-const);
  }
  .buttonPrimaryOutlined:hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .buttonPrimaryOutlined:active {
    transform: scale(0.95);
  }
}

.logoText {
  font-size: 0.8rem;
}

.divider {
  margin-bottom: 20 * $vh-const;
}
// .buttonContainer {
//   background-color: blue;
//   width: 100%;
// }
.submitButton {
  width: 100%;
  height: 48 * $vw-const;
  transition: all 0.1s ease;

  // padding-top: 1.8vh;
  // padding-bottom: 1.8vh;
}

.submitButton:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.submitButton:active {
  transform: scale(0.95);
}
.block1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  margin-top: 150 * $vh-const;
}

.footerContainer {
  font-family: 'Poppins-Regular';
  font-size: 12 * $vw-const;
  color: #86909e;
  letter-spacing: 0.48px;
  font-weight: 400;
  margin-top: 50 * $vh-const;
  .copyright {
    margin-top: 10 * $vw-const;
    text-align: center;
  }
  .privacy,
  .terms,
  .help {
    cursor: pointer;
    margin-right: 10 * $vw-const;
  }
  .privacy:hover,
  .terms:hover,
  .help:hover {
    text-decoration: underline;
    color: #000000;
  }
}
.updatedDate {
  display: flex;
  width: 97%;
  justify-content: end;
  font-size: clamp(8px, 12 * $vw-const, 12 * $vw-const);
  color: #d3d3d4;
  letter-spacing: 0.48px;
  font-weight: 400;
}
.mobilePlayStoreIcons {
  margin-bottom: 10px;
  display: flex;
  .playStoreIcon {
    width: 20 * $vw-const;
    margin-right: 10 * $vw-const;
    margin-left: 10 * $vw-const;
  }
  .appStoreIcon {
    width: 20 * $vw-const;
    margin-left: 10 * $vw-const;
  }
}

.languageContainer {
  position: absolute;
  right: 15 * $vw-const;
  top: 15 * $vw-const;
  background: #e6edff;
  border-radius: 16.5 * $vw-const;
  font-size: 14 * $vw-const;
  width: 170 * $vw-const;
  display: flex;
  justify-content: space-between;
  div {
    padding: 5px 10 * $vw-const;
    cursor: pointer;
  }
  .currentLanguage {
    background: rgba(131, 175, 251, 0.35);
    border-radius: 16.5 * $vw-const;
    text-decoration: underline;
  }
  .lang {
    color: #202428;
    letter-spacing: 0.56px;
    text-align: center;
    width: 90 * $vw-const;
  }
}
.toolTip {
  max-width: 500 * $vw-const;
}
.modalContainer {
  position: fixed;
  right: 20px;
  top: 20px;
  background-color: rgba(0, 69, 230, 0.7);
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  font-size: 0.8rem;
  border: none;
  display: flex;
  align-items: flex-start;
  animation: slideIn 1s ease-in-out;
  z-index: 2;
  pointer-events: none;
}
.modalContent {
  max-width: 500px;
}

.closeButton {
  background-color: transparent;
  border: none;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  cursor: pointer;
}

@keyframes slideIn {
  from {
    right: -100%;
  }
  to {
    right: 0%;
  }
}

.notification {
  font-family: 'Poppins-SemiBold';
  position: relative;
  overflow: hidden;
  min-height: 5rem;
  font-size: 18 * $vw-const;
  color: #e67717;
  letter-spacing: 0.72px;
  background: rgba(230, 119, 23, 0.1);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 90%;
  margin-top: 20 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 15 * $vw-const;
  img {
    width: 80 * $vw-const;
    height: 80 * $vw-const;
    margin-right: 20 * $vw-const;
  }
}

.notificationBar {
  position: absolute;
  height: 4px;
  background-color: #e67717;
  bottom: 0;
  left: 0;
  transition: all 0.2s ease;
}
