/* button styles */
.buttonWrapper {
  /* width: 100%; */
  text-align: center;
}

.button {
  /* width: 6vw; */
  padding: 0.4vh 0.8vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 1vw;
  min-height: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button:disabled,
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.secondaryButton {
  border: 0.01vw solid #878787;
  border-radius: 0.2vw;
  background: white;
}

.PrimaryButton {
  border: 0.01vw solid #215020;
  border-radius: 0.2vw;
  background: #0045e6;
  color: white;
}
.primaryOutline {
  border: 1px solid #0045e6;
  border-radius: 0.2vw;
  background: #ffffff;
  color: #0045e6;
  transition: all 0.2s ease;
}
.primaryOutline:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.12);
}
.primaryOutline:active {
  transform: scale(0.95);
}
.dangerButton {
  border: 0.01vw solid #d92020;
  border-radius: 0.2vw;
  background: #d92020;
  color: white;
}
.tertiaryButton {
  border: 0.01vw solid #e6edff;
  border-radius: 0.2vw;
  background: #e6edff;
  color: #000;
}

.icon {
  height: 1rem;
  aspect-ratio: 1;
  object-fit: contain;
  margin: 0 0.4rem;
}
