@import '../../../../../assets/sass/variables';

.headerContainer {
  margin-bottom: 4vh;
}

.mainContainer {
  width: 60vw;

  margin: 0 auto;
}

.buttonsContainer {
  flex-shrink: 0;
  margin-right: 1vw;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: none;
  border-radius: 5px;
  width: 12vw;
  padding: 1.5vh 0;
  margin-bottom: 1vh;
  color: #002c94;
  cursor: pointer;

  img {
    width: 1.5vw;
    margin-right: 1vh;
  }
}

.dataContainer {
  background: #e6edff;
  padding: 2vh 2vw;
  width: 100%;
  border-radius: 5px;
  min-height: 50vh;
}
.topContainer {
  margin-bottom: 4vh;
}

.profileImage {
  border-radius: 5px;
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 2vw;
}
.rightForm {
  width: 100%;
}

.disabledLabel {
  color: #20242870;
}
.disabledContainer {
  // border: 1px solid #002c9470;
  width: 20vw !important;
  height: 5.5vh;
}

.formContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  // margin-bottom: 8vh;
  height: 100%;
  margin-bottom: 4vh;
}

.containerClass {
  // width: 20vw !important;
  background: #fff !important;
  // border: 1px solid #002c9470;
  // height: 5.5vh;
}

.btnPrimary {
  width: 18vw;
  height: 5.5vh;
  border-radius: 5px;
  background: #0045e6;
  border: none;
  color: #fff;
}

.dropdownContainerStyle {
  height: 5.5vh;
}
.disableDropDown {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
}

.formFields {
  font-size: 0.9rem;
  width: 20vw !important;
  // height: 7.5vh;
  height: 80 * $vw-const;
  margin-bottom: 6vh;

  // margin-bottom: 1.4rem;
}
