@import '../../../assets/sass/_variables.scss';

.container {
  min-width: 60vw;
  max-width: 60vw;
  min-height: 80vh;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: auto;
}
.header {
  background-color: #002c94;
  padding: 1rem;
  text-align: center;
  color: #fff;
}

.body {
  padding: 1rem 2rem;
  margin-bottom: 4rem;

  .heading {
    font-size: 1rem;
    font-family: 'Poppins-Bold';
    color: #202428;
    margin-bottom: 1rem;
  }
}

.boxContainer {
  width: 100%;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.box {
  border-radius: 5px;
  overflow: hidden;
  width: 25vw;
}
.contentContainer {
  padding: 1rem;
  background-color: #eff2f8;

  p {
    font-family: Poppins-Regular;
    font-size: 0.8rem;
    color: #202428;
    letter-spacing: 0.72px;
    font-weight: 400;
  }
}
.boxImageContainer {
  background-color: #4f9c75;
  display: flex;
  justify-content: center;
  padding: 0.4rem 0rem;
}
.boxImage {
  // width: 10rem;
  height: 12rem;
  aspect-ratio: 1;
}

.exclamation {
  height: 5rem;
  margin-right: 0.8rem;
  aspect-ratio: 1;
}
.alert {
  width: 2rem;
  margin-right: 0.8rem;
  aspect-ratio: 1;
}

.cta {
  align-items: center;
  p {
    font-family: Poppins-SemiBold;
    font-size: 1rem;
    color: #e67717;
    letter-spacing: 0.72px;
    font-weight: 600;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;

  button {
    width: 6rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  .neutral {
    background-color: #e6edff;
    color: #0045e6;
  }

  .negative {
    background-color: #e67717;
    color: #fff;
  }
}

.stepTwoImage {
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  img {
    height: 6rem;
    aspect-ratio: 1;
  }
}

.stepTwoMiddleContainer {
  p {
    font-family: Poppins-Regular;
    font-size: 1rem;
    color: #202428;
    letter-spacing: 0.64px;
    font-weight: 400;
  }
  max-width: 40rem;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.stepTwoSteps {
  padding: 1rem 4rem;
  border-radius: 10px;
  background-color: #eff2f8;
  margin-bottom: 2rem;

  p {
    margin: 1.5rem 0;
    color: #e67717;
  }
}

// New styles
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
}
.buttonContainer button {
  /* padding: 5px;
    border-radius: 5px; */
  /* background-color: transparent; */
}

.textContainer {
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  border-radius: 20px;
  font-size: 1.85vh;
  display: flex;
  align-items: center;
  text-align: center;
  background: #eff2f8;
  margin-bottom: 2rem;
}
.content {
  font-size: 16 * $vw-const;
  text-align: center;
  margin-bottom: 20 * $vw-const;
}
.resendOTPText {
  font-size: 16 * $vw-const;
  margin-top: 20 * $vw-const;
  text-decoration: underline;
  color: #0045e6;
  text-align: center;
  cursor: pointer;
  width: 6vw;
  align-self: center;
}
.heading {
  font-family: 'Poppins-SemiBold';
  font-size: 24 * $vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
  margin: 0px auto;
  margin-bottom: 30 * $vw-const;
}
.message {
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}

.button {
  text-align: center;
  background: $blue-100;
  height: 3rem;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: $white;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin: 1rem;
  width: 15rem;
  cursor: pointer;
}

.okButton {
  text-align: center;
  background: #e6edff;
  height: 3rem;
  border: none;
  font-family: 'Poppins-SemiBold';
  font-size: 1rem;
  color: #0045e6;
  letter-spacing: 0.04rem;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin: 1rem;
  width: 15rem;
  cursor: pointer;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}
.popupTextarea {
  height: 200px;
  width: 90%;
  padding: 15px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  // .button {
  //   width: 15.854vw;
  //   font-size: 1.42vh;
  //   height: 3.2vh;
  // }
}

.otpError {
  display: flex;
  justify-content: center;
  font-family: 'Poppins-Regular';
  margin: 0px auto;
  font-weight: bold;
  color: #e67717;
  opacity: 1;
  font-size: 1.13rem;
}

.stepThreeHeader {
  font-family: Poppins-Regular;
  font-size: 1rem;
  color: #393939;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 400;
  margin: 4rem 0rem;
}
.ctaSecond {
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  p {
    font-family: Poppins-SemiBold;
    font-size: 1rem;
    color: #e67717;
    letter-spacing: 0.72px;
    font-weight: 600;
  }
}

.infotext {
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  font-family: 'Poppins-Regular';
}
