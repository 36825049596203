@import '../../../../../../assets/sass/variables';

.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
}

.container {
  width: 60%;
  height: 85%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  flex-direction: row;
  background: #002c94;
  justify-content: center;
  padding: 1vh 1vw;
  margin-bottom: 2vh;

  p {
    font-size: 1rem;
    font-weight: 'bold';
    color: #fff;
  }
}
.filtersWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
 flex-direction: column;
}
.selectWrapper {
  margin-bottom: 20px;
}
.searchContainer {
  width: 320px;
  height: 38px;
  justify-content: center;
}


.searchInput {
  flex: 1;
  background: #e6edff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  padding: 1.5vh 1vw;
  margin-right: 1px;
}

.searchInput::placeholder {
  color: #002c9470;
  font-size: 13 * $vw-const;

}

.searchButton {
  display: flex;
  background: #e6edff;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.4vh 1vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
  width: 1vw;
  aspect-ratio: 1;
  // flex: 1;
}

.personCardsContainer {
  border: 1px solid green;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-self: center;
  min-height: 310 * $vw-const; 
  // background: green;
  width: 636* $vw-const; ;
  margin: 0 auto;
  margin-bottom: 20 * $vw-const; 
  margin-top: 80 * $vw-const; 

  justify-content: flex-start;
  align-items: flex-start;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins-Regular;
}
.tableMetadata {
  justify-content: space-between;
}

.tableWrapper {
  // height: 600px;
  width: 90%;
  margin: 0 auto;
  min-height: 180 * $vh-const;
  // max-height: 20vh;
  // overflow: auto;
  margin-bottom: 50 * $vh-const;
}

.image {
  height: 15px;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  // width: 100%;
  width: 4vw;
}

.buttonContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 2vh;

  button {
    min-width: 6vw;
    border: none;
    margin-right: 2vw;
    padding: 1vh 2vw;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16* $vw-const;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .finish {
    background: #0045e6;
    color: #ffffff;
    transition: all 0.1s ease;
  }
  
  .finish:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .finish:active {
    transform: scale(0.95);
  }

}

.tripTypeDetails {
  display: flex;
   
    width: 100%;
    flex-direction: column;
    padding: 10 * $vw-const;
   p, span {
    font-size: 16* $vw-const;;
    color: #202428;
    letter-spacing: 0.56px;
    line-height: 1.40625vw;
    font-weight: 400
   }
   .radioButtons {
    display: flex;
    background: white;
    padding: 20 * $vw-const 15 * $vw-const;
    border-radius: 5px;
    justify-content: center;
    input {
      margin-right: 10px;
    }
    > div {
      display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 60* $vw-const;
    }
   }
}

.personDetails {
  // background: blue;
  padding: 1vh 2vw;
  justify-content: space-between;
  display: flex;
    flex-direction: column;
    width: 98%;
    height: 95%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1%;
    background: #F6F6F6;
border-radius: 5px;
}
.imageTextContainer {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2vh;
  align-items: center;
  display: flex;
  // width: 100%;
  // background: red;
}
.vehicleSuccessBlock {
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-top: -80px;
  margin-bottom: 30px;
  .successContent {
      font-size: 14* $vw-const;
      color: #202428;
      letter-spacing: 0.56px;
      font-weight: 400;
      margin-bottom: 30px;
  }
  .successIcon {
    height: 140 * $vw-const;
    aspect-ratio: 1;
    border-radius: 5px;
    margin-right: 1vw;
  }
}
.vehicleSummaryTop {
  width: 90%;
  margin-top: 20px;
}
.summaryBlock {
  display: flex;
    justify-content: space-around;
    margin-bottom: 30* $vw-const;
    margin-top: 30* $vw-const;

  .dataLabel {
    font-size: 14* $vw-const;
    color: #202428;
    letter-spacing: 0.56px;
    line-height: 21* $vw-const;
    font-weight: 400;
    margin-bottom: 10px;
  } 
  .dataValue {
  font-size: 14 * $vw-const;
  color: #002C94;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 10px;

  } 

}

.personImage {
  height: 120 * $vw-const;
  aspect-ratio: 1;
  border-radius: 5px;
  margin-right: 1vw;
}

.textArea {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  p {
    color: #202428;
font-size: 14* $vw-const;
color: #202428;
letter-spacing: 0.56px;
line-height: 27* $vw-const;
font-weight: 400;
  }

  span {
    font-size: 18* $vw-const;
    color: #002C94;
    letter-spacing: 0.56px;
    line-height: 27* $vw-const;
    font-weight: 600;
  }

}
.marginTop80 {
  margin-top: 80px;
}
.personAlreadyAdded {
  text-align: center;
  font-size: 0.8rem;
  color: #e67717;
}

.addMaterialButton {
  background: none;
  border: none;
  font-size: 0.8rem;
  color: #0045e6;
  cursor: pointer;
  font-weight: bold;
}

.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
}
