@import '../../../../../assets/sass/variables';

.headerContainer {
  margin-bottom: 4vh;
}

.mainContainer {
  width: 95%;
  margin: 0 auto;
  padding: 30 * $vw-const;
  background: #ffffff;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.tabsContainer {
  //  flex-shrink: 0;
  margin-right: 20 * $vw-const;
  width: 250 * $vw-const;
  border-right: 1px solid #cbc9c9;
  padding-right: 30 * $vw-const;
  .imgContainer {
    .imgIcon {
      width: 220 * $vw-const;
      height: 152 * $vw-const;
      background-color: #002c94;
      border-radius: 15 * $vw-const;
      margin: 0 auto;
      margin-bottom: 10 * $vw-const;
      background-image: linear-gradient(
        138deg,
        #4d77d9 0%,
        #4a75d7 0%,
        #022f9c 80%
      );
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .centerIcon {
      width: 20 * $vw-const;
    }

    .label {
      font-family: 'Poppins-Regular';
      font-size: 18 * $vw-const;
      color: #002c94;
      letter-spacing: 0;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30 * $vw-const;
    }
  }

  .button {
    font-family: 'Poppins-Regular';
    font-size: 16 * $vw-const;

    color: #767676;
    letter-spacing: 0.64px;
    font-weight: 100;
    border-top: 1px solid #cbc9c9;
    cursor: pointer;
    height: 75 * $vw-const;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .editIconWrapper {
      width: 32 * $vw-const;
      height: 32 * $vw-const;
      margin-right: 10 * $vw-const;
      background: #f3f3f3;
      border-radius: 16 * $vw-const;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      background: #002c94;
    }
    img {
      width: 18 * $vw-const;
    }
  }
  .lastButton {
    border-bottom: 1px solid #cbc9c9;
  }
}

.dataItemWrapper {
  width: 530 * $vw-const;
  border-bottom: dashed;
  border-width: thin;
  border-color: #d8d8d8;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Poppins-Regular';
  font-size: 16 * $vw-const;
  color: #202428;
  letter-spacing: 0.64px;
  font-weight: 400;
  padding: 15 * $vw-const 0;
  display: flex;
  justify-content: flex-start;
  .label {
    min-width: 220 * $vw-const;
  }
  .agencyLabelWidth {
    min-width: 220 * $vw-const;
  }
  .profileLabelWidth {
    min-width: 280 * $vw-const;
  }

  .value {
  }
}
.marginLeft30 {
  margin-left: 30 * $vw-const;
}

.headLabel {
  font-family: 'Poppins-Regular';
  font-size: 18 * $vw-const;
  color: #002c94;
  letter-spacing: 0;
  line-height: 1.5;
  font-weight: 400;
}

.dataContainer {
  //  background: #e6edff;
  padding: 0vh 15 * $vw-const;
  width: 100%;
  border-radius: 5px;
  min-height: 50vh;
}

.profileImage {
  border-radius: 5px;
  width: 10vw;
  height: 10vw;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 2vw;
}
.rightForm {
  width: 100%;
}

.disabledLabel {
  color: #20242870;
}
.disabledContainer {
  // border: 1px solid #002c9470;
  width: 20vw !important;
  height: 5.5vh;
}

.formContainer {
  flex-wrap: wrap;
  // justify-content: space-between;
  // margin-bottom: 8vh;
  height: 100%;
  margin-bottom: 50 * $vw-const;
  margin-top: 50 * $vw-const;
  margin-right: -140 * $vw-const;
}

.containerClass {
  // width: 20vw !important;
  background: #fff !important;
  // border: 1px solid #002c9470;
  // height: 5.5vh;
}

.btnPrimary {
  width: 18vw;
  height: 5.5vh;
  border-radius: 5px;
  background: #0045e6;
  border: none;
  color: #fff;
  cursor: pointer;
}

.dropdownContainerStyle {
  height: 5.5vh;
}
.disableDropDown {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
}

.formFields {
  font-size: 14 * $vw-const;
  width: 440 * $vw-const !important;
  // height: 7.5vh;
  height: 60 * $vw-const;
  margin-bottom: 90 * $vw-const;
  margin-right: 140 * $vw-const;
  float: left;
  * {
    font-size: 16 * $vw-const;
  }
}
.SannaLogo {
  margin-top: 25 * $vw-const;
}
.passwordFieldsContainer {
  margin-top: 30* $vw-const;
  flex-direction: column;
  display: flex;
  .btnPrimary {
    margin-top:  30* $vw-const;
    margin-bottom:  30* $vw-const;

  }
}

.passwordFields {
  div:first-child {
    width: 21.5rem;
  }
  img {
    width: 30* $vw-const;
    height: 30* $vw-const;
    object-fit: scale-down;
    top: 33px;
  }
}