/* button styles */
.buttonWrapper {
  /* width: 100%; */
  text-align: center;
}

.button {
  /* width: 6vw; */
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  //   min-height: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: none;
}
.button:disabled,
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.cross {
  background: #e6edff;
}
.icon {
  height: 1rem;
  aspect-ratio: 1;
  object-fit: contain;
}
