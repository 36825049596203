.container {
  width: 40vw;
  height: 40vh;
  background: white;
  border-radius: 10px;
  padding: 1vw 1vh;
}

.header {
  font-size: 1.2rem;
  color: #002c94;
  text-align: center;
  margin-bottom: 2vh;
}

.bottomFields {
  padding: 0 1vw;
  margin-bottom: 2vh;
}

.formInput {
  width: 30%;
  margin-right: 5vw;
  display: flex;
  flex-direction: row;
}

.buttonsContainer {
  justify-content: center;
  margin-bottom: 2vh;

  button {
    font-family: inherit;
    border: none;
    padding: 1vh 2vw;
    color: #fff;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
  }

  .approve {
    background: #0045e6;
  }
  .reject {
    background: #e6edff;
    color: #0045e6;
  }
  .surrender {
    background: #e67717;
    color: #fff;
  }
}

.closeIcon {
  position: absolute;
  right: 1vw;
  width: 1vw;
  aspect-ratio: 1;
}

.containerClassNames {
  height: 40px !important;
  width: 18vw;
  margin-right: 2vw;
}

.labelClassNames {
  font-size: 0.8rem;
}

.error {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 2vh;
  color: red;
}

//   .history {
//     width: 70%;
//     font-family: inherit;
//     font-size: 0.8rem;
//     font-weight: bold;
//     border: none;
//     padding: 1vh 2vw;
//     color: #0045e6;
//     border-radius: 5px;
//     margin-right: 2vw;
//     background: #e6edff;
//     cursor: pointer;
//   }
