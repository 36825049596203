.DateRangePicker__MonthHeader {
    justify-content: center;
    }

.DateRangePicker__CalendarSelection {
        background-color: #5968EA;
        border: 1px solid #5968EA;
}

.DateRangePicker__MonthHeader {
    justify-content: center;
  }
  
  .DateRangePicker__CalendarSelection {
    background-color: #5968ea;
    border: 1px solid #5968ea;
  }
  

  
  .ag-header-cell[col-id='cargoName'],
  .ag-cell[col-id='cargoName'],
  .ag-theme-balham .ag-ltr .ag-cell[col-id='cargoName'] {
    border-right: 0px solid #979797;
  }
  