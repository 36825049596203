@import '../../../../../assets/sass/variables';

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.backdrop {
  height: 30%;
  overflow: hidden;

  background-image: url('../../../../../assets/images/backdrop-blue/image.png');
  background-position: center;
  background-repeat: repeat;
}
.bottomContainer {
  height: 70%;
  position: relative;
}

.mainContent {
  min-height: 40rem;
  background-color: transparent;
  width: 80%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: -13rem;
  transform: translateX(-50%);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .leftContainer {
    flex: 1;
  }
  h1 {
    font-weight: bold;
    color: #fff;
  }
  img {
    width: 30 * $vw-const;
    aspect-ratio: 1;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.topContainer {
  border-radius: 10px;
  background-color: #fff;

  padding: 1.8rem 1rem;
  border: 1px solid #c7c7c7;
  position: relative;
  margin-bottom: 2rem;

  p {
    text-align: center;
    margin-bottom: 1rem;
  }
  span {
    color: #3e3e3e;
    font-family: 'Poppins-Bold';
  }
}

.containerIcon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.iconContainer {
  padding: 5px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.genericIcon {
  height: 50 * $vh-const;
}

.gateIcon {
  height: 30 * $vh-const;
}

.topContainerMetricsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #ecf0f9;
  border: 1px solid #c7c7c730;
  min-height: 6rem;
  margin-bottom: 1rem;
}

.topContainerMetricsContainerItem {
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #002c94;
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.topContainerMetricsIconsContainer {
  margin-right: 20 * $vw-const;
  margin-left: 20 * $vw-const;
}
.topContainerMetricsIcons {
  width: 30 * $vw-const;
  aspect-ratio: 1;
  object-fit: contain;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .number {
    font-family: 'Poppins-Medium';
    font-size: 1.4rem;
    color: #fff;
  }
  p {
    font-family: 'Poppins-Light';
    margin-bottom: 0;
    color: #fff;
  }
}

.topMiddleContainerHeader {
  font-size: 1rem;
  font-family: 'Poppins-Light';
  color: #4a4a4a;
  margin-bottom: 0rem !important;
}

.topMiddleContainerBox {
  padding: 0.6rem;
  border-radius: 10px;
  border: 1px solid #c7c7c770;
  margin-bottom: 1rem;
}
.topMiddleContainerBoxInner {
  border-radius: 10px;
  overflow: hidden;

  width: 100%;

  p {
    margin-bottom: 0rem;
  }
}
.topMiddleContainerBoxBody {
  background-color: #ecf0f9;
  padding: 0.4rem 0rem;
  min-height: 4.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.topMiddleContainerBoxNumber {
  font-size: 1.4rem;
  font-family: 'Poppins-Regular';
  color: #4c4c4c;
}
.topMiddleContainerBoxTag {
  font-size: 0.8rem;
  font-family: 'Poppins-Light';
}

.topMiddleMetricsIconContainer {
  position: absolute;
  left: 50%;
  bottom: -10px;
  height: 2.2rem;
  width: 2.2rem;
  background-color: #ecf0f9;
  transform: translateX(-50%);
  img {
    height: 30 * $vw-const;
  }
}

.borderRight {
  border-right: 1px solid #fff;
}

.topMiddleBox {
  flex: 1;
  margin: 0rem 0.4rem;
}

.topBottomContainerBox {
  border-radius: 10px;
  border: 1px solid #c7c7c770;
  overflow: hidden;
  flex: 1;
  margin: 0rem 0.4rem;
}
.topBottomIconContainer {
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  margin-left: 0.4rem;
  img {
    height: 20 * $vw-const;
  }
}

.topBottomContainer {
}

.topBottomContainerBoxBottom {
  min-height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.8rem;

  p {
    margin-bottom: 0;
    color: #3f3f3f;
  }

  .number {
    font-family: 'Poppins-Medium';
    font-size: 1.4rem;
  }
}
.bigCardHeaderText {
  margin-bottom: 0rem !important;
  color: '#4A4A4A';
  font-family: 'Poppins-Regular';
  margin-left: 20 * $vw-const;
  font-size: 0.8rem;
}

.topBottomIcon {
  height: 40 * $vw-const;
}

.bottomContainerBox {
  border-radius: 10px;
  background-color: #fff;
  padding: 1.8rem 1rem;

  border: 1px solid #c7c7c7;
  position: relative;
  margin-bottom: 2rem;
}
.bottomIconContainer {
  border-top: 1px solid #c7c7c7;
}

.tillDate {
  font-family: 'Poppins-Bold' !important;
  text-align: center;
  display: block;
  margin-bottom: 1rem;
}
.poweredBySanna {
  display: flex;
  align-items: center;
  padding-top: 10px;
  color: #b3bac2;
  text-align: center;
}

.profileIconButton {
  color: #fff;
}

.edrishtiLogo {
  width: 3rem !important;
  object-fit: contain;
  display: inline-block;
  margin-right: 1rem;
  // flex-grow: 1;
  aspect-ratio: 1;
  // object-fit: cover;
}

.logoutBtn {
  border: 1px solid #003fd3 !important;
  color: black;
  // padding: 0.4rem 1rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff !important;

  img {
    width: 20px;
    height: 20px;
  }
}

.inventoryContainer {
  padding: 1rem 0.8rem;
  // background-color: #ECF0F9;
  h2 {
    font-size: 1rem;
    font-family: 'Poppins-Bold';
    color: '#4A4A4A';
    margin-bottom: 1rem;
  }
}
.inventoryCardContainer {
  border-radius: 15px;
  border: 1px solid #c7c7c7;
  min-height: 10rem;
  width: 49%;
  // margin-right: 2rem;
  overflow: hidden;
}
.inventoryImageContainer {
  max-width: 30%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f8faff;
  flex-direction: column;
}
.inventoryImage {
  height: 2rem;
  aspect-ratio: 1;
  object-fit: contain;
  margin-bottom: 12rem;
}
.inventoryImageContainerClassName {
  margin-bottom: 0.4rem;
}
.inventoryImageText {
  font-family: Poppins-Regular;
  font-size: 0.8rem;
  color: #4a4a4a;
}
.inventoryContentContainer {
  width: 100%;
  flex: 1;
  padding: 2rem;
  justify-content: space-between;
}

.inventoryContent {
  justify-content: space-between;
  align-items: center;
}

.divideHorizontal {
  height: 1px;
  flex: 1;
  border-top: 1px dashed black;
  margin: 0rem 1rem;
}

.inventoryLabel {
  color: #3f3f3f;
  font-family: Poppins-Regular;
}

.inventoryValue {
  color: #4c4c4c;
  font-family: Poppins-Bold;
  font-size: 1.4rem;
}

.inventoryImageText {
  font-size: 0.8rem;
  font-family: Poppins-Regular;
  color: #4a4a4a;
}
