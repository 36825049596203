@import "../../../assets/sass/variables";


//  Person details css in RequestCard Popup
.cardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40*$vh-const;
}
.cardWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15*$vw-const;;
  // opacity: 0.5;
  background: #eceff7;
  border-radius: 5px;
  min-height: 120*$vw-const;;
  padding: 10*$vw-const;;
}
.cardheader {
  font-size: 18*$vw-const;;
  color: #002c94;
  letter-spacing: 0.72px;
  font-weight: 600;
}
.cardImage {
  height: 80*$vw-const;
  width: 80*$vw-const;
  margin-right: 20*$vw-const;
}
.info {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14*$vw-const;
}
.requestLabel {
  font-size: 14*$vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21*$vw-const;
  font-weight: 500;
  position: relative;
  a {
    text-decoration: none;
    color: #002c94;
  }

}
.requestKey {
  font-size: 14*$vw-const;
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 21*$vw-const;
  font-weight: 400;
  margin-bottom: 7*$vh-const;
}
.truckIcon {
  height: 50*$vw-const;
  width: 50*$vw-const;
  margin-right: 30*$vw-const;
}
.documentIcon {
  width: 20*$vw-const;
  margin-left: 5*$vw-const;
  position: absolute;

}


.successIcon {
  height: 160*$vw-const;
  aspect-ratio: 1;
  margin-bottom: 32*$vw-const;
}
.requestSuccess {
  display: flex;
  margin-top: 50*$vw-const;
  margin-bottom: 120*$vw-const;
  flex-direction: column;
  align-items: center;
}
.successMessage {
  font-size: 18*$vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
}

.popupTextarea {
  width: 80%;
  min-height: 150*$vw-const;
  background: #D3D3D4;
  border-radius: 5px;
  padding: 10*$vw-const;
  font-size: 14*$vw-const;
}
.rejectMessage {
  width: 80%;
  font-size: 18*$vw-const;
  color: #002c94;
  letter-spacing: 0.72px;
  font-weight: 600;
}
.requestReject {
  display: flex;
  margin-top: 250*$vh-const;
  margin-bottom: 220*$vh-const;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backButton {
  background-color: #E6EDFF;
  color: #0045E6;
  cursor: pointer;
}

.buttonPrimary {
  background-color: #e67717!important;
  cursor: pointer;
}
.buttonSecondary {
  background-color: #0045e6 !important;
  cursor: pointer;
}

