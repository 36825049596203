@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');
.contactContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 40rem;
  background: #fff;
  justify-content: space-between;
  font-family: 'Noto Sans', sans-serif;
  // horizontal padding of 2 rem
  font-weight: 400 !important;

  p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 0.8rem;
    color: #999999;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #001c35aa;
}
.contactImage {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.container {
  max-width: 90rem;
  margin: 0 auto;
  position: relative;
  z-index: 30;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftContainer {
  width: 50%;
}
.rightContainer {
  width: 50%;
}
.header {
  color: #ec8940;
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  padding-bottom: 5rem;
  text-transform: uppercase;
  margin-left: 1.5rem;
}

.contactFormContainer {
  background: #001c35;
  border-radius: 20px;
  padding: 2rem;
}

.formRow {
  display: flex;
  gap: 20px;
  margin-bottom: 1rem;
}
.formContainer {
  width: 100%;
  position: relative;
}

.formInput {
  padding: 1rem 2rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  font-family: 'Noto Sans', sans-serif;
  // horizontal padding of 2 rem

  font-size: 1rem !important;
  font-weight: 400 !important;
}

.formInput:focus {
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.formInput::placeholder {
  color: #fff;
}

.heroCTA {
  background: #ec8940;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 8px;
  margin-top: 2rem;
  border: none;
  cursor: pointer;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400 !important;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.successIcon {
  height: 4rem;
  object-fit: contain;
  margin-bottom: 2rem;
}

.headerContact {
  font-family: 'Noto Sans', sans-serif;
  font-size: 2rem !important;
  text-align: center;
  color: #ffffff;
  font-weight: 300 !important;
  margin-bottom: 2rem;
  position: relative;
  z-index: 40;
}

.subheadingContact {
  text-align: center;
  font-size: 1rem;
  position: relative;
  z-index: 40;
}

.formError {
  color: #ec8940 !important;
  font-size: 0.8rem;
  height: 10px;
}

.customPlaceholder {
  position: absolute;
  left: 2rem; // adjust this based on your input padding
  top: 1rem; // adjust this based on your input padding
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  color: #fff;
  pointer-events: none;
}
