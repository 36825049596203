@import '../src/assets/sass/custom.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins-Regular';
  scrollbar-width: thin;
  scrollbar-color: rgb(199, 201, 201) grey;
}
//for carousel in login page.
.carousel .control-dots {
  display: flex;
  justify-content: flex-end;
  right: 5px;
}
.carousel .control-dots .dot {
  margin: 0 3px !important;
}

// scrollbar CSS
 ::-webkit-scrollbar {
  width: 0.7vw;
  height: 0.7vw;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  margin-block: 0.5em;
}

/* Handle */
::-webkit-scrollbar-thumb
// ::-moz-scrollbar-thumb
{
  background: rgb(122, 121, 121);
  border-radius: 10px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
// ::-moz-scrollbar-thumb:hover
{
  background: grey;
  border: 3px solid transparent;
  background-clip: content-box;
}

// Tables common css
.ag-theme-balham .ag-header {
  border-bottom: 0px solid 0px;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border-right: 1px solid #979797 !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border-right: 1px solid #979797 !important;
  outline: none;
}
.ag-cell,
.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #979797;
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}
.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: auto;
  overflow-y: auto;
}

.ag-cell,
.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #979797!important;
  display: flex;
  align-items: center;
  line-height: 4;
  word-break: initial,
}
.ag-theme-balham .ag-cell {
  border: 0px solid transparent!important;
}
.ag-header-viewport {
  background-color: #e5ecff!important;
}
.ag-header-cell-text {
  line-height: 1.5;
}