@import '../../../../../../assets/sass/_variables.scss';
.mainContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 60%;
  height: 65%;
  background: #fff;
  flex-direction: column;
  border-radius: 5px;
  overflow: auto;
}

.header {
  background: #002c94;
  display: flex;
  justify-content: center;
  padding: 1vh 0vw;
  margin-bottom: 4vh;

  h1 {
    text-align: center;
    font-size: 1rem;
    color: #fff;
  }
}

.buttonsContainer {
  width: 60%;
  justify-content: center;
  margin: 0 auto;

  button {
    border: none;
    min-width: 10vw;
    height: 5vh;
    margin-right: 2vw;
    border-radius: 3px;
    cursor: pointer;
    padding: 0vh 2vw;
    margin-top: 20px;
  }

  .cancel {
    background: #e6edff;
    color: #0045e6;
  }
  .positive {
    background: #0045e6;
    color: #fff;
    transition: all 0.1s ease;
  }
    
  .positive:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .positive:active {
    transform: scale(0.95);
  }
  
}

.cartContainer {
  background: #e6edff;
  border-radius: 5px;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 1vh 1vw;
  margin-bottom: 2vh;
  min-height: 40vh;
  // width: 100%;
}
.tableWrapper {
  margin-bottom: 4vh;
}

.tableHeader {
  padding: 0.4vh 1vw;

  margin-bottom: 1.4vh;
  width: 95%;

  p {
    font-size: 0.8rem;
    color: #002c94;
  }
}
.tableBody {
  background: #d8d8d8;
  // padding: 1vh 1vw;
  padding: 1vh 1vw;
  margin: 0 0.4vw;
  margin-bottom: 1vh;

  width: 95%;
  align-items: center;
  border-radius: 5px;
  p {
    font-size: 0.8rem;
  }
}

.quantityButtons {
  border: 1px solid #002c94;
  border-radius: 5px;
  padding: 0 0.4vw;
  color: #002c94;
  margin-right: 0.4vw;
  background: none;
  cursor: pointer;
  background: #fff;
  border: 1px solid #002c94;
}

.quantity {
  border: 1px solid #002c94;
  border-radius: 5px;
  padding: 0.4vh 1vw;
  color: #002c94;
  margin-right: 0.4vw;
  background: none;
  background: #fff;
  border: 1px solid #002c94;
}

.cross {
  border: none;
  // padding: 1vh;
  height: 3vh;
  width: 3vh;
  border-radius: 50px;
  background: #5d6774;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1vh;
}

.divider {
  height: 1px;
  border-bottom: 1px dashed #979797;
  margin-bottom: 1vh;
}

.details {
  justify-content: space-between;
  margin-bottom: 20 * $vw-const;

  p {
    font-size: 0.8rem;
  }
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;

  h1 {
    font-family: 'Poppins-Bold';
    font-size: 2rem;
    color: #0ac157;
  }
  img {
    height: 10vw;
    aspect-ratio: 1;
  }
  h2 {
    font-size: 1.2rem;
    color: #002c94;
  }
  p {
    text-align: center;
    font-size: 0.8rem;

    span {
      font-family: 'Poppins-Semibold';
      font-size: 0.9rem;
    }
  }
  button {
    width: 8vw;
    height: 4vh;
    border: none;
    background: #e6edff;
    border-radius: 5px;
    color: #0045e6;
    cursor: pointer;
  }
}

.failureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;

  h1 {
    font-family: 'Poppins-Bold';
    font-size: 2rem;
    color: #e67717;
  }
  img {
    height: 10vw;
    aspect-ratio: 1;
  }
  h2 {
    font-size: 0.8rem;
    color: #000;
  }

  button {
    width: 8vw;
    height: 4vh;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 2vw;
  }
  .payLater {
    background: #e6edff;
    color: #0045e6;
  }
  .tryAgain {
    background: #0045e6;
    color: #fff;
    transition: all 0.1s ease;
}
  
.tryAgain:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.tryAgain:active {
  transform: scale(0.95);
}
}
