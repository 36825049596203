@import '../../../assets/sass/variables';
.container {
  width: 850 * $vw-const;
  position: relative;
  background-color: white;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  padding-bottom: 30 * $vw-const;
}
.secondContainer {
  overflow: auto;
  max-height: 90vh;
}
.bodyContainer {
  padding: 0 30 * $vw-const 0 30 * $vw-const;
  flex-direction: column;
}

.requestInfo * {
  font-size: 14 * $vw-const;
}

.closeIcon {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
}
.buttonContainer {
  flex: 1;
  display: flex;
  height: auto;
  padding: 0.89vh;
  flex-direction: row;
  justify-content: center;
  /* background-color: #F4F4F4; */
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 60 * $vw-const;
  font-size: 24 * $vw-const;
  font-weight: bold;
  margin-bottom: 30 * $vw-const;
  background: #002c94;
  border-radius: 5px 5px 0 0;
}

.button {
  height: 48 * $vh-const;
  width: 160 * $vh-const;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #5a69ed 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  height: 48 * $vh-const !important;
  font-size: 16 * $vh-const !important;

  margin-right: 10px;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}

.requestLabel {
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 500;
}
.buttonPrimary {
  background-color: #e6edff !important;
  color: #0045e6 !important;
}
.buttonSecondary {
  background-color: #0045e6 !important;
}

.creditsContainer {
  font-size: 14 * $vw-const;
  display: flex;
  align-items: center;
  margin-right: 25 * $vw-const;
  flex-direction: column;
  .creditsLabel {
    display: flex;
    align-items: center;
    background-color: #0ac157;
    width: 280 * $vw-const;
    height: 40 * $vw-const;
    border-radius: 5px;
    justify-content: space-between;
    padding: 10px;
    margin-left: 10px;
  }
  .label {
    color: white;
    margin-left: 5px;
  }

  .credits {
    color: #e6d10b;
  }
}
.requestInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16 * $vw-const;

  h1 {
    font-size: 1.4rem;
    color: #e67717;
    margin-bottom: 40 * $vh-const;
  }
  span {
    color: #0ac157;
    font-size: 1rem;
  }
  .subheadingText {
    color: #e67717;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 40 * $vh-const;
  }
  .orderIdText {
    color: #002c94;
    font-size: inherit;
    text-decoration: underline;
  }
  .thirdHeading {
    color: #002c94;
    margin-bottom: 40 * $vh-const;
  }
  p {
    margin-bottom: 2 * $vh-const;
  }
  img {
    height: 140 * $vh-const;
    aspect-ratio: 1;
    margin-bottom: 40 * $vh-const;
  }
}

.flexBox {
  display: flex;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 344px;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;

  input {
    background: #ffffff;
    border: 1px solid #b3bac2;
    border-radius: 5px;
    padding: 5px;
    height: 40px;
    font-size: 18 * $vw-const;
    font-weight: 100;
    margin-bottom: 20 * $vw-const;
  }
  input:placeholder {
    font-size: 16 * $vw-const;
    font-weight: 300;
  }
  input::placeholder {
    color: #d3d3d4;
    font-weight: 300;
    font-family: 'Poppins-Regular';
    font-size: 16 * $vw-const;
  }

  .error {
    color: #e67717;
    border-left-width: 1vw;
    font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
    position: absolute;
    bottom: -5px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}
