@import '../../../../../../assets/sass/_variables.scss';
.container {
  padding: 4vh 3vw;
}

.header {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2vh;
}
.leftText {
  align-items: center;
 
  .imagePara {
    align-items: center;
    font-size: 16 * $vw-const;
  }

  h1 {
    font-family: inherit;

    color: #002c94;
  }

  p {
    font-size: 0.8rem;
    color: #86909e;
    text-decoration: underline;
    margin-left: 0.2vw;
  }
  img {
    height: 1vw;
    aspect-ratio: 1;
    margin-left: 1vw;
    margin-right: 10* $vw-const;;
  }
}

.inputText {
  background-color: #e6edff;
  height: 39px;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 400 * $vw-const;
  font-size: 16 * $vw-const;
  margin-left: 0px;
  border-radius: 5px;
  margin-right: 0;
}

.buyCredits {
  background: #0045e6;
  padding: 1vh 1vw;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  height: clamp(35px, 50 * $vw-const, 50 * $vw-const);
  width: clamp(120px, 180 * $vw-const, 180 * $vw-const);
  margin-top: 30px;
  transition: all 0.1s ease;

}
  
.buyCredits:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.buyCredits:active {
  transform: scale(0.95);
}


.refreshButton {
  background: none;
  padding: 0.4vh 1vw;
  border: none;
  border-radius: 5px;
  color: black;
  margin-left: 1vw;
  cursor: pointer;
  text-decoration: underline;
}
.box {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.20);
  border-radius: 5px;
  height: clamp(112px, 112 * $vh-const, 112 * $vh-const);
  font-family: 'Poppins-Regular';
  color: #86909E;
  letter-spacing: 0.96px;
  text-align: center;
  line-height: 35px;
  align-items: center;
  .span {
    font-size: 24 * $vw-const;
   
  }  
  .totalCredits {
    color: #002C94;
    font-size: 24 * $vw-const;
    margin-left: 50 * $vw-const;
    margin-right: 5px;
    font-weight: bold;
  }
}
.buy {
  border: none;
  color: #fff;
  padding: 0.4vh 2vw;
  border-radius: 5px;
  background-color: #0ac157;
  cursor: pointer;
}
.SannaLogo {
  position: absolute;
  bottom: 0;
  left: 40%;
}