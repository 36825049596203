@import '../../../../../assets/sass/variables';

.agencyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header {
  width: 90%;
  margin-top: 35px;
  font-family: Poppins-Bold;
  font-size: 32px;
  color: #002c94;
  letter-spacing: 1.27px;
  font-weight: 700;
}
.bodyContainer {
  flex: 1;
  justify-content: center;
}
.tableWrapper {
  width: 90%;
  max-height: 60vh;
  overflow: auto;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #0ac157;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.inActiveDot {
  height: 10px;
  width: 10 * $vw-const;
  background-color: #e67717;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7 * $vw-const;
}

.defaultContainerStyle {
  flex: 1;
  display: inline-table;
  height: 47.92vh;
  padding-left: 6.302vw;
  padding-right: 4.479vw;
}
.rowHeader {
  font-size: 16 * $vw-const;
  color: #202428;
  background-color: #e6edff;
  font-weight: bold !important;
  line-height: 1.1;
  word-break: 'initial';
}
.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428 !important;
  font-size: 14 * $vw-const;
  font-weight: 400;
}
.gridRowClass:hover {
  background: #eeeaea !important;
}
.statusLabel {
  font-size: 14 * $vw-const;
  letter-spacing: 0.56px;
  font-weight: 500;
}
.activeStatus {
  display: flex;
  height: 24px;
  width: 102 * $vw-const;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  background-color: #c6edd6;
  border: 1px solid #c6edd6;
  border-radius: 5px;
}
.inActiveStatus {
  display: flex;
  height: 24px;
  width: 102 * $vw-const;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  background-color: #f3dbc7;
  border: 1px solid #f3dbc7;
  border-radius: 5px;
}
.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 90%;
}
.filterWrapper {
  display: flex;
  width: 90%;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20 * $vw-const;
}
.dropdown {
  margin-right: 25 * $vw-const;
  display: flex;
  flex-direction: row;
}
.inputDate {
  background-color: #e6edff !important;
  height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 9.625vw;
  font-size: 14 * $vw-const;
  margin-left: 10 * $vw-const;
  display: flex;
  align-items: center;
  padding-left: 10 * $vw-const;
  color: #002c94;
}
.inputText {
  background-color: #e6edff !important;
  height: 2.4rem;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 400 * $vw-const;
  font-size: 1.75vh;
  margin-left: 120 * $vw-const;
  border-radius: 5 * $vw-const;
  margin-right: 0;
}
.button {
  width: 140 * $vw-const;
  font-size: 1rem;
}
.left,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.clearLabel {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  color: #e67717;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.datePicker {
  position: absolute;
  background-color: white;
  top: 3.66vh;
  left: 12px;
  box-shadow: 0px 0.27vh 0.55vh #00000029;
  z-index: 1;
}
.dateWrapper {
  position: relative;
}
.image {
  height: 15px;
}
.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}
.maxOperatorsLimit {
  justify-content: center;
  height: 40px;
  align-items: center;
  display: flex;
}

.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: 1.6rem;
}
.headerImage {
  height: 1.8vw;
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 5px;
}

.imageContainer {
  border-radius: 5px;
  overflow: hidden;
  background: #e6edff;
  padding: 0.2vw;
  margin-right: 1vw;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1vh 2vw;
  margin-bottom: 4vh;
}
.actionImg {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.edit {
  height: 15px;
  width: 12px;
  margin-right: 1rem;
}
.delete {
  height: 16px;
  width: 14px;
}
