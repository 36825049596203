/* button styles */
.buttonWrapper {
  /* width: 100%; */
  text-align: center;
}

.button {
  /* width: 6vw; */
  padding: 0.4vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 1vw;
  min-height: 2.4rem;
}
.button:disabled,
.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.secondaryButton {
  border: 0.01vw solid #878787;
  border-radius: 0.2vw;
  background: white;
}

.PrimaryButton {
  border: 0.01vw solid #215020;
  border-radius: 0.2vw;
  background: #0045e6;
  color: white;
}

.dangerButton {
  border: 0.01vw solid #d92020;
  border-radius: 0.2vw;
  background: #d92020;
  color: white;
}
.tertiaryButton {
  border: 0.01vw solid #E6EDFF;
  border-radius: 0.2vw;
  background: #E6EDFF;;
  color: #000;  
}

.orangeBtn {
  border: 0.01vw solid #e67717;
  border-radius: 0.2vw;
  background: #e67717;
  color: white;
}
