@import '../../../../../assets/sass/variables';

.container {
  padding-top: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 100 * $vh-const;
  width: 70%;
  align-self: center;
  padding-bottom: 50 * $vh-const;
}
.formContainer {
  align-self: center;
  width: 100%;
}
.backlabel {
  // margin-top: 30px;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
  color: #002c94;
  letter-spacing: 0.56px;
  font-weight: 500;

  img {
    width: 1vw;
    aspect-ratio: 1;
    margin-right: 0.5vw;
  }
}

.header {
  font-size: 1.5rem;
  text-align: center;
  color: #002c94;
  font-family: 'Poppins-semibold';
  margin-bottom: 4rem;
}

.forgotPasswordContainer {
  align-self: flex-end;
  margin-bottom: 2vh;
}

.forgotPassword {
  font-family: 'Poppins-Regular';
  background: transparent;
  border: none;
  cursor: pointer;
}
