.container {
  font-size: 0.8rem;
  align-items: center;
  font-family: 'Poppins-Regular';
}

.checkbox {
  margin-right: 0.4rem;
  // width: 20px;
  // height: 20px;
  // aspect-ratio: 1;
}

.terms {
  color: #0045e6;
  text-decoration: underline;
  cursor: pointer;
}
