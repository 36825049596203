@import '../../../../assets/sass/_variables.scss';

.buttonPrimary {
  background-color: #e6edff !important;
  color: #0045e6 !important;
  cursor: pointer;
}
.buttonSecondary {
  background-color: #0045e6 !important;
  cursor: pointer;
}
.creditsContainer {
  font-size: 14 * $vw-const;
  display: flex;
  align-items: center;
  margin-right: 25 * $vw-const;
  flex-direction: column;
  .creditsLabel {
    display: flex;
    align-items: center;
    background-color: #0ac157;
    width: 280 * $vw-const;
    height: 40 * $vw-const;
    border-radius: 5px;
    justify-content: space-between;
    padding: 10px;
    margin-left: 10px;
  }
  .label {
    color: white;
    margin-left: 5px;
  }

  .credits {
    color: #e6d10b;
  }
}
.requestInfo {
  display: flex;
  justify-content: center;
  font-size: 16 * $vw-const;
  .addCreditsBlock {
    background-color: #e6edff;
    width: 600 * $vw-const;
    min-height: 358 * $vh-const;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    > div:first-child {
      text-align: center;
    }
    .headingLabel {
      font-family: 'Poppins-Regular';
      font-size: 16 * $vw-const;
      color: #202428;
      letter-spacing: 0.64px;
      text-align: center;
      line-height: 25px;
      font-weight: 400;
    }
    .label {
      font-family: 'Poppins-Regular';
      font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
      color: #202428;
      letter-spacing: 0.56px;
      font-weight: 400;
    }
    .amountBlock {
      width: 380 * $vw-const;
      display: flex;
      justify-content: space-between;
      font-family: 'Poppins-Medium';
      font-size: 16 * $vw-const;
      color: #202428;
      letter-spacing: 0.64px;
      font-weight: 500;
      margin: 0 auto;
      line-height: 2;
      span {
        color: #202428;
      }
    }
    .dottedLine {
      border-top: dashed;
      border-width: thin;
      border-color: gray;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.flexBox {
  display: flex;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 344px;
  margin: 0 auto;
  margin-top: 15px;

  input {
    background: #ffffff;
    border: 1px solid #b3bac2;
    border-radius: 5px;
    padding: 5px;
    height: 40px;
    font-size: 18 * $vw-const;
    font-weight: 100;
    margin-bottom: 20 * $vw-const;
  }
  input:placeholder {
    font-size: 18 * $vw-const;
    color: gray;
    font-weight: 300;
  }
}
