.DateRangePicker__MonthHeader {
  justify-content: center;
}

.DateRangePicker__CalendarSelection {
  background-color: #5968ea;
  border: 1px solid #5968ea;
}

.DateRangePicker__MonthHeader {
  justify-content: center;
}

.DateRangePicker__CalendarSelection {
  background-color: #5968ea;
  border: 1px solid #5968ea;
}



.ag-header-cell[col-id='timeStamp'],
.ag-cell[col-id='timeStamp'],
.ag-theme-balham .ag-ltr .ag-cell[col-id='timeStamp'] {
  border-right: 0px solid #979797;
}

