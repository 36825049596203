@import '../../../../../../assets/sass/variables';

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  flex-direction: column;
  overflow: auto;
}
.formContainer {
  width: 95%;
    margin: 0 auto;
  align-items: center;
  padding: 40 * $vw-const 30 * $vw-const 30 * $vw-const 0 * $vw-const;
  .inputBox {
    width: 365 * $vw-const;
    margin-right: 20 * $vw-const;;
    height: clamp(50px, 50 * $vw-const,50 * $vw-const);

  }
  .submitButton {
    width: 200 * $vw-const;
    height: clamp(50px, 50 * $vw-const,50 * $vw-const);
    margin-top: 10 * $vw-const;
    margin-left: 20* $vw-const;
  }
}
@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }
  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}

.buttonsContainer {
  margin-bottom: 30 * $vh-const;
  margin-top: 30 * $vh-const;

  button {
    border: none;
    padding: 1vh 4vw;
    background: #e6edff;
    color: #0045e6;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
  }
}
.SannaLogo {
  margin-top: 200 * $vw-const;
  // position: absolute;
  // bottom: 0;
  // left: 40%;
}

.slider {
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 70 * $vw-const;
  background-color: #E6EDFF;
  align-items: center;
  justify-content: space-between;
  padding: 0 40 * $vw-const;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Poppins-Regular';
  font-size: 18 * $vw-const;
  color: #002C94;
  letter-spacing: 0.72px;
  font-weight: 400;
  margin-top: 20 * $vw-const;
  
}
.sliderOpen {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dropDownIcon {
    width: 30  * $vw-const;
    transform: rotate(270deg);
}
.dropDownRotate {
  width: 30  * $vw-const;
  transform: rotate(0deg);
}

.noRowsFound {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20 * $vw-const;
  color: #002c94;
}

.tableContainer {
  width: 95%;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  border-top: 1px solid #E6EDFF;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 20 * $vw-const;

}
.latestActivity {
  margin-bottom: 30 * $vw-const;
}

.header {
  width: 100%;
  margin-top: 20 * $vw-const;
  margin-bottom: 20 * $vw-const;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.left {
  display: flex;
  align-items: center;

  .dateWrapper {
    position: relative;
    margin-right: 25 * $vw-const;
    .datePicker {
      position: absolute;
      background-color: white;
      left: 12 * $vw-const;
      box-shadow: 0px 0.27vh 0.55vh #00000029;
      z-index: 1;
    }
    .inputDate {
      background-color: #e6edff !important;
      height: 39px;
      border: 0;
      border-radius: 0;
      outline: none;
      width: 260 * $vw-const;
      font-size: 16 * $vw-const;
      display: flex;
      align-items: center;
      padding-left: 10 * $vw-const;
      padding-right: 10 * $vw-const;
      color: #002c94;
      margin-top: 3px;
      justify-content: space-between;
    }
    .calendar {
      width: 15px;
    }
  }
}
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  .inputText {
    background-color: #e6edff;
    height: 39px;
    border: 0;
    border-radius: 0;
    outline: none;
    width: 200 * $vw-const;
    font-size: 16 * $vw-const;
    margin-left: 0px;
    border-radius: 5px;
    margin-right: 0;
  }
  .clearLabel {
    font-size: 14 * $vw-const;
    color: #e67717;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 100;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 30 * $vw-const;
    margin-right: 30 * $vw-const;
  }
  .applyBtn {
    width: 140 * $vw-const;
    font-size: 16 * $vw-const;
  }
}
.tableWrapperAutoHeight {
  max-height: 60vh;
  width: 100%;
  // overflow: auto;
  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}


.tableWrapperNormal {
  height: 60vh;
  width: 100%;
  overflow: auto;
  .ag-root-wrapper {
    border: none !important;
  }
}
.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #002C94;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #FFFFFF;
  letter-spacing: 0.64px;
  border: none;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428;
  font-size: 14 * $vw-const;
  font-weight: 100;
  .status {
    .statusLabel {
      background: rgba(211, 211, 212, 0.2);
      border: 1px solid #d3d3d4;
      border-radius: 5px;
      width: 110 * $vw-const;
      padding-left: 10 * $vw-const;
      display: flex;
      align-items: center;
      height: 20px;
      .roundIcon {
        width: 10 * $vw-const;
        height: 10 * $vw-const;
        background: #d3d3d4;
        border-radius: 5px;
        margin-right: 3px;
      }
    }
  }
}

.gridRowClass:hover {
  background: #eeeaea !important;
}

// overriding ag-theme classes
.ag-theme-balham .ag-root-wrapper {
  border: solid 0px !important;
}

.ag-theme-balham .ag-header {
  border: none;
}

.bottomLabel {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  font-size: 18 * $vw-const;
}
.timepicker {
  margin-right: 25 * $vw-const;
  input {
    background-color: #e6edff !important;
    height: 39px;
    border: 0;
    border-radius: 0;
    outline: none;
    width: 120 * $vw-const;
    font-size: 16 * $vw-const;
    display: flex;
    align-items: center;
    padding-left: 10 * $vw-const;
    padding-right: 10 * $vw-const;
    color: #002c94;
    margin-top: 3px;
    justify-content: space-between;

    ::placeholder {
      color: #d3d3d4;
    }
  }
}

.popUpContainer {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #00000080;
    z-index: 2;
    left: 0;
    top: 0;


    .container {
      width: 70%;
      position: relative;
      background-color: white;
      flex-direction: column;
      border-radius: 5px 5px 0 0;
      padding-bottom: 30 * $vw-const;
      height: 60%;
    }
    .secondContainer {
      overflow: auto;
      max-height: 70vh;
    }
    .bodyContainer {
      padding: 0 30 * $vw-const 0 30 * $vw-const;
      flex-direction: column;
    }    
    
    .closeIcon {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 24px;
      width: 24;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .error {
      border-left-width: 1vw;
      border-left-color: #f85b65;
      font-size: 16 * $vw-const;
    }
    .success {
      border-left-width: 1vw;
      border-left-color: #44dbae;
    }
    .close {
      position: absolute;
      top: 0.45vh;
      right: 0.45vh;
      width: 2.5vh;
      height: 2.5vh;
      color: #000000;
    }
    .buttonContainer {
      flex: 1;
      display: flex;
      height: auto;
      padding: 0.89vh;
      flex-direction: row;
      justify-content: center;
      /* background-color: #F4F4F4; */
    }
    
    .heading {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      height: 50 * $vw-const;
      font-size: 20 * $vw-const;
      font-weight: bold;
      margin-bottom: 30 * $vw-const;
      background: #002c94;
      border-radius: 5px 5px 0 0;
    }
    
    .button {
      height: 48 * $vh-const;
      width: 160 * $vh-const;
      border-radius: 0.37vh;
      color: #ffffff;
      border: none;
      background: #5a69ed 0% 0% no-repeat padding-box;
      justify-content: end;
      justify-self: flex-end;
      height: 48 * $vh-const !important;
      font-size: 16 * $vh-const !important;
    
      margin-right: 10px;
    }
    
    .icon {
      width: 2vw;
      margin-right: 0.89vh;
    }
   
    .textareaContainer {
      align-items: center;
      justify-content: center;
      color: #ff7600;
      textarea {
        width: 600 * $vw-const;
        min-height: 200 * $vw-const;
        margin-bottom: 60  * $vw-const;
        padding: 10* $vw-const;
        font-size: 20 * $vw-const;
        border: 1px solid gray;
        resize: none;
        color: #333333
      }
      .finalButton {
        width: 180 * $vw-const;
      }
    }
    @media screen and (max-width: 480px) {
      .container {
        width: 80%;
      }
      .icon {
        height: 50px;
      }
    
      .button {
        width: 15.854vw;
        font-size: 1.42vh;
        height: 3.2vh;
      }
    }

}



