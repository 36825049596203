@import '../../assets/sass/variables';

.containerClassNames {
  width: 70%;
  align-self: center;
}

.errorPopup {
  .blueStrip {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #002C94;;
    position: absolute;
    top:0;
    left:0;
    height: 50* $vw-const;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .noPermitContainer {
    color: #0045e6;
    width: 550 * $vw-const;
    height: 245 * $vw-const;
    background: #fff;
    border: 1px solid #979797;
    border-radius: 5px;
    padding: 10* $vw-const;
    margin-top: 100* $vw-const;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div {
      width: 100%;
    height: 100%;
    }
    img {
      width: 130* $vw-const;
    }
    .bold {
      font-size: 24* $vw-const;
      color: #002C94;
      text-align: center;
      font-weight: 600;
    }
  }
}


.container {
  width: 50%;
  position: relative;
  background-color: white;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  padding-bottom: 30 * $vw-const;
  min-height: 70vh;

}
.secondContainer {
  overflow: auto;
  max-height: 90vh;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  .heading {
    height: 40px;
    padding: 10 * $vw-const;
    letter-spacing: 0.74px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

}
.bodyContainer {
  padding: 0 30 * $vw-const 0 30 * $vw-const;
  flex-direction: column;
}

.closeIcon {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
  font-size: 16 * $vw-const;
}
.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}
.close {
  position: absolute;
  top: 0.45vh;
  right: 0.45vh;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
}
.buttonContainer {
  flex: 1;
  display: flex;
  height: auto;
  padding: 0.89vh;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  /* background-color: #F4F4F4; */
  align-items: flex-end;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 60 * $vw-const;
  font-size: clamp(12px, 22 * $vw-const,24 * $vw-const);
  font-weight: bold;
  margin-bottom: 30 * $vw-const;
  background: #002c94;
  border-radius: 5px 5px 0 0;
}

.button {
  width: 200 * $vw-const!important;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #5a69ed 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  height: 48 * $vw-const!important;
  font-size: 18 * $vw-const!important;

  margin-right: 10px;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}
.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24 * $vw-const;
  font-size: 14 * $vw-const;
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 400;
}
.requestLabel {
  font-size: 14 * $vw-const;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21 * $vw-const;
  font-weight: 500;
}

//vehicle card 
.statusCardContainer {
  width: 520 * $vw-const;
  display: flex;
  justify-content: center;
  background: #ffffff;
  flex-direction: column;
  margin: 0 auto;
 border-radius: 5px;
  margin-top: 30 * $vw-const;
  margin-bottom: 30 * $vw-const;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .heading {
    width: 100%;
    height: 40px;
    padding: 10 * $vw-const;
    background: #202428;
    font-size: 20 * $vw-const;
    color: #ffffff;
    letter-spacing: 0.74px;
    text-align: center;
    font-weight: 500;
    justify-content: center;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .cardDetails {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16 * $vw-const;
    color: #202428;
    letter-spacing: 0.48px;
    line-height: 18 * $vw-const;
    font-weight: 500;
    margin-bottom: 30 * $vw-const;

    .icon {
      width: 80 * $vw-const;
      height: 80 * $vw-const;
      margin-right: 10 * $vw-const;
    }
  }
  .contentContainer {
    display: flex;
    line-height: 2.5;
    flex-direction: column;
    span {
      font-family: 'Poppins-SemiBold';
    }
  }
  .buttonContainer {
    flex: 1;
    display: flex;
    height: auto;
    padding: 10px 20px;
    flex-direction: row;
    flex: 1;
    align-items: flex-end;
    justify-content: space-around;

    .button {
      width: 120 * $vw-const;
      border-radius: 0.37vh;
      color: #ffffff;
      border: none;
      background-color: #e6edff;
      color: #0045e6;
      justify-content: end;
      justify-self: flex-end;
      font-size: 20 * $vw-const;
      margin-bottom: 20 * $vw-const;
      padding: 10 * $vw-const 20 * $vw-const;
      cursor: pointer;
      //  flex: 1
    }
  
  }
  .buttonPrimary {
    background-color: #e6edff !important;
    color: #0045e6 !important;
  }
  .buttonSecondary {
    background-color: #0045e6 !important;
  }
}


.blueTickWrapper {
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 5px;
  width: 650 * $vw-const;
  padding: 5px;
  margin: 0 auto;
  .blueTickContainer {
    background: #F6F6F6;
    border-radius: 5px;
    font-size: 24 * $vw-const;
    color: #002C94;
    text-align: center;
    font-weight: 800;
    padding-bottom: 30 * $vw-const;
    letter-spacing: 0.96px;
    img {
      width: 160* $vw-const;
    }
  }
  .permitTimeBlock {
    display: flex;
    flex-direction: column;
    padding: 20  * $vw-const 10  * $vw-const 20  * $vw-const 40  * $vw-const;
    .timeActivePeriod, .timeStampContainer,  .agencyNameContainer{
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 16* $vw-const;
      font-weight: 100;
      .boldLabel{
        width: 40%;
        margin-top: 20* $vw-const;
      }
      .col2 {
        width: 55%;
        margin-top: 20* $vw-const;
      }
    }
    .boldLabel {
      font-weight: 800;
      font-size: 16* $vw-const;
      font-family: 'Poppins-SemiBold';
    }
    .colorBoxRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75%;
      img {
        margin-top: 18 * $vw-const;
        width: 110* $vw-const;
        margin-left: 5* $vw-const;;
      }
    }
  }
}


.timeBlock {
  width: 55 * $vw-const;
  height: 58 * $vw-const;
  margin-bottom: 40* $vw-const;
  margin-top: 20* $vw-const;

  text-align: center;
  .timeIconContainer {
   // background: rgba(109,212,0,0.60);
    border-radius: 5px;
    width: 60 * $vw-const;
    height: 58 * $vw-const;
    position: relative;
    padding: 5px 2px 2px 2px;
    margin: 0px 0 2px 0px;
    .time {
      background-color: white;
      width: 99%;
      margin: 0 auto;
      height: 42 * $vw-const;
      font-size: 14.5 * $vw-const;
      line-height: 1.2;
      text-align: center;
      background-color: white;
      
      margin-top: 6* $vw-const;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      align-items: center;
    } 
    
  }
  .date, .label {
    font-size: 13 * $vw-const;
    color: #000000;
  }
 
}

 

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}
