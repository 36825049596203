@import '../../../src/assets/sass/variables';
.date {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #b3bac2 !important;
  border-radius: 5px;
  width: 100%;
  // padding: 0.5rem;
  height: 50px;
  padding: 0 0.5vw;

  outline: none;
  background: url('../../assets/images/calendar-icon/image.png') 97% 50%
    no-repeat;
  background-color: #fff;

  background-size: 18px 18px;
}
// .date:invalid::-webkit-datetime-edit {
//   // color: red;
// }
.date:valid::-webkit-datetime-edit {
  color: black;
}

.date::-webkit-inner-spin-button {
  display: none;
}
.date::-webkit-calendar-picker-indicator {
  opacity: 0;
}

// input[type="date"] {
//   outline: none;
//   background: url("../../assets/images/calendar-icon/image.png") 97% 50%
//     no-repeat;
//   background-size: 18px 18px;
// }

// input[type="date"]::-webkit-inner-spin-button {
//   display: none;
// }
// input[type="date"]::-webkit-calendar-picker-indicator {
//   opacity: 0;
// }
.label {
  margin-bottom: 0.3rem;
  font-family: 'Poppins-Regular';
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
}

.error {
  color: #e67717;
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
}
