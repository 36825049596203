@import '../../../assets/sass/variables';

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoContainer {
  padding-top: 2.81rem;
  text-align: center;
  display: block;
  margin-bottom: 2.5rem;
  width: 23.69rem;
  img {
    margin-bottom: 0.93rem;
  }
  h2 {
    font-family: 'Poppins-Bold';
    font-size: 2rem;
    color: #0045e6;
    letter-spacing: 0.07rem;
    text-align: center;
    font-weight: 700;
  }
  h4 {
    font-family: 'Poppins-SemiBold';
    font-size: 24px;
    color: #0045e6;
    letter-spacing: 0.96px;
    text-align: center;
    font-weight: 600;
  }
}

.formContainer {
  width: 21.5rem;
  margin-bottom: 2.5rem;
  p {
    font-family: 'Poppins-SemiBold';
    font-size: 1.5rem;
    color: #002c94;
    letter-spacing: 0.06rem;
    font-weight: 600;
  }

  input[type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.loginButton {
  background: #0045e6;
  width: 21.5rem;
  height: 3rem;
  border: 1px solid;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  margin: 5px auto;
  margin-bottom: 1.88rem;
}

.forgotPasswordRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins-Regular';
  font-size: 0.75rem;
  color: #202428;
  letter-spacing: 0.04rem;
  text-align: right;
  font-weight: 400;
  position: relative;
  top: -20px;

  span {
    display: flex;
    align-items: center;
    label {
      padding-left: 0.8rem;
    }
  }
  a {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    color: #202428;
    letter-spacing: 0.04rem;
    text-align: right;
    font-weight: 400;
  }
}

.create_user {
  text-align: center;
  display: block;
  margin-bottom: 0.88rem;
  span {
    font-family: 'Poppins-Regular';
    font-size: 0.88rem;
    color: #202428;
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 400;
    width: 13.94rem;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: $blue-100;
  }
}

.button_group_section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 2.13rem;
  .buttonPrimaryOutlined {
    background: #ffffff;
    width: 21.5rem;
    height: 3rem;
    border: 1px solid;
    border-radius: 5px;
    color: $blue-100;
    font-family: 'Poppins-Bold';
    margin: 5px auto;
    margin-bottom: 1.88rem;
    font-size: 1rem;
  }
}
.buttonPrimary {
  background-color: #e67717 !important;
}
.buttonSecondary {
  background-color: #0045e6 !important;
}
.successIcon {
  height: 160px;
  aspect-ratio: 1;
  margin-bottom: 32px;
}
.requestSuccess {
  display: flex;
  margin-top: 50px;
  margin-bottom: 120px;
  flex-direction: column;
  align-items: center;
}
.successMessage {
  font-size: 18px;
  color: #002c94;
  letter-spacing: 0.72px;
  text-align: center;
  font-weight: 600;
}
.buttonContainer {
  margin-bottom: 80px;
}
.popupTextarea {
  width: 80%;
  min-height: 150px;
}
.rejectMessage {
  width: 80%;
  font-size: 18px;
  color: #002c94;
  letter-spacing: 0.72px;
  font-weight: 600;
}
.requestReject {
  display: flex;
  margin-top: 250px;
  margin-bottom: 220px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.cardWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15px;
  // opacity: 0.5;
  background: #eceff7;
  border-radius: 5px;
  min-height: 140px;
}
.cardheader {
  font-size: 18px;
  color: #002c94;
  letter-spacing: 0.72px;
  font-weight: 600;
}
.cardImage {
  height: 80px;
  width: 80px;
  margin-right: 20px;
}
.info {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
}
.requestLabel {
  font-size: 14px;
  color: #002c94;
  letter-spacing: 0.56px;
  line-height: 21px;
  font-weight: 500;
}
.requestKey {
  font-size: 14px;
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 21px;
  font-weight: 400;
}
.truckIcon {
  height: 50px;
  width: 50px;
  margin-right: 30px;
}

.approve {
  background: #0045e6;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  // width: 10vw!important;
}
.reject {
  // background: #e67717;
  background: #e67717;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  // width: 10vw!important;
}
.hold {
  background: #e6edff;
  color: #0045e6 !important;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  color: #0045e6;
  letter-spacing: 0.64px;
  text-align: center;
  font-weight: 600;
  // width: 10vw!important;
}

.documentContainer {
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  overflow: auto;
}

.documentImage {
}
