@import '../../../assets/sass/variables';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');

.heroContainer {
  font-family: 'Noto Sans', sans-serif !important;
  height: 65rem;
  width: 100%;
  background-image: url('../../../assets/images/Landing/hero/image.jpeg');
  background-size: cover;
  position: relative;
  background-position: 0 -10rem;
  overflow: hidden;
}

.gradientMask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #011c36;
  mask-image: linear-gradient(to top, white 10%, transparent);
  -webkit-mask-image: linear-gradient(to top, white 10%, transparent);
  //   background: linear-gradient(to top, #001c35, #252525);
  //   mask-image: linear-gradient(to top, white, red);
  //   -webkit-mask-image: linear-gradient(to top, white, red);
  //   -webkit-mask-image: linear-gradient(to top, #001c35, #252525);
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #011c3670;
  z-index: 10;
}

.content {
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 14rem;
  z-index: 20;
  overflow: hidden;
}
.heroButtonContainer {
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;

  margin: 0 auto;
  z-index: 990;
  margin-top: 1rem;
}
/* CSS */
.heroButton {
  z-index: 20;
  align-items: center;
  text-decoration: none !important;
  // background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  background-image: url('/assets/images/Landing/hero/login.png');
  background-size: cover;
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  // font-family: 'Poppins-Regular';
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem !important;
  font-weight: 400 !important;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 1rem 2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.heroButton:active,
.heroButton:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .heroButton {
    font-size: 24px;
    min-width: 196px;
  }
}

.smartPorts {
  // font-family: 'Poppins-Light';
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
}
.subText {
  max-width: 30rem;
  margin-top: 1rem;
  // font-family: 'Poppins-Light';
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
}

.animationContainer {
  margin: 0 auto;
  // padding-top: 7rem;
  display: flex;
  flex-direction: row;

  align-items: center;
}
.animationText {
  width: 14rem;
  color: #fff;
  font-size: 1.4rem;
  // font-family: 'Poppins-Bold';
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
  margin-left: 2rem;
  margin-bottom: -3rem;
  // margin-bottom: 1rem;
}
.outerCircle {
  height: 14rem;
  width: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px dashed #ffffff50;
  border-radius: 100%;
  position: relative;
  // margin-right: 4rem;
  margin-top: 5rem;
}
.outerImage {
  position: absolute;
  height: 14rem;
  // width: 14rem;
  top: 0;
  // left: 10rem;
  right: 50%;
  bottom: 0;
  margin: auto;
  transform: translateX(50%);
  // margin-right: 2px;
}
.circle {
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  background: #ec8940;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroCTA {
  background: #ec8940;
  padding: 1rem 4rem;
  color: #fff;
  border-radius: 8px;
  margin-top: 2rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}
