@import '../../../../../../assets/sass/variables';
.mainContainer {
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  flex-direction: column;
  overflow: auto;
  // padding: 20 * $vw-const 0;
}
.bodyContainer {
  padding: 0 4.16vh;
  padding-top: 0;
  flex-direction: column;
  
}
.body {
  padding: 0vh 2vw;
  background: #FFFFFF;
  border: 1px solid #D4D5D9;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 90%;
  margin: 30 * $vw-const 0 * $vw-const 30 * $vw-const 30 * $vw-const;

}

.formContainer {
  flex-wrap: wrap;
  margin-top: 35 * $vh-const;
  .firstDiv {
    width: 344 * $vw-const;
    margin-right: 50 * $vw-const;
    margin-bottom: 20 * $vh-const;
  }
  .purposeWorkDiv {
    width: 744 * $vw-const;
    margin-right: 100 * $vw-const;
    margin-bottom: 20 * $vh-const;
  }
}

.containerClassNames {
  width: clamp(120px, 344 * $vw-const, 344 * $vw-const);
  margin-right: 2vw;
  // height: 4vw;
  height: 45px !important;
  background-color: white;
  label {
    height: 45px !important;
  }
}
.dropdownContainerStyle {
  width: clamp(120px, 744 * $vw-const, 744 * $vw-const);
}
.buttonContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  height: auto;
  /* padding: 0.89vh; */
  flex-direction: row;
  /* background-color: red; */
}
.rowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  padding-left: 1vw;
}

.referenceIdsRowHeader {
  font-size: 16 * $vw-const;
  background-color: #e6edff;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  color: #202428;
  letter-spacing: 0.64px;
  padding-left: 1vw;
}
.referenceIdsRowValues {
  padding-left: 1vw;
}

.heading {
  display: flex;
  font-family: Poppins-SemiBold;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 60px;
  font-size: 2.4vh;
  margin-bottom: 1.57vh;
  background: #002c94;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

.button {
  width: 7.854vw;
  height: 4.56vh;
  border-radius: 0.37vh;
  color: #ffffff;
  border: none;
  background: #5a69ed 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  font-size: 1.94vh;
  margin-right: 10px;
  cursor: pointer;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}

.gridOfTwo {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
}
.gridOfThree {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  grid-column-gap: 2rem;
}
.divider {
  margin-bottom: 1rem;
}
.imageWrapper {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.userIcon {
  height: 110px;
}
.imageSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 160px;
  background: #e6edff;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 80%;
  }
  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}

// .containerClassNames {
// height: 40px !important;
//   margin-bottom: 1vh !important;
// }

.labelClassNames {
  font-size: 0.8rem;
}

.shiftRows {
  width: 100%;
  justify-content: space-between;
}

.shifts {
  height: 30vh;
  overflow-y: auto;
  margin-bottom: 8vh;
}

.image {
  height: 15px;
  cursor: pointer;
}

.tableWrapper {
  width: 90%;
  max-height: 600px;
  margin: 0 auto;
  overflow: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  .ag-root-wrapper {
    border: 0px solid!important;
  }
}

// .tableWrapper {
//   // height: 600px;
//   width: 900 * $vw-const;
//   margin: 0 auto;
//   // max-height: 20vh;
//   overflow: auto;
//   margin-bottom: 10vh;
//   min-height: 100px;
// }

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  // width: 100%;
  width: 4vw;
}

.gridRowClass {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #202428 !important;
  font-size: 16 * $vw-const;
  font-weight: 400;
  font-family: 'Poppins-Regular';
}
.personTableHeader {
  justify-content: space-between;
  p {
    font-size: clamp(10px, 12 * $vw-const, 12 * $vw-const);
    color: #202428;
    letter-spacing: 0.48px;
    text-align: right;
    font-weight: 400;
  }
}

.buttonsContainer {
  margin-bottom: 30 * $vh-const;
  margin-top: 30 * $vh-const;

  button {
    border: none;
    padding: 1vh 4vw;
    background: #e6edff;
    color: #0045e6;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
  }
}

.buttonsBottomContainer {
  margin: 50 * $vh-const;
  justify-content: center;
  button {
    border: none;
    background: #e6edff;
    color: #0045e6;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
    min-width: 160 * $vw-const;
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    padding: 10 * $vw-const;
  }
  .applyBtn {
    background: #0045e6;
    color: white;
    transition: all 0.1s ease;
  }  
  .applyBtn:hover {
    background-color: $blue-160;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
  .applyBtn:active {
    transform: scale(0.95);
  }
}


.successContainer {
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65%;
  .successIcon {
    width: 150 * $vw-const;
    margin-bottom: 25 * $vh-const;
  }
  .content {
    font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);
  }
  .referenceId {
    font-family: 'Poppins-SemiBold';
    font-size: clamp(12px, 22 * $vw-const, 22 * $vw-const);
    color: #e67717;
    letter-spacing: 0.72px;
    text-align: center;
    line-height: 33px;
    font-weight: 600;
  }
  button {
    border: none;
    background: #e6edff;
    color: #0045e6;
    border-radius: 5px;
    cursor: pointer;
    min-width: 160 * $vw-const;
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    padding: 10 * $vw-const;
    margin-top: 100 * $vh-const;
  }
}

.dropdownContainerStyle {
}
.passDate {
  font-size: 0.6rem;
}

.addMaterialButton {
  background: none;
  border: none;
  font-size: 0.8rem;
  color: #0045e6;
  cursor: pointer;
  font-weight: bold;
}
.SannaLogo {
  margin-top: 200 * $vw-const;

  // position: absolute;
  // bottom: 0;
  // left: 40%;
}
.personPermitHeaderContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #D4D5D9;
  margin-bottom: 10 * $vw-const;
  .personPermitHeader {
    .humanImgBg {
      width: 50* $vw-const;
      height: 50* $vw-const;
      background-color: #E7EDFE;  
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin-right: 20* $vw-const;
      img {
        width: 25* $vw-const;
      }
    }    
    font-size: 18 * $vw-const;
    color: black;
    font-family: 'Poppins-Light';
    display: flex;
    align-items: center;
    margin-bottom: 15* $vw-const;
  }
  .primaryButton {
    border: none;
    border-radius: 3px;
    cursor: pointer;
    min-width: 180 * $vw-const;
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    padding: 10 * $vw-const;
    background: #0045e6;
    color: white;
    margin-bottom: 20 * $vw-const;
    transition: all 0.1s ease;
}  
.primaryButton:hover {
  background-color: $blue-160;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}
.primaryButton:active {
  transform: scale(0.95);
}
}
.vehiclePermitHeaderContainer {
  margin-top: 40 * $vw-const;
}