@import '../../../src/assets/sass/variables';
.uploadBox {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #b3bac2;
  border-radius: 5px;
  width: 100%;
  // padding: 0.5rem;
  // background-color: red;
  height: 50px;
  padding: 0 0.5rem;

  //   flex-wrap: wrap;
  //   background-color: red;
}

.icon {
  width: 1rem;
  aspect-ratio: 1;
}

.container {
  flex-wrap: wrap;
  width: 100%;
}
.textboxContainer {
  height: auto;
}

.label {
  margin-bottom: 0.3rem;
  // background: red;
  font-family: 'Poppins-Regular';
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
}

.placeholderStyles {
  // placeholderTextColor={"#D3D3D4"}
  color: #d3d3d4;
  font-family: 'Poppins-Regular';
}

.error {
  color: #e67717;
  font-size: clamp(9px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
}
.fileName {
  font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
  margin-right: 0.25vw;
  font-family: 'Poppins-Regular';
}
.fileContainer {
  // background-color: red;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5vw;
}
.button {
  background: #e6edff;
  border: none;
  border-radius: 50%;
  width: 1rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}

.allowedFormats {
  font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
  color: #002c94;
}
.photoAllowedFormats {
  font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
  line-height: 1.4;
  color: #002c94;
  margin-top: -15px;
  text-align: center;
}
