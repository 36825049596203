.body {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
}
.sideBar {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.relative {
  display: flex;
  flex: 1;
  position: relative;
}
.bodyWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f8faff;
}
.container {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.helpCenter {
  padding: 1vh 1vw;
  margin-bottom: 1vh;
}
.emailPrompt {
  margin-bottom: 2vh;
}
.emailPrompt span {
  color: #0045e6;
}
.popupContainerStyle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}