@import '../../../src/assets/sass/variables';

.headerContainer {
  margin-top: 30 * $vh-const;
}
.heading {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 10 * $vh-const;
}
.tabsContainer {
  height: clamp(30px, 55 * $vh-const, 55 * $vh-const);
  min-height: 20px;
  width: 100%;
  display: flex;
  background: #e6edff;
  align-items: center;
  margin-top: 15 * $vh-const;
  margin-bottom: 20 * $vh-const;
  .tab {
    font-family: 'Poppins-Medium';
    font-size: clamp(10px, 16 * $vw-const, 16 * $vw-const);
    color: #202428;
    letter-spacing: 0.64px;
    text-align: center;
    font-weight: 500;
    width: clamp(150px, 272 * $vw-const, 272 * $vw-const);
    height: clamp(30px, 55 * $vh-const, 55 * $vh-const);
    min-height: 20px;
    padding: 0 14 * $vw-const;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active {
    background-color: #87abff;
    border-bottom: 3px solid #002c94;
    color: white;
  }
}
.bellIcon {
}
.profilePic {
  border-radius: 5px;
  background-color: gray;
}
.headerText {
  font-family: 'Poppins-Bold';
  color: #002c94;
  font-size: clamp(16px, 32 * $vw-const, 32 * $vw-const);
  letter-spacing: 1.27px;
  font-weight: 700;
}
.image {
  height: 24 * $vw-const;
  aspect-ratio: 1;
  background-size: cover;
}

.imageContainer {
  border-radius: 5px;
  background: #e6edff;
  margin-right: 20 * $vw-const;
  padding: 5px;
  height: 40 * $vw-const;
  width: 40 * $vw-const;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.headerContainer {
  align-items: center;
  justify-content: space-between;
}
.creditsContainer {
  width: 300 * $vw-const;
  height: 40 * $vw-const;
  background-color: #002c94;
  font-size: 14 * $vw-const;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 25 * $vw-const;
  border-radius: 5px;
  .creditsLabel {
    display: flex;
    align-items: center;
    .label {
      color: white;
      margin-left: 5px;
    }
  }
  .credits {
    color: #e6d10b;
  }
}
