@import '../../assets/sass/variables';

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 20 * $vh-const;
  div {
    width: 6.25rem;
    height: 0.04rem;
    border-color: #979797;
    border-width: 1px;
    border-style: solid;
    border-bottom: 1px;
  }
  span {
    margin: 0.3rem;
    font-family: 'Poppins-Regular';
    font-size: 0.88rem;
    color: #202428;
    letter-spacing: 0.03px;
    text-align: center;
    font-weight: 400;
  }
}
