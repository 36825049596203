@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
}
