@import '../../../assets/sass/variables';

.helpCenter {
  padding: 1vh 1vw;
  margin-bottom: 1vh;

  .emailPrompt {
    margin-bottom: 2vh;
  }
  .emailPrompt span {
    color: #0045e6;
  }

  .instructionsHeader {
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 4vh;
  }
  a {
    display: block;
    font-size: 1rem;
  }
}

.genericPopupStyles {
  width: 50%;
  height: 60%;
  overflow-y: auto;
}
